/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line no-unused-vars */
import React from 'react';

export const locale = {
  "locales": ["pl"],
  "messages": {
    "editor": {
      "first_name": "Imię",
      "name": "Nazwa",
      "subject": "Przedmiot",
      "message": "Wiadomość",
      "custom_dropdown": "Dostosowane menu rozwijane",
      "custom_label": "Własne etykietowanie",
      "set_autoplay": "Autoodtwarzanie",
      "autoplay_info": "Uwaga! Niektóre przeglądarki w większości przypadków nie zezwalają na autoodtwarzanie. Wyciszenie autoodtwarzania jest jednak zawsze dozwolone.",
      "set_muted": "Wyciszenie",
      "data_protection_officer": "Inspektor ochrony danych",
      "data_protection_authority": "Organ ochrony danych",
      "button_font_color": "Kolor czcionki",
      "set_label_dropdown": "Przypisz etykietę do listy rozwijanej",
      "set_label_custom_field": "Przypisz etykietę do pola wprowadzania",
      "empty_field": "Wolne pole",
      "custom_field": "Pole zdefiniowane przez użytkownika",
      "change_label": "Zmień etykietę",
      "edit_label": "Edytuj etykietę",
      "salutation": "Pozdrowienie",
      "title": "Tytuł",
      "link_title": "Nazwa łącza",
      "main_text": "Tekst główny",
      "more_text": "dalszy tekst",
      "create_text": "Utwórz tekst",
      "edit_text_slider": "Nie utworzono jeszcze żadnego elementu tekstowego",
      "edit_rss_elements": "Nie wybrano jeszcze żadnych elementów formy, kliknij tutaj, aby je dodać.",
      "rss_settings": "Ustawienia kanału RSS",
      "select_rss": "Możesz wybrać kanał RSS z szablonów",
      "available_rss": "Dostępne kanały RSS",
      "load_rss_part_1": "Wybrano następujący kanał RSS.",
      "load_rss_part_2": "Kliknij przycisk \"Pobierz kanały RSS\", aby załadować kanały.",
      "load_rss_feeds": "Pobieranie kanałów RSS",
      "color": "Kolor",
      "background_color": "Kolor tła",
      "distance_to_border": "Odległość do krawędzi",
      "color_of_qrcode": "Kolor kodu QR",
      "click_me": "Kliknij mnie",
      "new_menu_item": "Nowy element menu",
      "external": "zewnętrzny",
      "imprint": "Nadruk",
      "privacy": "Ochrona danych",
      "january": "Styczeń",
      "february": "luty",
      "march": "Marzec",
      "april": "Kwiecień",
      "may": "Maj",
      "june": "Czerwiec",
      "july": "Lipiec",
      "august": "Sierpień",
      "september": "Wrzesień",
      "october": "październik",
      "november": "Listopad",
      "december": "Grudzień",
      "monday": "Poniedziałek",
      "tuesday": "Wtorek",
      "wednesday": "Środa",
      "thursday": "Czwartek",
      "friday": "Piątek",
      "saturday": "Sobota",
      "sunday": "Niedziela",
      "edit_date": "Edycja spotkania",
      "oclock": "Zegar",
      "filter_selection": "Wybór filtra",
      "use_filter": "Zastosuj",
      "sample_picture_found": "Znaleziono przykładowy obraz",
      "already_edited_in_another_tab": "Ta edycja może być już edytowana w innej zakładce.",
      "information_for_contact": "Dane do formularza kontaktowego",
      "mail_receiver": "Odbiorca wiadomości e-mail",
      "google_analytics": "Google Analytics",
      "measurement_id": "Identyfikator pomiaru dla Google Analytics",
      "new_picture_inserted": "Dodano nowy obraz",
      "text_changed": "Tekst został zmieniony",
      "edit_title": "Kliknij tutaj, aby edytować tytuł",
      "edit_description": "Kliknij tutaj, aby edytować opis obrazu",
      "edit_copyright": "Kliknij tutaj, aby edytować prawa autorskie",
      "no_changes": "Bez zmian",
      "changes_on_document": "Zmiany w dokumencie",
      "changes_on_page": "Zmiany na stronie",
      "page": "Strona",
      "choose_button_label": "Wybierz etykietę dla przycisku",
      "edit_calendly": "Kliknij tutaj, aby edytować ustawienia Calendly",
      "exchanged_with_page": "wymieniony ze stroną",
      "upload_failed": "Przesyłanie nie powiodło się",
      "try_again": "Spróbuj ponownie",
      "saving_failed": "Zapisywanie nie powiodło się",
      "new_page_inserted": " nowa strona lub strony wstawione po stronie",
      "delete_pages": "Usuwanie stron",
      "all_content_deleted": "Strony i cała ich zawartość zostaną usunięte.",
      "yes_delete": "Tak, usuń",
      "pages_deleted": " Usunięte strony, według stron",
      "rather_not": "Lepiej nie",
      "new_here": "Jesteś tu nowy?",
      "first_start": "Wygląda na to, że po raz pierwszy otworzyłeś nasz nowy edytor. Czy chciałbyś otrzymać wprowadzenie?",
      "yes_absolutely": "Tak, absolutnie",
      "im_fine": "Mogę zarządzać",
      "no_page_definitions_found": "Nie znaleziono definicji stron!",
      "move_up": " Przenoszenie stron w górę",
      "side_position_fixed": "Pozycja boczna stała",
      "generated_in_pdf": "Strony są generowane automatycznie w pliku PDF",
      "move_down": " Przenoszenie stron w dół",
      "by": "Do",
      "add_page": " Wstaw strony",
      "remove_page": " Usuń strony",
      "unsaved_changes": "Masz niezapisane zmiany",
      "i_have_it": "Ok, teraz to mam!",
      "document_pictures": "Obrazy w dokumencie",
      "used_images": "Użyte obrazy",
      "take_a_photo": "Zrób zdjęcie",
      "unused_images": "Nieużywane obrazy",
      "content_is_saved": "Zawartość jest zapisywana",
      "page_arrangement": "Układ boczny",
      "print_sheet_view": "Widok arkusza wydruku",
      "view_safety_distance": "Widok bezpiecznej odległości",
      "view_structure": "Widok struktury",
      "start_tutorial": "Rozpocznij samouczek",
      "adjust_by_window": "Dostosowanie strony do okna",
      "adjust_by_width": "Dostosuj stronę do szerokości",
      "adjust_by_height": "Dostosuj wysokość strony",
      "delete_content": "Usuń zawartość",
      "delete_selected_content_block": "Powoduje to usunięcie wybranego bloku treści i jego zawartości (obrazów, tekstów).",
      "delete_children_of_parents": "Należy pamiętać, że jest to element nadrzędny. Jeśli go usuniesz, wszystkie powiązane elementy podrzędne również zostaną usunięte.",
      "reset_content": "Zresetuj zawartość",
      "reset_content_block_to_root": "Powoduje to zresetowanie wybranego bloku treści i jego zawartości (obrazów, tekstów) do pierwotnego stanu.",
      "yes_reset": "Tak, reset",
      "copy_full_content": "Kopiowanie całej zawartości strony",
      "paste_content": "Wklejanie zawartości ze schowka",
      "enlarge_block": "Powiększ blok",
      "shrink_block": "Zmniejsz rozmiar bloku",
      "text_overflow": "Przepełnienie tekstu",
      "initial_image_available": " Dostępny obraz początkowy",
      "paste_content_after": "Wklej zawartość ze schowka po tych",
      "deselect_element": "Usuń zaznaczenie elementu",
      "select_element": "Wybierz element",
      "block_can_not_move": "Bloku nie można przesunąć",
      "up": "Do góry",
      "reset_block": "Blok resetowania",
      "change_backgroundcolor": "Zmiana koloru tła",
      "change_fontcolor": "Zmiana koloru czcionki",
      "copy_block": "Kopiuj blok",
      "delete_block": "Usuń blok",
      "down": "W dół",
      "product_in_cart": "Produkt w koszyku",
      "added_to_cart": " został dodany do Twojego koszyka.",
      "great": "Świetnie!",
      "to_cart": "Do koszyka zakupów",
      "are_you_sure": "Jesteś pewien?",
      "reset_to_selected_status": "Dokument zostanie zresetowany do wybranego stanu",
      "abort": "Anuluj",
      "reset_to_version": "Reset do wersji",
      "choose_a_campaign": "Wybierz kampanię",
      "name_for_edition": "Nazwa dla tej edycji",
      "save_to": "Zapisz jako...",
      "save": "Zapisz",
      "settings": "Ustawienia",
      "short_summary": "Krótkie podsumowanie",
      "keywords_separated": "Słowa kluczowe oddzielone przecinkiem",
      "serial_letter": "Łączenie poczty",
      "activate_serial_letter": "Aktywacja korespondencji seryjnej",
      "serial_letter_database": "Baza danych korespondencji seryjnej",
      "serial_letter_database_upload_info": "Przesyłanie obsługuje następujące formaty plików - .xlsx, .xls i .csv.",
      "serial_letter_info": "Wprowadź symbole zastępcze w komórkach pierwszego wiersza w bazie danych. Poniżej należy wprowadzić odpowiednie wartości. Na przykład w następujący sposób:",
      "preview_of_database": "Podgląd bazy danych",
      "serial_letter_database_upload": "Przesyłanie",
      "no_error": "Bez błędu",
      "error": "Błąd",
      "information_for_imprint": "Informacje dotyczące nadruku",
      "responsible_for_content": "Odpowiedzialny za treść",
      "company_association": "Firma / Stowarzyszenie",
      "street": "ul.",
      "zip_code": "Kod pocztowy",
      "city": "Lokalizacja",
      "email": "Adres e-mail",
      "phone_number": "Telefon",
      "data_protection_information": "Informacje na temat ochrony danych",
      "responsible": "Osoba odpowiedzialna",
      "change": "Zmiana",
      "export_image": "Eksportuj obraz",
      "preview": "Podgląd",
      "preview_button": "Podgląd przycisku",
      "print_pdf": "Drukuj PDF",
      "initialize": "Inicjalizacja!",
      "order": "Zamówienie",
      "versions": "Wersje",
      "currently_used_image": "Aktualnie używany obraz",
      "upload_new_image": "Prześlij nowy obraz",
      "new_cut": "Recut",
      "take_on": "Przejęcie",
      "choose_file": "Wybierz plik",
      "image_description": "Opis obrazu",
      "image_upload": "Przesyłanie obrazów",
      "mediathek": "Biblioteka multimediów",
      "pixabay": "Pixabay",
      "add_content": "Dodaj zawartość",
      "copied_content_block": "Skopiowany blok zawartości",
      "unsaved_content": "Masz niezapisane zmiany! Czy naprawdę chcesz opuścić edytor?",
      "filter_settings": "Ustawienia filtra",
      "filter_grayscale": "Skala szarości",
      "filter_sepia": "Sepia",
      "filter_blur": "rozmycie",
      "filter_brightness": "Jasność",
      "filter_contrast": "Kontrast",
      "filter_hueRotate": "Obracanie tonacji kolorów",
      "filter_invert": "Odwrócenie kolorów",
      "filter_opacity": "Przejrzystość",
      "filter_saturate": "Nasycenie",
      "filter_default": "Standard",
      "filter_set": "Zaprzestano produkcji",
      "work_surface": "Powierzchnia robocza znajduje się tutaj.",
      "work_surface_description": "Tutaj odbywa się proces projektowania. Tutaj można wstawiać i edytować obrazy, teksty i elementy.",
      "page_navigation": "Tutaj znajduje się nawigacja strony",
      "page_navigation_description": "Tutaj można przełączać się między poszczególnymi stronami oraz dodawać, usuwać lub zamieniać strony.",
      "content_blocks": "Tutaj znajdują się bloki zawartości",
      "content_blocks_description": "W tym miejscu można wstawiać różne elementy graficzne i tekstowe do materiałów reklamowych, klikając je. Jeśli na materiale reklamowym nie ma wystarczającej ilości miejsca, można w dowolnym momencie usunąć zbędne elementy.",
      "screen_view": "Widok ekranu można zmienić tutaj.",
      "screen_view_description": "Tutaj możesz zmienić widok swoich mediów internetowych. 100% zawsze reprezentuje oryginalny rozmiar materiału reklamowego.",
      "error_display": "Tutaj wyświetlane są błędy.",
      "error_display_description": "System powiadamia użytkownika w tym miejscu w przypadku wykrycia przepełnienia tekstu lub innych błędów technicznych.",
      "options": "Tutaj znajdziesz opcje dla swoich materiałów reklamowych.",
      "options_description": "Kliknij przycisk podglądu, aby wygenerować podgląd PDF lub przycisk zamówienia, aby umieścić materiał reklamowy w koszyku. Za pomocą opcji \"Zapisz jako\" można skopiować materiał reklamowy do innej kampanii.",
      "tutorial_end": "To był nasz redaktor w pigułce!",
      "tutorial_end_description": "Teraz możesz szybko i łatwo zaprojektować swoje materiały reklamowe zgodnie z projektem korporacyjnym.",
      "content_block_copied": "Skopiowany blok zawartości",
      "content_blocks_switched": "Zamienione bloki zawartości",
      "content_block_reset": "Resetowanie bloku zawartości",
      "content_block_resize": "Zmieniony rozmiar bloku zawartości",
      "content_block_removed": "Usunięty blok zawartości",
      "no_place": "Brak miejsca",
      "content_block_inserted": "Wstawiony blok zawartości",
      "no_dates_available": "Brak dostępnych dat, kliknij tutaj, aby je dodać.",
      "new_date": "Nowa data",
      "edit_dates": "Edycja terminów",
      "date_description": "Opis spotkania",
      "add": "Dodaj",
      "no_dates_availableTwo": "Brak dostępnych dat",
      "date_title": "Tytuł nominacji",
      "date_place": "Miejsce spotkania",
      "date_from": "Am/Dn",
      "date_to": "Do",
      "no_forms": "Nie wybrano jeszcze żadnych elementów formy, kliknij tutaj, aby je dodać",
      "read_privacy_policy": "Przeczytałem i zrozumiałem politykę prywatności.",
      "contact_form_settings": "Ustawienia formularza kontaktowego",
      "dropdown_settings": "Ustawienia listy rozwijanej",
      "add_options": "Dodaj opcje",
      "dropdown_label": "Etykieta rozwijana",
      "dropdown_option_fields": "Rozwijane przyciski opcji",
      "pls_choose": "Wybierz element formularza",
      "pls_chooseTwo": "Wybierz żądane elementy formularza kontaktowego",
      "send": "Wysyłanie",
      "google_maps_element_settings": "Ustawienia lokalizacji Mapy Google",
      "no_location": "Nie dodałeś jeszcze adresu, kliknij tutaj, aby go edytować",
      "found_textoverflow": "Znaleziono przepełnienie tekstu",
      "minimum_size": "Minimalny rozmiar",
      "too_low_resolution": "Obraz ma zbyt niską rozdzielczość!",
      "set_scale_free": "Aktywuj bezpłatne skalowanie",
      "unset_scale_free": "Dezaktywacja bezpłatnego skalowania",
      "no_images_selected": "Nie wybrano jeszcze żadnych zdjęć, kliknij tutaj, aby dodać",
      "gallery_settings": "Ustawienia galerii",
      "add_image": "Dodaj obraz",
      "add_image_front": "Dodaj zdjęcie na początku",
      "add_image_back": "Dodaj zdjęcie na końcu",
      "slider_images_invert": "Odwróć kolejność obrazów",
      "image_text_settings": "Ustawienia tekstu obrazu",
      "change_slider": "Aby zmienić tytuł lub opis, kliknij odpowiedni tekst na suwaku",
      "change_image_title": "Zmiana tytułu zdjęcia",
      "change_image_description": "Zmiana opisu obrazu",
      "image_copyright": "Prawa autorskie do zdjęć",
      "slider_settings": "Ustawienia suwaka",
      "new_titel": "Nowy tytuł",
      "new_link_title": "Nowy tytuł łącza",
      "edit_text_title": "Edytuj tytuł",
      "edit_text_link": "Edytuj link",
      "edit_link_title": "Edytuj tytuł łącza",
      "edit_text_text": "Edytuj tekst",
      "add_element": "Dodaj element",
      "add_element_before": "Dodaj element z przodu",
      "add_element_after": "Dodaj element z tyłu",
      "edit_text_of_element": "Aby edytować tekst, kliknij odpowiedni tekst na suwaku",
      "invert_elements": "Odwrócona sekwencja",
      "edit_nav": "Edytuj nawigację",
      "term": "Oznaczenie",
      "link": "Link",
      "actions": "Działania",
      "content_of_QRCode": "Jaką treść powinien zawierać kod QR?",
      "address_of_website": "Wprowadź adres strony internetowej:",
      "name_surname": "Imię i nazwisko",
      "business_card": "Wizytówka",
      "must_url": "Musi to być adres URL",
      "position": "Pozycja",
      "phone": "Telefon",
      "website": "Strona internetowa",
      "address": "Adres",
      "email_message": "Wiadomość e-mail",
      "text": "Tekst",
      "recipient_email_address": "Adres e-mail odbiorcy",
      "reference": "Przedmiot",
      "content": "Zawartość",
      "your_text": "Twój tekst",
      "add_QRCode": "Wstaw kod QR",
      "no_preview_of_twitter": "Niestety nie jest możliwe wyświetlenie tutaj Twittera, prosimy o skorzystanie z funkcji podglądu strony",
      "no_preview_of_calendly": "Niestety nie jest możliwe wyświetlenie Calendly tutaj, prosimy o skorzystanie z funkcji podglądu strony.",
      "twitter_link": "Opublikowany link na Twitterze:",
      "social_media_settings": "Ustawienia mediów społecznościowych",
      "calendly_settings": "Ustawienia kalendarza",
      "button_settings": "Ustawienia przycisków",
      "action_button_content_text": "Kliknij tutaj, aby edytować ustawienia przycisku akcji",
      "action_button_link_element": "Odniesienie do elementu",
      "action_button_external_link": "Odniesienie do linku zewnętrznego",
      "action_button_or": "lub",
      "button_color": "Kolor przycisku",
      "add_social_media_account": "Nie dodano jeszcze konta w mediach społecznościowych, kliknij tutaj, aby je wybrać.",
      "choice_of_provider": "Wybór dostawców mediów społecznościowych",
      "embed_code": "Osadzanie kodu z kanału mediów społecznościowych",
      "link_to_your_page": "Link do strony pod adresem",
      "link_to_your_calendly_page": "Link do strony Calendly",
      "link_to_your_page_two": "Link do strony",
      "video_settings": "Ustawienia wideo",
      "add_video": "Nie wybrano jeszcze żadnego filmu, kliknij tutaj, aby go dodać",
      "choice_video_provider": "Wybór dostawców usług wideo",
      "upload_video": "Prześlij wideo",
      "choice_mp4_file": "Wybierz plik wideo (.mp4)",
      "nav_type": "Typ",
      "nav_type_url": "URL",
      "nav_type_page": "Strona",
      "nav_type_page_prefix": "Strona"
    },
    "medialibrary": {
      "new_folder": "Nowy folder",
      "sorting": "Sortowanie",
      "directories": "Katalogi",
      "medialibrary": "Biblioteka multimediów",
      "media": "Media",
      "upload_media": "Przesyłanie multimediów",
      "sorting_description": "Należy pamiętać, że sortowanie opiera się na nazwie pliku, a nie na zdefiniowanym tytule.",
      "sorting_info_text": "Wybierz kolejność sortowania folderów i multimediów. Zestaw sortowania ma również zastosowanie we wszystkich widokach folderów.",
      "paste": "Wstawka",
      "info": "Uwaga!",
      "info_text": "Jeden lub więcej plików już istnieje. Nie zostały one przesłane ponownie.",
      "back_to_root": "Powrót do katalogu głównego",
      "directory_by": "Katalog",
      "created_by": "Stworzony przez",
      "no_folder_created": "Nie utworzono żadnych folderów",
      "no_media_found": "Brak nośnika w folderze",
      "upload_processing": "Przesyłane pliki są przetwarzane",
      "not_uploaded": "Nie można załadować multimediów!",
      "close": "Zamknij",
      "preview": "Podgląd obrazu/wideo",
      "name": "Nazwa",
      "upload_info": "Info",
      "options": "Opcje",
      "status": "Status",
      "adjust_meta": "Dostosowywanie metadanych",
      "title": "Tytuł",
      "description": "Opis",
      "save": "Zapisz",
      "create_new_directory": "Utwórz nowy katalog",
      "directory_name": "Nazwa katalogu",
      "rename_folder": "Zmiana nazwy folderu",
      "copyright": "Prawa autorskie",
      "upload": "Przesyłanie",
      "wastebasket": "Kosz na śmieci",
      "are_you_sure_erase_file": "Czy na pewno chcesz trwale usunąć plik?",
      "are_you_sure_erase_folder": "Czy na pewno chcesz nieodwracalnie usunąć folder?",
      "erase": "Usuń"
    },
    "meta": {
      "campaigns": {
        "title": "Kampanie",
        "description": "Kampanie",
        "name": "Kampanie"
      },
      "productShop": {
        "title": "Sklep",
        "description": "Szukasz towarów? W takim razie trafiłeś we właściwe miejsce.",
        "name": "Artykuł"
      },
      "carShop": {
        "title": "Sklep z pojazdami",
        "description": "Szukasz swojego wymarzonego samochodu? Zapoznaj się z naszymi pojazdami już teraz.",
        "name": "Pojazdy"
      },
      "car_detail_search": {
        "title": "Nie jesteś pewien, który samochód Ci odpowiada? Skorzystaj z naszej wyszukiwarki samochodów, aby znaleźć idealny samochód",
        "description": "Szukasz samochodu o określonych wymaganiach, ale nie wiesz dokładnie, jakiego pojazdu potrzebujesz? Wybierz typ pojazdu, układ napędowy i poziom wyposażenia.",
        "name": "Wyszukiwanie szczegółowe"
      },
      "car_brands": {
        "title": "Wybierz swoją markę",
        "description": "Wybierz swoją markę i odkryj najlepsze oferty na rynku.",
        "name": "Wybór marki"
      },
      "car_models": {
        "title": "Wybierz model swojej {brandName}.",
        "description": "Wybierz model spośród najpopularniejszych {modelOne} do {modelTwo}.",
        "name": "Modele"
      },
      "car_model_drive": {
        "title": "Wybierz rodzaj paliwa i skrzynię biegów dla swojego {brandName} {modelName}.",
        "description": "Mamy {brandName} {modelName} z silnikami {fuelTypes}. Dostępne typy skrzyń biegów to {gearboxTypes}.",
        "name": "Rodzaj paliwa"
      },
      "car_model_equipment": {
        "title": "Wybierz poziom wyposażenia dla swojego {brandName} {modelName}.",
        "description": "Do wyboru są wersje Budget, Family, Comfort lub Business. Najpopularniejszy, Comfort, oferuje czujniki parkowania, Bluetooth i nie tylko.",
        "name": "Sprzęt"
      },
      "favorites": {
        "title": "Ulubione, zapisane na później",
        "description": "To świetnie, że zachowałeś niektóre z naszych najlepszych przedmiotów na później. Teraz spróbuj wybrać ten, który chcesz.",
        "name": "Ulubione"
      },
      "cart": {
        "title": "Koszyk na zakupy",
        "description": "Koszyk na zakupy",
        "name": "Koszyk na zakupy"
      },
      "checkout": {
        "title": "Kasa",
        "description": "Kasa",
        "name": "Kasa"
      },
      "clients": {
        "title": "Klienci",
        "description": "Klienci",
        "name": "Klienci"
      },
      "client_details": {
        "title": "Firma",
        "description": "Firma",
        "name": "Firma"
      },
      "leads": {
        "title": "Przewody",
        "description": "Przewody",
        "name": "Przewody"
      },
      "lead_details": {
        "title": "Ołów",
        "description": "Ołów",
        "name": "Ołów"
      },
      "cars": {
        "title": "Pojazdy",
        "description": "Pojazdy",
        "name": "Pojazdy"
      },
      "car_overview": {
        "title": "Przegląd pojazdu",
        "description": "Przegląd pojazdu",
        "name": "Przegląd pojazdu"
      },
      "car_details": {
        "title": "Pojazd",
        "description": "Pojazd",
        "name": "Pojazd"
      },
      "pages": {
        "title": "Strona",
        "description": "Strona",
        "name": "Strona"
      },
      "page_details": {
        "title": "Strona",
        "description": "Strona",
        "name": "Strona"
      },
      "profile": {
        "title": "Profil",
        "description": "Profil",
        "name": "Profil"
      },
      "locations": {
        "title": "Lokalizacje",
        "description": "Lokalizacje",
        "name": "Lokalizacje"
      },
      "company_locations": {
        "title": "Lokalizacje",
        "description": "Lokalizacje",
        "name": "Lokalizacje"
      },
      "data_push": {
        "title": "Wypychanie danych",
        "description": "Wypychanie danych",
        "name": "Wypychanie danych"
      },
      "white_label_projects": {
        "title": "Projekty white label",
        "description": "Projekty white label",
        "name": "Projekty white label"
      },
      "users": {
        "title": "Użytkownicy",
        "description": "Użytkownicy",
        "name": "Użytkownicy"
      },
      "user_details": {
        "title": "Użytkownicy",
        "description": "Użytkownicy",
        "name": "Użytkownicy"
      },
      "user_import": {
        "title": "Importuj użytkowników",
        "description": "Importuj użytkowników",
        "name": "Importuj użytkowników"
      },
      "teams": {
        "title": "Zespoły",
        "description": "Zespoły",
        "name": "Zespoły"
      },
      "questionnaires": {
        "title": "Kwestionariusze",
        "description": "Kwestionariusze",
        "name": "Kwestionariusze"
      },
      "questionnaire_details": {
        "title": "Kwestionariusz",
        "description": "Kwestionariusz",
        "name": "Kwestionariusz"
      },
      "document_types": {
        "title": "Typy dokumentów",
        "description": "Typy dokumentów",
        "name": "Typy dokumentów"
      },
      "document_type_details": {
        "title": "Typ dokumentu",
        "description": "Typ dokumentu",
        "name": "Typ dokumentu"
      },
      "templates": {
        "title": "Szablony",
        "description": "Szablony",
        "name": "Szablony"
      },
      "cms_elements": {
        "title": "Elementy CMS",
        "description": "Elementy CMS",
        "name": "Elementy CMS"
      },
      "cms_element_details": {
        "title": "Element CMS",
        "description": "Element CMS",
        "name": "Element CMS"
      },
      "brands": {
        "title": "Marki",
        "description": "Marki",
        "name": "Marki"
      },
      "models": {
        "title": "Modele",
        "description": "Modele",
        "name": "Modele"
      },
      "categories": {
        "title": "Kategorie",
        "description": "Kategorie",
        "name": "Kategorie"
      },
      "category_details": {
        "title": "Kategoria",
        "description": "Kategoria",
        "name": "Kategoria",
        "authorization_on_mobile_device": "Autoryzacja na urządzeniach mobilnych",
        "hide_category": "Ukryj kategorię"
      },
      "attributes": {
        "title": "Atrybuty",
        "description": "Atrybuty",
        "name": "Atrybuty"
      },
      "attribute_details": {
        "title": "Atrybut",
        "description": "Atrybut",
        "name": "Atrybut"
      },
      "attribute_groups": {
        "title": "Grupy atrybutów",
        "description": "Grupy atrybutów",
        "name": "Grupy atrybutów"
      },
      "dynamic_data_definitions": {
        "title": "Dynamiczne definicje danych",
        "description": "Dynamiczne definicje danych",
        "name": "Dynamiczne definicje danych"
      },
      "pipelines": {
        "title": "Rurociągi",
        "description": "Rurociągi",
        "name": "Rurociągi"
      },
      "pipeline_details": {
        "title": "Rurociąg",
        "description": "Rurociąg",
        "name": "Rurociąg"
      },
      "items": {
        "title": "Produkty",
        "description": "Produkty",
        "name": "Produkty"
      },
      "item_details": {
        "title": "Produkt",
        "description": "Produkt",
        "name": "Produkt"
      },
      "calendar": {
        "title": "Kalendarz",
        "description": "Kalendarz",
        "name": "Kalendarz"
      },
      "orders": {
        "title": "Zamówienia",
        "description": "Zamówienia",
        "name": "Zamówienia"
      },
      "settings": {
        "title": "Ustawienia",
        "description": "Ustawienia",
        "name": "Ustawienia"
      },
      "four_zero_four": {
        "title": "404",
        "description": "404",
        "name": "404"
      },
      "account": {
        "title": "Konto",
        "description": "Konto",
        "name": "Konto"
      },
      "forgot_password": {
        "linktext": "Zapomniałeś hasła?",
        "title": "Zapomniane hasło",
        "description": "Zapomniane hasło",
        "name": "Zapomniane hasło"
      },
      "renew_password": {
        "title": "Odnów hasło",
        "description": "Odnów hasło",
        "name": "Odnów hasło"
      },
      "new_password_after_reset": {
        "title": "Nowe hasło po resecie",
        "description": "Nowe hasło po resecie",
        "name": "Nowe hasło po resecie"
      },
      "pricing": {
        "title": "Ceny",
        "description": "Ceny",
        "name": "Ceny"
      },
      "signedup": {
        "title": "Zarejestrowany",
        "description": "Zarejestrowany",
        "name": "Zarejestrowany"
      },
      "go_pro": {
        "title": "Go pro",
        "description": "Go pro",
        "name": "Go pro"
      },
      "paypal": {
        "title": "Zapłać za pomocą Paypal",
        "description": "Zapłać za pomocą Paypal",
        "name": "Zapłać za pomocą Paypal"
      },
      "lead_catchers": {
        "title": "Główni łapacze",
        "description": "Główni łapacze",
        "name": "Główni łapacze"
      },
      "bills": {
        "title": "Faktury",
        "description": "Faktury",
        "name": "Faktury"
      },
      "provisionServiceDocuments": {
        "title": "Prowizje"
      },
      "commissionServiceDocuments": {
        "title": "Dokumenty Komisji"
      },
      "integrations": {
        "title": "Integracje",
        "description": "Integracje",
        "name": "Integracje"
      },
      "order_complete": {
        "title": "Zamówienie zrealizowane. Dziękuję bardzo",
        "description": "Zamówienie zrealizowane. Dziękuję bardzo",
        "name": "Zamówienie zrealizowane. Dziękuję bardzo"
      },
      "order_details": {
        "title": "Szczegóły zamówienia",
        "description": "Szczegóły zamówienia",
        "name": "Szczegóły zamówienia"
      },
      "my_company": {
        "title": "Moja firma",
        "description": "Moja firma",
        "name": "Moja firma"
      },
      "white_label_plugins": {
        "title": "Wtyczki białych etykiet",
        "description": "Wtyczki białych etykiet",
        "name": "Wtyczki białych etykiet"
      },
      "plugins": {
        "title": "Wtyczki Creacheck",
        "description": "Wtyczki Creacheck",
        "name": "Wtyczki Creacheck"
      },
      "bill_details": {
        "title": "Szczegóły faktury",
        "description": "Szczegóły faktury",
        "name": "Szczegóły faktury"
      },
      "activate_plugin": {
        "title": "Aktywuj wtyczkę",
        "description": "Aktywuj wtyczkę",
        "name": "Aktywuj wtyczkę"
      },
      "model_groups": {
        "title": "Grupy modeli",
        "description": "Grupy modeli",
        "name": "Grupy modeli"
      },
      "model_group_details": {
        "title": "Szczegóły grupy modeli",
        "description": "Szczegóły grupy modeli",
        "name": "Szczegóły grupy modeli"
      },
      "model_assets": {
        "title": "Zdjęcia modeli",
        "description": "Zdjęcia modeli",
        "name": "Zdjęcia modeli"
      },
      "model_asset_details": {
        "title": "Szczegóły obrazu modelu",
        "description": "Szczegóły obrazu modelu",
        "name": "Szczegóły obrazu modelu"
      },
      "lead_per_mail_rules": {
        "title": "Zasady prowadzenia przez e-mail",
        "description": "Zasady prowadzenia przez e-mail",
        "name": "Zasady prowadzenia przez e-mail"
      },
      "car_rental": {
        "title": "Bezpieczna i szybka rezerwacja w {city}. {liczba} samochodów do wyboru",
        "description": "Zarezerwuj samochód szybko i bezpiecznie. Uwierz nam, wynajem samochodu nigdy nie był tak szybki i łatwy.",
        "name": "Wynajem"
      },
      "failed_refunds": {
        "title": "Nieudane zwroty kosztów",
        "description": "Nieudane zwroty kosztów",
        "name": "Nieudane zwroty kosztów"
      },
      "companySignup": {
        "title": "Rejestracja dla firm",
        "description": "Zarejestruj swoją firmę i korzystaj z najnowszego ekosystemu dla dealerów samochodowych",
        "name": "Rejestracja dla firm"
      },
      "price_negotiations": {
        "title": "Negocjacje cenowe",
        "description": "Negocjacje cenowe",
        "name": "Negocjacje cenowe"
      },
      "price_negotiation_details": {
        "title": "Negocjacja ceny",
        "description": "Negocjacja ceny",
        "name": "Negocjacja ceny"
      },
      "communities": {
        "title": "Lista społeczności",
        "description": "Lista społeczności",
        "name": "Lista społeczności"
      },
      "car_service_locations": {
        "title": "Lokalizacje, w których dostępne są wizyty serwisowe",
        "description": "Lokalizacje",
        "name": "Lokalizacje"
      },
      "car_service_resources": {
        "title": "Wybór konsultanta serwisowego",
        "description": "Konsultant ds. usług",
        "name": "Konsultant ds. usług"
      },
      "car_service_free_slots": {
        "title": "Wybierz wolny przedział czasowy na zaparkowanie samochodu",
        "description": "Okno czasowe",
        "name": "Okno czasowe"
      },
      "car_service_client_data": {
        "title": "Zaloguj się lub zarejestruj, aby zarezerwować wizytę",
        "description": "Logowanie",
        "name": "Logowanie"
      },
      "car_service_car_data": {
        "title": "Wprowadź dane pojazdu, aby zarezerwować usługę",
        "description": "Dane pojazdu",
        "name": "Dane pojazdu"
      },
      "car_service_summary": {
        "title": "Ostatni krok - Sprawdź swoje dane i wybór",
        "description": "Sprawdź",
        "name": "Sprawdź"
      },
      "car_services": {
        "title": "Usługi warsztatowe",
        "description": "usługi",
        "name": "usługi"
      },
      "car_service_stations": {
        "title": "Stanowiska warsztatowe",
        "description": "Stacje robocze",
        "name": "Stacje robocze"
      },
      "document_templates": {
        "title": "Szablony dokumentów",
        "description": "Szablony dokumentów",
        "name": "Szablony dokumentów"
      },
      "document_template_details": {
        "title": "Szablon dokumentu",
        "description": "Szablon dokumentu",
        "name": "Szablon dokumentu"
      }
    },
    "sort": {
      "relevance": "Znaczenie",
      "username": "e-mail",
      "lastName": "Nazwisko",
      "cdate": "Najpierw najnowsze",
      "cdateDesc": "Najpierw najnowsze",
      "dueDate": "Termin płatności",
      "fav": "Najpopularniejsze",
      "priceAsc": "Najniższa cena",
      "rentPriceAsc": "Najniższa cena",
      "priceDesc": "Najwyższa cena",
      "rentPriceDesc": "Najwyższa cena",
      "priority": "Priorytet",
      "mdate": "Ostatnia aktualizacja",
      "countryName": "Kraj",
      "vat": "VAT",
      "sumGross": "Kwota"
    },
    "jobTypes": {
      "wedding": "Ślub",
      "family_portrait": "Portret rodzinny",
      "maternity": "Macierzyństwo",
      "newborn": "Noworodek",
      "couple": "Para",
      "pet": "Zwierzę domowe",
      "commercial_advertising": "Reklama",
      "fashion": "Moda",
      "model_portfolio": "Portfolio modeli",
      "event": "Wydarzenie",
      "headshots": "Nagłówki",
      "real_estate_and_architecture": "Nieruchomości i architektura",
      "other": "Inne"
    },
    "pipelineEvent": {
      "afterLeadCreated": "stworzony zgodnie z leadem",
      "afterJobAccepted": "zaakceptowany po zakończeniu pracy",
      "beforeJobScheduled": "przed planowaną pracą",
      "afterJobScheduled": "po zaplanowanym zadaniu",
      "afterGalleryCreated": "stworzony zgodnie z galerią",
      "afterPreviousStage": "po poprzednim etapie"
    },
    "paymentEvents": {
      "beforeJobDate": "przed datą pracy",
      "onJobDate": "w dniu pracy",
      "afterJobDate": "według daty pracy",
      "afterInvoiceDate": "po dacie wystawienia faktury"
    },
    "autocomplete": {
      "placeholder": "Wybierz...",
      "noResultsText": "Nie znaleziono wyników",
      "loadingPlaceholder": "Ładowanie"
    },
    "salesDoc": {
      "debtor_number": "Numer klienta",
      "order_number": "Numer zamówienia",
      "order_totals": "Suma zamówień",
      "quote_from": "Od",
      "quote": "Oferta",
      "quote_for": "Oferta dla",
      "quotes": "Oferty",
      "new_quote": "Nowa oferta",
      "invoice_from": "Od",
      "invoice": "Faktura",
      "invoice_date": "Data faktury",
      "tax_invoice": "Faktura",
      "order": "porządek",
      "credit_note": "Nota kredytowa",
      "credit_notes": "Noty kredytowe",
      "invoice_for": "Faktura za",
      "invoices": "Faktury",
      "new_invoice": "Nowa faktura",
      "cancellation_invoice_to": "Faktura za anulowanie dla",
      "cancellation_invoice": "Faktura za anulowanie",
      "to_refunded": "Wciąż do zwrotu:",
      "positions": "Pozycje",
      "issued": "Wydano",
      "cancelled": "STORNO",
      "billing_period": "Okres rozliczeniowy",
      "billing_period_not_valid": "Nieprawidłowy okres rozliczeniowy",
      "pro_rate": "proporcjonalnie",
      "name": "Nazwa",
      "description": "Opis",
      "quantity": "Ilość",
      "unit_price": "Cena jednostkowa",
      "inc_tax": "Podatki wliczone w cenę",
      "exc_tax": "Bez podatków",
      "subtotal": "Suma częściowa",
      "total": "Całkowita kwota",
      "amount_due": "Łącznie netto",
      "total_due": "Kwota faktury",
      "target": "Klient",
      "due_date": "Do dnia",
      "to_pay": "Nadal do zapłaty:",
      "payed": "Płatne",
      "payment": "Płatność",
      "download_csv_ready": "Eksport CSV zakończony",
      "no_vat_eu_country": "Nieopodatkowane dostawy wewnątrzwspólnotowe zgodnie z § 4 nr 1b UstG.",
      "no_vat_non_eu_country": "Zwolniona z podatku dostawa eksportowa zgodnie z § 6 UstG",
      "thanks_for_order": "Dziękujemy za złożenie zamówienia. O ile nie określono inaczej, powyższe usługi zostaną wykonane w dniu wystawienia niniejszej faktury.",
      "bill_by_date": "Prosimy o przelanie kwoty, podając numer faktury, na następujące konto do %date%:",
      "intended_use": "Przeznaczenie",
      "paid_with_cash_cc": "Faktura została zapłacona gotówką / kartą EC",
      "amount_from_credit_card": "Karta kredytowa zostanie obciążona kwotą %amount%.",
      "amount_from_paypal": "Kwota %amount% zostanie pobrana z konta PayPal użytkownika.",
      "debit_from_account": "Kwota %amount% zostanie pobrana z konta użytkownika.",
      "register_and_taxes": "Rejestr handlowy i podatki",
      "managing_director": "Dyrektor zarządzający"
    },
    "provisionDoc": {
      "provision_calculation": "Obliczanie prowizji",
      "client": "Klient",
      "invoice_no_date": "Nr faktury / data",
      "payedDate": "zapłacone w dniu",
      "serviceType": "Typ usługi",
      "amount": "Kwota",
      "provisionRate": "Stawka prowizji",
      "provisionValue": "Komisja",
      "totalAmount": "Całkowita kwota",
      "provision": "Komisja",
      "provisions": "Prowizje",
      "provision_for": "Komisja ds.",
      "download_pdf": "Pobierz PDF"
    },
    "paymentStatus": {
      "late": "Późno",
      "paid": "Płatne",
      "unpaid": "Nieopłacony",
      "inProgress": "W toku"
    },
    "shopItem": {
      "plus_shipping": "plus koszty wysyłki",
      "variants_possible": "Dostępne są różne opcje wyboru"
    },
    "sepaAgreement": {
      "sepa_direct_debit_mandate": "Upoważnienie do polecenia zapłaty SEPA (autoryzacja polecenia zapłaty)",
      "mandate_reference": "Odniesienie do mandatu",
      "will_be_communicated_separately": "zostaną przekazane osobno",
      "payee": "Odbiorca płatności",
      "creditor": "Wierzyciel",
      "payer": "Płatnik",
      "i_allow_payee_direct_debit": "Niniejszym upoważniam odbiorcę płatności do pobierania płatności z mojego konta w drodze polecenia zapłaty do odwołania. Jednocześnie zlecam mojemu bankowi honorowanie poleceń zapłaty pobieranych z mojego konta przez odbiorcę.",
      "note_i_can_claim_reimbursement_within_eight_weeks": "Uwaga: Mogę zażądać zwrotu pobranej kwoty w ciągu ośmiu tygodni od daty obciążenia. Obowiązują warunki uzgodnione z moim bankiem.",
      "document_issued_electronically_and_valid_without_signature": "List ten został utworzony automatycznie i jest ważny również bez podpisu",
      "shortening_notice_period": "Skrócenie okresu wypowiedzenia",
      "deadline_for_advance_information_of_direct_debit_is_shortened_to_one_day": "Termin wcześniejszego powiadomienia o poleceniu zapłaty SEPA został skrócony do jednego dnia.",
      "i_issue_sepa_direct_debit_mandate": "Niniejszym wystawiam upoważnienie do polecenia zapłaty SEPA"
    },
    "sad": {
      "offer": "Oferta",
      "modification": "Poprawka",
      "order": "porządek",
      "offers": "Oferty",
      "orders": "Zamówienia",
      "offer_short": "Ang.",
      "order_short": "Najlepsze.",
      "conversion_rate_short": "CR",
      "conversion_rate": "Współczynnik konwersji",
      "last_offer_date": "Ostatnia oferta",
      "last_offer_date_short": "Ostatni Ang.",
      "car_data": "Dane pojazdu",
      "class": "Klasa",
      "model_name": "Nazwa modelu",
      "model_name_short": "Nazwa modelu (krótka)",
      "model_range": "Seria",
      "body_design": "Kształt ciała",
      "car_color": "Kolor",
      "base_price": "Cena podstawowa",
      "list_price": "Cena katalogowa",
      "optional_equipment": "Wyposażenie specjalne",
      "incl": "w tym.",
      "factory_discount": "Osiedle fabryczne",
      "cash_discount": "Rabat gotówkowy",
      "volume_discount": "Rabat ilościowy",
      "transportation_costs": "Koszty transferu",
      "price_reduction": "Obniżka ceny",
      "car_image": "Obraz pojazdu z konfiguratora",
      "discount": "Nieruchomość",
      "sales_price": "Cena sprzedaży",
      "max_speed": "Prędkość maksymalna",
      "car_acceleration": "Przyspieszenie (0 - 100)",
      "online_code": "Kod online",
      "sales_activity": "Działalność sprzedażowa",
      "last_modified": "Ostatnia zmiana",
      "offer_date": "Data oferty",
      "order_date": "Data zamówienia",
      "order_location": "Lokalizacja zamówienia",
      "pre_contract_nr": "V-Order no.",
      "pre_contract_date": "V order dat.",
      "contract_nr": "Nr zamówienia.",
      "shipment_place": "Miejsce dostawy",
      "shipment_key": "Klucz dostawy",
      "special_agreement": "Umowa specjalna",
      "contact_person": "Osoba do kontaktu",
      "customer_account_number": "Nr konta dłużnika.",
      "financing": "Finansowanie",
      "leasing": "Leasing",
      "financing_product": "Produkt",
      "paid_deposit": "Zaliczka",
      "paid_deposit_leasing": "Specjalna opłata leasingowa",
      "credit_amount": "Całkowita kwota pożyczki",
      "monthly_rate": "Rata miesięczna",
      "contract_duration": "Czas działania",
      "total_mileage": "Przebieg",
      "purchase_price": "Cena zakupu",
      "annual_percentage_rate": "Efektywna roczna stopa procentowa",
      "nominal_interest_percentage_rate": "Odsetki debetowe stałe p.a.",
      "debit_interest_percentage_rate": "Oprocentowanie nominalne p.a.",
      "total_amount": "Całkowita kwota",
      "final_instalment": "Ostatnia rata",
      "application_date": "Data złożenia wniosku",
      "application_approval_date": "Data autoryzacji",
      "application_rejection_date": "Data odrzucenia",
      "client_private": "Prywatny",
      "client_business": "Komercyjne",
      "monthly_leasing_rate": "Miesięczna rata leasingowa",
      "monthly_financing_rate": "Miesięczna rata finansowania",
      "monthly_insurance_rate": "Miesięczna składka na ubezpieczenie komunikacyjne",
      "no_winleas_data_available": "Brak dostępnych danych WinLEAS",
      "winleas_data_available": "Dane dotyczące finansowania (F) i/lub leasingu (L) dostępne w WinLEAS",
      "discount_cash": "Karetka gotówkowa",
      "discount_volume": "Rabat na ilość/recykler",
      "discount_environmental": "Premia środowiskowa",
      "discount_additional": "Dodatkowe warunki",
      "deduction": "Zniżka",
      "deduction_mbl": "Zniżka MBL",
      "deduction_mbl_interest": "Dyskonto odsetkowe MBL",
      "deduction_consultant": "Konsultant ds. rabatów",
      "deduction_consultant_interest": "Konsultant ds. dyskontowania stóp procentowych",
      "commission": "Komisja",
      "commission_standard": "Standard",
      "commission_addition_deduction": "Oznaczanie w górę / oznaczanie w dół",
      "commission_subsequent_scope_of_supply": "Następny. Zakres dostawy",
      "commission_total": "Łącznie",
      "subsidy": "Dotacja",
      "subsidy_contract_partner": "Wiceprezes ds. dotacji",
      "subsidy_factory": "Zakład subsydiowania",
      "subsidy_mbvd": "Subwencja MBVD",
      "shortcut_lp_kp": "LP/KP",
      "campaign": "Działanie",
      "service_rate": "Stawka za usługę",
      "residual_value": "Wartość rezydualna",
      "table_residual_value": "Pozostała wartość tabeli",
      "residual_value_change": "Zmiana wartości rezydualnej",
      "ref": "w odniesieniu do",
      "net": "netto",
      "gross": "brutto",
      "list_price_short": "LP",
      "sales_price_short": "KP",
      "note_save": "Zapisz notatkę",
      "notes": "Uwagi",
      "salesman": "Sprzedawca",
      "salesman_blacklist": "Sprzedawca (czarna lista)",
      "salesman_number": "Nr sprzedawcy.",
      "salesman_number_short": "Nie.",
      "enterprise_number": "Nr firmy.",
      "Mercedes": "Mercdes",
      "Smart": "Inteligentny",
      "equipment_line": "Linia",
      "packages": "Pakiety",
      "upholstery": "Tapicerka",
      "single_equipments": "Inne",
      "included_in": "zawarte w",
      "store": "Oddział",
      "save_filterset": "Zapisz zestaw filtrów",
      "internal_activity": "Działalność wewnętrzna",
      "offer_made": "Utworzona oferta",
      "in_progress": "W toku",
      "predecessor_offer": "Poprzednia oferta / duplikat",
      "lost": "Utracony biznes",
      "ordered": "Zamówienie zrealizowane",
      "canceled": "Zamówienie anulowane"
    },
    "rad": {
      "earnings": "Wydajność",
      "occupancy_rate": "Wykorzystanie",
      "amount": "Kwota"
    },
    "external_account_status": {
      "login_fail": "Sprawdź dane dostępu! W przeciwnym razie nie będzie można odczytać więcej danych.",
      "login_warning": "Sprawdź dane dostępu!",
      "login_valid": "Prawidłowe dane dostępu",
      "last_successful_login": "Ostatnie udane logowanie"
    },
    "categoryitems_stock": "Kawałek",
    "initialize": "Inicjał",
    "customer": "Firma",
    "customer_": "Klient",
    "select_mandant": "Wybierz klienta",
    "initial_title": "Początkowy tytuł nośnika reklamowego",
    "will_be_shown_to_the_user_in_frontend": "Jest wyświetlany użytkownikom w interfejsie użytkownika",
    "producttemplate_data_saved": "Zapisane dane szablonu",
    "product_size": "Format / rozmiar produktu",
    "template_upload_files": "Wybierz plik/pliki do przesłania",
    "example_of_product_size": "np: DIN A4, DIN long...",
    "output_format": "Format wyjściowy",
    "product_size_width": "Szerokość w pikselach",
    "product_size_height": "Wysokość w pikselach",
    "template_folder": "Ścieżka szablonu",
    "template_dpi": "DPI dla wyjścia",
    "template_upload": "Przesyłanie",
    "template_pageSpace": "Przestrzenie siatki",
    "output_exportType": "Typ wyjścia",
    "template_fonts": "Czcionki",
    "template_fontSizeMin": "Rozmiar czcionki min",
    "template_styles": "Dane CSS i LESS",
    "template_fontSizeMax": "Maksymalny rozmiar czcionki",
    "template_init_amount_of_pages": "Początkowa liczba stron",
    "template_page_add_increase": "Niewierność",
    "template_pageMaxAmount": "Liczba stron maks.",
    "template_pageMinAmount": "Liczba stron min",
    "template_pageTop": "Wyściółka górna",
    "template_pageBottom": "Wyściółka dolna",
    "template_pageFirst": "Numer strony Strona tytułowa",
    "template_pageFirstFixed": "Poprawiono stronę główną",
    "template_pageLast": "Numer strony Ostatnia strona",
    "template_pageLastFixed": "Poprawiono ostatnią stronę",
    "template_endless": "Niekończąca się strona",
    "template_fixed": "Strona naprawiona",
    "template_sample_page": "Liczba stron Standardowa zawartość",
    "font_colors": "Kolory tekstu",
    "back_colors": "Kolory tła",
    "client_logo": "Logo firmy",
    "template_pageNumber": "Liczba stron",
    "page_names": "Ustawienia strony",
    "add_contentBlock": "Dodaj blok zawartości",
    "css": "Ogólne CSS",
    "cssPDF": "CSS tylko dla wyjścia PDF",
    "cssPrintPDF": "CSS tylko dla wydruku PDF",
    "cssWebsite": "CSS dla wyjścia jako strona internetowa",
    "chosenItem": "Aktualny wybór produktów",
    "pdf_price": "Cena PDF",
    "auflagen": "Warunki",
    "print_prices": "Ceny bazowe",
    "proof_base_price": "Sprawdzenie danych dotyczących ceny bazowej",
    "new_proof_base_price": "Sprawdzenie nowych danych dotyczących ceny bazowej",
    "get_vendor_price": "Ceny wywoławcze",
    "add_variant_line": "Dodaj linię",
    "remove_all_item_variant": "Usuń wszystkie edycje",
    "get_vendor_prices": "Dostawca wzywający do zapłaty ceny",
    "get_vendor_id": "Zapytanie o identyfikator produktu dostawcy",
    "no_vendor_id": "Nie ustawiono identyfikatora produktu dostawcy!",
    "cc_item_id": "ID produktu CreaCheck",
    "vendor_item_id": "ID produktu Dostawca",
    "vendor_item_name": "Nazwa produktu Dostawca",
    "vendor_item_missing_attributes": "Nie można znaleźć ceny dla tej kombinacji atrybutów.",
    "vendor_item_save_attributes": "Wybierz atrybuty!",
    "vendor_item_must_define_attributes": "Aby utworzyć warianty, należy zdefiniować atrybuty.",
    "vendor_item_has_no_attributes": "Ta grupa produktów nie ma atrybutów",
    "vendor_item_all_n_quantities": "Pokazuje wszystkie ilości {quantityCount}",
    "vendor": "Dostawca",
    "internal_item_id": "Wewnętrzny identyfikator",
    "select_vendor_product": "Wybierz grupę produktów",
    "item_type": "Typ produktu",
    "print_item": "Produkt do druku",
    "website_item": "Strona internetowa",
    "normal_item": "Inny produkt",
    "not_more_pages": "Nie można utworzyć więcej nazw stron niż jest możliwych stron!",
    "info_title_page": "Strony tytułowej nie można przenieść ani usunąć",
    "info_last_page": "Ostatniej strony nie można przenieść ani usunąć",
    "page_definition_page_count_info": "Numer strony 0 = wartość domyślna dla wszystkich stron bez definicji, ostatnia dla ostatniej strony",
    "padding_on_top": "Górny rozstaw",
    "padding_on_bottom": "Odległości poniżej",
    "padding_on_left": "Odstęp w lewo",
    "padding_on_right": "Odstęp w prawo",
    "delimitation_of_grid_blocks": "Bloki siatki można dodawać bez ograniczeń. Strona staje się wtedy coraz dłuższa.",
    "fixed_page": "Strona jest stała i nie można jej przenieść.",
    "page_definition": "Definicja strony",
    "add_definition": "Dodaj definicję",
    "page_default_value": "Numer strony 0 = wartość domyślna dla wszystkich stron bez definicji",
    "page_count": "Liczba stron",
    "page_widths": "Szerokości boczne",
    "page_heights": "Wysokości boczne",
    "no_special_formatting": "Brak specjalnego formatowania",
    "min_max_font_size": "Min-/Max-FontSize",
    "activate_min_max_font_size": "Aktywacja lub dezaktywacja Min-/Max-FontSize",
    "filename": "Nazwa pliku",
    "for_user": "Dla użytkowników",
    "user_can_use_font": "Użytkownik może używać tej czcionki w edytorze",
    "import_successful": "Import zakończony, zajrzyj do innych zakładek",
    "import_resets_template": "Import resetuje szablon, musi zostać ponownie zainicjowany, wszystkie istniejące edycje klienta z tym szablonem stają się bezużyteczne!!!!",
    "problem": "Wystąpił problem",
    "reading_pages": "Czytanie stron",
    "done": "Zakończony",
    "versions": "Wersje",
    "template_not_edited": "Ten szablon nie został jeszcze edytowany.",
    "dataProof_infoText": "W ramach ręcznej kontroli danych nasi eksperci sprawdzają wszystkie kryteria techniczne etapu przygotowania do druku. Obejmują one drukowalność, zgodność ze wszystkimi specyfikacjami technicznymi i subiektywny efekt kolorystyczny. Więcej szczegółowych informacji na temat sprawdzania danych można znaleźć w sekcji FAQ.",
    "media_library": "Biblioteka multimediów",
    "count": "Ilość",
    "welcome_message": "Witaj, {imię}",
    "email": "e-mail",
    "website": "Strona internetowa",
    "phone": "Telefon",
    "fax": "faks",
    "password": "hasło",
    "repeat_password": "Powtórz hasło",
    "company": "Firma",
    "company_name": "Nazwa firmy",
    "company_data_timeline_tab_title": "Oś czasu danych firmy",
    "company_data_success": "Dane firmy zostały pomyślnie zmienione!",
    "company_data_error": "Błąd podczas zmiany danych firmy!",
    "updated_by": "Zaktualizowane przez",
    "companies": "Firmy",
    "first_name": "Imię",
    "last_name": "Nazwisko",
    "street": "ul.",
    "house_number": "Numer domu",
    "apartment_number": "Numer mieszkania",
    "address_line2": "Linia adresowa 2",
    "zip_code": "Kod pocztowy",
    "city": "Miasto",
    "country": "Kraj",
    "currency": "Waluta",
    "previous_year": "Poprzedni rok",
    "vat_name": "Nazwa VAT",
    "vat_id_name": "Identyfikator VAT Nazwa",
    "vat_id": "VAT - IdNr",
    "vat_rates": "Stawki VAT",
    "coc_number": "Numer COC",
    "registered_at": "Zarejestrowany pod adresem",
    "page": "Strona",
    "page_of": "z",
    "save": "Zapisz",
    "save_and_email": "Zapisz i wyślij e-mailem",
    "add": "Dodaj",
    "add_client": "Dodaj firmę",
    "update_company_data": "Zmiana nazwy firmy",
    "add_user": "Dodaj użytkownika",
    "add_product": "Dodaj produkt",
    "add_producttemplate": "Dodaj szablon",
    "add_question": "Dodaj pytanie",
    "add_option": "Dodaj opcję",
    "add_questionnaire": "Dodaj kwestionariusz",
    "add_template": "Dodaj szablon",
    "add_position": "Dodaj pozycję",
    "add_pipeline": "Dodaj rurociąg",
    "add_quote": "Dodaj ofertę",
    "add_invoice": "Dodaj fakturę",
    "add_stage": "Dodaj poziom",
    "add_gallery": "Dodaj galerię",
    "add_job": "Dodaj pracę",
    "add_payment_schedule": "Dodaj plan płatności",
    "add_payment": "Dodaj płatność",
    "add_location": "Dodaj lokalizację",
    "add_data_definition": "Dodaj definicję danych",
    "add_car": "Dodaj pojazd",
    "add_document": "Dodaj dokument",
    "add_document_type": "Dodaj typ dokumentu",
    "add_team": "Dodaj zespół",
    "add_brand": "Dodaj markę",
    "add_model": "Dodaj model",
    "add_model_group": "Dodaj grupę modeli",
    "add_model_asset": "Dodaj obraz modelu",
    "add_category": "Dodaj kategorię",
    "add_attribute": "Dodaj właściwość",
    "add_attribute_group": "Dodaj grupę atrybutów",
    "add_value": "Wartość dodana",
    "add_variant": "Dodaj wariant",
    "add_lead": "Dodaj ołów",
    "add_task": "Dodaj zadanie",
    "add_page": "Dodaj stronę",
    "add_data_push": "Dodawanie danych push",
    "add_white_label": "Dodaj projekt białej etykiety",
    "add_menu_element": "Dodaj pozycję menu",
    "add_secondary_menu_element": "Dodaj dodatkowy element menu",
    "add_cms_element": "Dodaj element CMS",
    "add_lead_catcher": "Dodaj głównego łapacza",
    "add_white_label_plugin": "Dodaj wtyczkę white label",
    "add_lead_per_mail_rule": "Reguła dodawania potencjalnych klientów przez e-mail",
    "add_message": "Dodaj wiadomość",
    "add_car_service": "Dodaj usługę",
    "add_car_service_station": "Dodaj stację roboczą",
    "add_team_member": "Dodaj członka zespołu",
    "add_document_template": "Dodaj szablon dokumentu",
    "add_lead_autoresponse": "Dodaj automatyczną odpowiedź leadów",
    "add_main_menu_version": "Dodaj wersję menu głównego",
    "add_lead_tagger": "Dodaj głównego taggera",
    "add_lead_assignment_strategy": "Dodaj strategię przypisywania",
    "add_escalation_rule": "Dodaj regułę eskalacji",
    "add_coupon": "Dodaj kupon",
    "add_company": "Dodaj firmę",
    "add_item": "Dodaj produkt",
    "add_manual_entry": "Dodaj ręczne wprowadzanie danych",
    "add_allowed_domain": "Dodaj autoryzowaną domenę",
    "update_allowed_domain": "Aktualizacja autoryzowanej domeny",
    "send_message": "Wyślij wiadomość",
    "send": "Wyślij",
    "details": "szczegóły",
    "delete": "Usuń",
    "undelete": "Przywracanie",
    "undelete_user_confirm": "Przywrócić użytkownika?",
    "or": "lub",
    "actions": "Działania",
    "client": "Firma",
    "job": "Praca",
    "active_jobs": "Aktywne miejsca pracy",
    "questionnaire": "Kwestionariusz",
    "review_and_send": "Sprawdź i wyślij",
    "download": "Pobierz",
    "duplicate": "Duplikat",
    "client_name": "Nazwa",
    "address": "Adres",
    "title": "Tytuł",
    "uploading": "Przesyłanie",
    "status": "Status",
    "next_stage": "Następny poziom",
    "type": "Typ",
    "schedule": "Harmonogram",
    "location": "Lokalizacja",
    "sent_on": "Wysłane",
    "name": "Nazwa",
    "description": "Opis",
    "bill_number": "Numer faktury",
    "quote_number": "Numer oferty",
    "do_sort": "Sortuj",
    "date": "data",
    "gallery": "Galeria",
    "questionnaires": "Kwestionariusze",
    "job_takes_whole_day": "Ta praca zajmuje cały dzień",
    "from": "Od",
    "to": "Do",
    "question": "Pytanie",
    "subject": "Temat",
    "content": "Zawartość",
    "template": "Szablon",
    "stage_has_due_date": "Ten etap ma datę zakończenia",
    "stage_sends_automated_email": "Na tym etapie wysyłana jest automatyczna wiadomość e-mail",
    "day": "Dzień",
    "days": "Dni",
    "hours": "Godziny",
    "review": "Sprawdź",
    "timezone": "Strefa czasowa",
    "client_since": "Firma dodała na",
    "user_since": "Użytkownik od",
    "payment_instructions": "Instrukcje dotyczące płatności",
    "bank_name": "Nazwa banku",
    "debtor_number": "Identyfikator dłużnika",
    "account_holder": "Posiadacz konta",
    "account_number": "Numer konta",
    "bank_location": "Lokalizacja banku",
    "bank_code_number": "Kod sortowania banku",
    "iban": "IBAN",
    "swift_bic": "SWIFT/BIC",
    "company_logo": "Logo firmy",
    "percent_of_order": "Procent zamówienia",
    "percent_of_remaining_balance": "Procent pozostałej kwoty",
    "amount": "Kwota",
    "due": "Z powodu",
    "payment_schedule": "Plan płatności",
    "record_payment": "Płatność dziennika",
    "mark_as_unpaid": "Oznacz jako nieopłacone",
    "close": "Zamknij",
    "yes": "Tak",
    "want_to_mark_the_payment_as_paid": "Czy naprawdę chcesz oznaczyć tę płatność jako opłaconą?",
    "want_to_mark_the_payment_as_unpaid": "Czy naprawdę chcesz oznaczyć tę płatność jako niezapłaconą?",
    "payment_marked_as_paid": "Płatność zarejestrowana",
    "payment_marked_as_unpaid": "Płatność oznaczona jako niezapłacona",
    "overdue_payments": "Zaległe płatności",
    "next_payment": "Następna płatność",
    "quote_accepted": "Oferta zaakceptowana",
    "quote_rejected": "Oferta odrzucona",
    "todays_tasks": "Dzisiejsze zadania",
    "overdue_tasks": "Zaległe zadania",
    "overdue_and_upcoming_payments": "Zaległe i nadchodzące płatności",
    "jobs_in_progress": "Zadania w toku",
    "appointments": "Daty",
    "appointment": "Data",
    "last_update": "Ostatnia aktualizacja",
    "mark_as_completed": "Oznacz jako ukończone",
    "want_to_mark_stage_complete": "Czy chcesz oznaczyć etap jako ukończony?",
    "stage_marked_complete": "Etap oznaczony jako ukończony",
    "gallery_download": "Pobierz obraz",
    "gallery_sharing": "Udostępnij galerię",
    "gallery_social_media": "Obrazy w mediach społecznościowych",
    "preview": "Podgląd",
    "invoice_preview": "Podgląd faktury",
    "preview_pdf": "Podgląd PDF",
    "message": "Wiadomość",
    "signup": "Rejestr",
    "continue": "Kontynuuj",
    "password_assistance": "Pomoc dotycząca hasła",
    "password_renewing": "Nowe hasło",
    "not_any_user_information": "Brak informacji o użytkowniku.",
    "failed_redirection": "Przekierowanie nie powiodło się.",
    "renew_password": "Zanim będziesz mógł się zalogować, musisz ustawić nowe hasło. Właśnie wysłaliśmy wiadomość e-mail z instrukcjami.",
    "get_new_password": "Żądanie nowego hasła",
    "set_new_password": "Ustaw nowe hasło",
    "forgot_password": {
      "infotext": "Jeśli zapomniałeś hasła, możesz je zresetować tutaj. Wprowadź swój zarejestrowany adres e-mail w poniższym formularzu i kliknij \"Kontynuuj\". Następnie wyślemy wiadomość e-mail z dalszymi informacjami.",
      "infotext_robethood": "Jeśli zapomniałeś hasła, możesz je zresetować tutaj. Wprowadź zarejestrowany adres e-mail w poniższym formularzu i kliknij \"Kontynuuj\". Następnie wyślemy wiadomość e-mail z dalszymi informacjami.",
      "info_email_not_used": "Jeśli nie korzystasz już z adresu e-mail powiązanego z Twoim kontem, skontaktuj się z nami za pomocą naszego formularza kontaktowego: {link}.",
      "info_email_not_used_robethood": "Jeśli nie korzystasz już z adresu e-mail powiązanego z Twoim kontem, skontaktuj się z nami za pośrednictwem naszego formularza kontaktowego: {link}.",
      "enter_new_password": "Przed Tobą tylko ten krok, aby zalogować się do Creacheck. Wystarczy dwukrotnie wprowadzić nowe hasło. To takie proste."
    },
    "thanks_for_signing_up": "Dziękujemy za rejestrację",
    "confirm_your_email": "Potwierdź swój adres e-mail",
    "signed_up_gratulations": "Witamy w CreaCheck. Wysłaliśmy wiadomość e-mail, aby dokończyć proces rejestracji. Prosimy o potwierdzenie rejestracji.",
    "signed_up_gratulations_robethood": "Witamy na naszej stronie internetowej. Wysłaliśmy wiadomość e-mail, aby dokończyć proces rejestracji. Prosimy o potwierdzenie rejestracji.",
    "check_email_for_message_from_us": "Sprawdź swoją skrzynkę e-mail.",
    "check_email_for_message_from_us_robethood": "Sprawdź swoją skrzynkę e-mail",
    "new_clients": "Nowi klienci",
    "users": "Użytkownicy",
    "user": "Użytkownicy",
    "won_from_count_leads": "Uzyskane od {count} potencjalnych klientów",
    "deals_won_out_of_count": "Transakcje wygrane przez {count}",
    "new_badge": "Nowość",
    "answer_badge": "Odpowiedź",
    "accept": "Akceptuj",
    "reject": "Odrzucić",
    "quote_rejected_on_date": "Oferta odrzucona w dniu {data}",
    "quote_accepted_on_date": "Oferta zaakceptowana w dniu {data}",
    "want_to_accept_the_quote": "Czy chcesz zaakceptować ofertę? Kontrahent zostanie bezpośrednio poinformowany o Twojej decyzji.",
    "want_to_reject_the_quote": "Czy chcesz odrzucić ofertę? Kontrahent zostanie bezpośrednio poinformowany o Twojej decyzji.",
    "want_to_convert_quote_to_invoice": "Czy chcesz przekształcić tę ofertę w fakturę?",
    "convert_quote_to_invoice": "Przekształć ofertę w fakturę",
    "convert_to_invoice": "Konwersja na fakturę",
    "convert_quote_from_template": "Konwertowanie oferty na inny szablon",
    "completed_on_date": "Ukończono w dniu {data}",
    "due_date_on_date": "Termin płatności {data}",
    "due_date": "Termin płatności",
    "current_stages_due_date": "Data zakończenia bieżącego etapu",
    "go_pro": "Go Pro",
    "recent_activity": "Ostatnia aktywność",
    "load_more": "Załaduj więcej",
    "your_galleries": "Twoje galerie",
    "unread_by_you": "Nieprzeczytane przez Ciebie",
    "count_images": "{liczba} obrazów",
    "view": "Widok",
    "filled_out_on": "Wypełniono na",
    "filled_out_by": "Wypełnione przez",
    "you_answered_the_questionnaire_on_date": "Odpowiedziałeś na kwestionariusz w dniu {data}.",
    "company_sent_a_questionnaire": "{firma} wysłała kwestionariusz i prosi o udzielenie odpowiedzi",
    "allow_to_download_full_res": "Umożliwienie klientowi pobrania obrazów w pełnej rozdzielczości",
    "allow_to_share_with_friends": "Umożliwienie klientowi udostępniania galerii znajomym",
    "allow_to_share_on_social_media": "Umożliwienie klientowi udostępniania zdjęć za pośrednictwem mediów społecznościowych",
    "got_it": "OK",
    "learn_more": "Dowiedz się więcej",
    "this_website_uses_cookies": "Nasza witryna wykorzystuje pliki cookie, aby zapewnić użytkownikom jak najlepsze korzystanie z niej.",
    "are_you_sure": "Jesteś pewien?",
    "no": "Nie",
    "closed_please_add_timeframe": "<span><span className='text-danger semi-bold'>Zamknięte</span> - prosimy o dodanie ram czasowych</span>.",
    "not_active_please_add_timeframe": "<span><span className='text-danger semi-bold'>Nieaktywne</span> - dodaj ramy czasowe</span>",
    "note": "Uwaga",
    "change_password": "Zmiana hasła",
    "roles": "Role i prawa",
    "active_set": "Bieżąca subskrypcja",
    "infos": "Informacje na temat",
    "documents": "Dokumenty",
    "document": "Dokument",
    "financial": "Finansowy",
    "bookingAccountNumber": "Konto pocztowe",
    "costAccountNumber": "Centrum kosztów",
    "locations": "Lokalizacje",
    "white_label": "Biała etykieta",
    "show_password_text": "Pokaż tekst hasła",
    "parent": "Rodzic",
    "change_parent": "Zmień rodzica",
    "cancel": "Anuluj",
    "timeline_undo": "Cofnij",
    "confirm_action_ramifications": "Potwierdź, że rozumiesz konsekwencje swojego działania",
    "client_will_not_be_visible_in_search_results": "Wybrany klient nie jest już widoczny w wyszukiwarce klientów.",
    "changing_parent_takes_a_while": "Zmiana prekursora może trochę potrwać",
    "load_message_from_template": "Załaduj wiadomość z szablonu",
    "questions": "Pytania",
    "search": "Wyszukiwanie",
    "search_for": "Szukaj",
    "dynamic_data_definitions": "Dynamiczne definicje danych",
    "dynamic_data_definition": "Dynamiczna definicja danych",
    "data_identifier": "Identyfikator danych",
    "cars": "Pojazdy",
    "rentals": "Czynsz",
    "no_car_available": "Brak dostępnego wózka",
    "file": "Plik",
    "files": "Pliki",
    "teams": "Zespoły",
    "team": "Zespół",
    "document_types": "Typy dokumentów",
    "document_type": "Typ dokumentu",
    "system_type": "Typ systemu",
    "brands": "Marki",
    "models": "Modele",
    "model": "Model",
    "model_groups": "Grupy modeli",
    "model_group": "Grupa modeli",
    "model_assets": "Zdjęcia modeli",
    "model_asset": "Obraz modelu",
    "categories": "Kategorie",
    "attributes": "Atrybuty",
    "attribute": "Atrybut",
    "values": "Wartości",
    "value": "Wartość",
    "select_category": "Wybierz kategorię",
    "position": "Pozycja",
    "color": "Kolor",
    "car": "Produkt",
    "category": "Kategoria",
    "brand": "Marka",
    "required": "Pole obowiązkowe",
    "multilanguage": "Wiele języków",
    "seo": "SEO",
    "page_title": "Tytuł strony",
    "keywords": "Słowa kluczowe",
    "item": "Artykuł",
    "sku": "SKU",
    "variants": "Warianty",
    "variant": "Wariant",
    "price": "Cena",
    "prices": "Ceny",
    "variant_attributes": "Atrybuty wariantu",
    "no_value_set": "Brak ustawionej wartości",
    "identification": "Identyfikacja",
    "templates": "Szablony",
    "vin": "FIN",
    "leads": "Przewody",
    "lead": "Ołów",
    "order_it": "Zamówienie",
    "order": "porządek",
    "orders": "Zamówienia",
    "images": "Zdjęcia",
    "image": "Zdjęcie",
    "upc": "UPC",
    "visibility": "Widoczność",
    "show_item_online": "Produkt widoczny online",
    "use_as_resource": "Używanie jako zasobu",
    "stock_and_prices": "Zapasy i ceny",
    "sales_price": "Cena sprzedaży",
    "sales_point": "Dystrybucja",
    "warehouse": "Magazyn",
    "stock_level": "Stan magazynowy",
    "rent_price": "Cena wynajmu",
    "pseudo_price": "Cena pseudo",
    "express_price": "Cena ekspresowa",
    "overnight_price": "Cena za noc",
    "sameday_price": "Cena jednodniowa",
    "basic_price": "Cena podstawowa",
    "weekday_price": "Cena za dzień powszedni",
    "weekend_price": "Cena weekendowa",
    "week_price": "Cena tygodniowa",
    "month_price": "Cena miesięczna",
    "unit_of_measure": "Jednostka miary",
    "basic_unit": "Jednostka podstawowa",
    "packaging_unit": "Jednostka opakowania",
    "subscribe": "Subskrybuj",
    "all": "Wszystkie",
    "pipeline": "Rurociąg",
    "pipelines": "Rurociągi",
    "default_team": "Standardowy zespół",
    "select_pipeline": "Wybór rurociągu",
    "please_select_pipeline_first": "Najpierw wybierz rurociąg",
    "view_type": "Widok",
    "stage": "Poziom",
    "stages": "Kroki",
    "properties": "Właściwości",
    "unassigned": "Bez przydziału",
    "assignee": "Procesor",
    "order_type": "Typ zamówienia",
    "internal_comment": "Komentarz wewnętrzny",
    "comments": "Komentarze",
    "comment": "Komentarz",
    "no_comments_yet": "Nie ma jeszcze komentarzy",
    "show_all_comments": "Pokaż wszystkie komentarze",
    "people": "Osoby",
    "watchers": "Obserwator",
    "dates": "Daty",
    "date_created": "Utworzony",
    "date_updated": "Aktualizacja",
    "closed": "Zamknięte",
    "start_watching_lead": "Zacznij obserwować ten trop",
    "stop_watching_lead": "Przestań oglądać ten lead",
    "no_longer_watching_lead": "Nie obserwujesz już tej przewagi",
    "watching_lead": "Obserwujesz ten trop",
    "wrote_comment": "Napisał komentarz",
    "replied_to_comment": "Odpowiedział w komentarzu",
    "language": "Język",
    "languages": "Języki",
    "primary_language": "Główny język",
    "secondary_languages": "Języki drugorzędne",
    "won": "Wygrana",
    "lost": "Zagubiony",
    "change_pipeline": "Potok zmian",
    "start_pipeline": "Uruchomienie rurociągu",
    "pipeline_started": "Rurociąg uruchomiony",
    "change_stage": "Poziom zmiany",
    "switch_team": "Zespół ds. zmian",
    "open": "Otwarty",
    "budget": "Budżet",
    "always_available": "Zawsze dostępny",
    "default_location": "Standardowa lokalizacja",
    "selected_category_has_no_attributes": "Wybrana kategoria nie ma atrybutów",
    "car_overview": "Przegląd pojazdu",
    "make_reservation": "Dokonaj rezerwacji",
    "reservation": "Rezerwacja",
    "cms_pages": "Strony",
    "grid_fluid": "Płyn",
    "grid_normal": "Normalny",
    "cms_add_container": "Dodaj pojemnik",
    "cms_add_row": "Dodaj linię",
    "cms_add_element": "Dodaj element",
    "media_files": "Pliki multimedialne",
    "upload": "Przesyłanie",
    "page_design": "Projekt strony",
    "translations": "Tłumaczenia",
    "translate": "Tłumaczenie",
    "category_filters": "Filtr kategorii",
    "attribute_groups": "Grupy atrybutów",
    "select_attribute": "Wybierz atrybut",
    "display": "Wyświetlacz",
    "select_multiple": "Wybór wielokrotny",
    "use_null_as_min_val": "Ustaw 0 jako minimum",
    "filter": "Filtry",
    "do_filter": "Filtr",
    "mobile_phone": "Telefon komórkowy",
    "mark_lead_won": "Oznacz przewagę jako wygraną",
    "mark_lead_lost": "Oznacz przewagę jako utraconą",
    "mark_lead_deleted": "Oznacz lead jako usunięty",
    "reopen_lead": "Ponowne otwarcie przewodu",
    "won_reason": "Powód, dla którego wygrał",
    "lost_reason": "Powód przegranej",
    "delete_reason": "Powód usunięcia",
    "reopen_reason": "Powód ponownego otwarcia",
    "no_reason_specified": "Nie podano przyczyny",
    "lead_marked_won": "Zwyciężył Lead marked. Gratulacje",
    "lead_marked_lost": "Ołów oznaczony jako utracony",
    "lead_marked_deleted": "Ołów oznaczony jako usunięty",
    "lead_reopened": "Ołów ponownie otwarty",
    "stage_changed": "Zmiana poziomu ołowiu",
    "pipeline_changed": "Zmiana potoku potencjalnych klientów",
    "team_changed": "Lider przypisany do innego zespołu",
    "current_pipeline": "Bieżący rurociąg",
    "current_stage": "Bieżący poziom",
    "file_extension": "Rozszerzenie",
    "file_size": "Rozmiar",
    "file_uploaded": "Przesłano",
    "no_client_assigned": "Brak przypisanego klienta",
    "no_products_assigned": "Brak przypisanych produktów",
    "no_order_assigned": "Brak przypisanej kolejności",
    "planned_duration_in_days": "Planowany czas trwania (dni)",
    "lead_budget_must_be_set": "Należy ustalić budżet wiodący",
    "client_must_be_assigned": "Klient musi być przypisany",
    "order_must_be_assigned": "Zamówienie musi zostać przypisane",
    "product_must_be_assigned": "Produkt musi być przypisany",
    "lead_has_to_have_following_documents": "Główny kandydat musi posiadać następujące dokumenty (dokumenty muszą być ważne)",
    "client_has_to_have_following_documents": "Klient musi posiadać następujące dokumenty (dokumenty muszą być ważne)",
    "count_days": "{liczba, liczba mnoga, jeden {# dzień} inne {# dni}}",
    "longer": "Dłuższy",
    "date_from": "Data od",
    "time_from": "Czas od",
    "date_to": "Data do",
    "time_to": "Czas do",
    "rate": "Stawka",
    "car_rental_weekday": "Wynajem - stawka za dzień tygodnia",
    "car_rental_weekend": "Wynajem - stawka weekendowa",
    "car_rental_week": "Wynajem - tygodniowa rata",
    "car_rental_month": "Wynajem - rata miesięczna",
    "document_overdue_please_review": "Dokument przeterminowany. Sprawdź",
    "count_cars": "{liczba, liczba mnoga, jeden {# pojazd} inne {# pojazdy}}.",
    "count_products": "{liczba, liczba mnoga, jeden {# produkt}, inne {# produkty}}.",
    "we_found_count_cars": "Znaleźliśmy {liczba, liczba mnoga, jeden {# pojazd} inne {# pojazdy}}.",
    "we_found_count_products": "Znaleźliśmy {liczba, liczba mnoga, jeden {# produkt} inne {# produkty}}.",
    "delete_all_filters": "Usuń wszystkie filtry",
    "image_recognition_tags": "Tagi rozpoznane na podstawie obrazu",
    "username": "Nazwa użytkownika",
    "handover_must_be_performed": "Należy wypełnić protokół przekazania pojazdu",
    "document_pages": "Strony dokumentu",
    "pages": "Strony",
    "handover": "Przekazanie",
    "mileage": "Przebieg",
    "client_documents": "Dokumenty klienta",
    "lead_documents": "Główne dokumenty",
    "fuel_level": "Poziom paliwa",
    "start_editing": "Rozpocznij edycję",
    "design": "Projekt",
    "responsive": "Responsywny",
    "back": "Powrót",
    "use_text_description": "Użyj opisu tekstowego",
    "use_visual_builder": "Użyj kreatora wizualnego",
    "set_image": "Ustaw obraz",
    "select_image": "Wybierz obraz",
    "uploaded_by_me": "Przesłane przeze mnie",
    "current_item": "Aktualny artykuł",
    "current_page": "Bieżąca strona",
    "difficulties": "Trudności",
    "damages": "Uszkodzenie",
    "damage_note": "Zawiadomienie o szkodzie",
    "no_damages_in_protocol": "Brak uszkodzeń w dzienniku",
    "add_damage": "Dodaj obrażenia",
    "my_company": "Moja firma",
    "user_administration": "Administracja użytkownikami",
    "client_administration": "Zarządzanie kontaktami",
    "crm_administraion": "Zarządzanie CRM",
    "content_administration": "Zarządzanie treścią",
    "product_administration": "Zarządzanie produktem",
    "producttemplate_administration": "Zarządzanie szablonami",
    "car_service_administration": "Zarządzanie warsztatem",
    "site_administration": "Administracja stroną internetową",
    "data_push": "Wypychanie danych",
    "client_has_no_ancestors": "Klient nie ma przodków",
    "domain": "Domena",
    "entity": "Podmiot",
    "event": "Wydarzenie",
    "endpoint_url": "Adres URL punktu końcowego",
    "authentication": "Uwierzytelnianie",
    "headers": "Nagłówki",
    "none": "Brak",
    "social_media": "Media społecznościowe",
    "style": "Styl",
    "html_header": "Nagłówek HTML",
    "custom_html_header": "Dostosowany nagłówek HTML",
    "css_file_name": "Nazwa pliku CSS zdefiniowana przez użytkownika",
    "custom_css": "Niestandardowy CSS",
    "custom_less": "LESS zdefiniowane przez użytkownika",
    "custom_font_folder": "Wybierz katalog czcionek",
    "custom_font": "Prześlij niestandardową czcionkę",
    "custom_css_mail": "Dostosowany CSS dla wiadomości e-mail",
    "main_menu": "Menu główne",
    "menu_css_class": "Klasa CSS menu głównego",
    "menu_css_class_scroll": "Klasa CSS menu głównego podczas przewijania",
    "white_label_projects": "Projekty white label",
    "imprint": "Nadruk",
    "terms_of_service": "GTC",
    "rental_terms_of_service": "Ogólne warunki wynajmu",
    "car_sales_terms_of_service": "Ogólne warunki sprzedaży pojazdów używanych",
    "item_sales_terms_of_service": "Ogólne warunki sprzedaży produktów",
    "privacy_policy": "Ochrona danych",
    "cancellation_policy": "Polityka anulowania",
    "footer": "Stopka",
    "hide_footer": "Ukryj stopkę",
    "unknown_property": "Nieznany parametr",
    "unknown": "nieznany",
    "price_from": "z",
    "price_to": "do",
    "slider_from": "z",
    "slider_to": "do",
    "within": "w ramach",
    "of": "z",
    "within_count_km_of_place": "w promieniu {liczba} km od {miejsce}",
    "click_to_start_geolocation": "Kliknij tutaj, aby rozpocząć geolokalizację",
    "geolocation_error": "Wystąpił problem z rozpoznaniem pozycji lub pozycja nie jest znana. Wprowadź swoją pozycję ręcznie.",
    "count_km_from": "km od",
    "car_power": "Wydajność",
    "car_power_unit": "PS",
    "car_gear": "Skrzynia biegów",
    "car_capacity": "Przemieszczenie",
    "car_capacity_unit": "cm³",
    "car_fueltype": "Rodzaj paliwa",
    "car_type": "Typ pojazdu",
    "car_num_previous_owners": "Liczba właścicieli pojazdów",
    "car_num_doors": "Liczba drzwi",
    "car_num_seats": "Liczba miejsc",
    "car_emission_class": "Klasa zanieczyszczenia",
    "car_condition": "Stan pojazdu",
    "car_construction_year": "Rok budowy",
    "car_color_manufacturer": "Kolor (producent)",
    "car_parking_assistance": "Pomoc w parkowaniu",
    "car_interior_material": "Materiał wyposażenia wnętrza",
    "car_airbags": "Poduszki powietrzne",
    "car_air_conditioning": "Klimatyzacja",
    "car_environment_badge": "Odznaka środowiskowa",
    "car_co2_emissions": "Emisje CO2",
    "car_co2_emissions_unit": "g/km",
    "car_fuel_consumption": "Zużycie",
    "car_fuel_consumption_unit": "l/100km",
    "combined": "połączony",
    "combined_short": "grzebień.",
    "urban": "miejski",
    "extra_urban": "poza miastem",
    "energy_efficiency_class": "Klasa efektywności energetycznej",
    "technical_data": "Dane techniczne",
    "first_registration": "Pierwsza rejestracja",
    "proceed_to_checkout": "Kasa",
    "home": "Strona główna",
    "checkout": "Kasa",
    "your_cart": "Twój wózek na zakupy",
    "your_car": "Twój samochód",
    "your_data": "Twoje dane",
    "services": "Usługa",
    "shipping_information": "Transfer",
    "payment_details": "Płatność",
    "summary": "Podsumowanie",
    "find_out_more": "Dowiedz się więcej",
    "qty": "Ilość",
    "checkout_next": "Dalej",
    "checkout_previous": "Powrót",
    "please_select": "Wybierz",
    "enum_many_glue": ",",
    "enum_last_glue": " oraz",
    "enum_end": " wybór",
    "show_details": "Pokaż szczegóły",
    "add_to_cart": "Dodaj do koszyka",
    "show_cart": "Pokaż koszyk zakupów",
    "cart": "Koszyk na zakupy",
    "missingQuantity": {
      "cart": "koszyk"
    },
    "cart_is_empty": "Koszyk jest pusty",
    "item_already_in_cart": "Ten przedmiot jest już w koszyku",
    "remove": "Usuń",
    "removed_from_cart": "została usunięta z koszyka",
    "continue_with_login": "Kontynuuj logowanie",
    "dont_have_an_account": "Brak konta",
    "continue_without_login": "Kontynuuj bez logowania",
    "already_have_an_account_enter_username_and_password": "Masz już konto? Wprowadź swoją nazwę użytkownika i hasło w poniższym formularzu.",
    "continue_without_login_and_register": "Możesz kontynuować proces zakupu i utworzyć konto tutaj.",
    "continue_booking_without_login_and_register": "Możesz kontynuować proces rezerwacji i utworzyć konto tutaj",
    "online_discount": "Zniżka online",
    "your_personal_data": "Dane osobowe użytkownika",
    "login_data": "Dane dostępu",
    "shipping": "Wysyłka",
    "payment_cash": "Gotówka",
    "payment_creditCard": "Karta kredytowa",
    "payment_payPal": "PayPal",
    "payment_wire_transfer": "Przelew bankowy",
    "payment_bill": "Faktura",
    "choose_different_payment_method": "Wybierz inną metodę płatności",
    "i_will_pick_up_my_car": "Odbiorę swój samochód",
    "i_will_pick_up_my_order": "Odbiorę moje zamówienie",
    "i_want_my_car_delivered": "Chciałbym, aby mój pojazd został dostarczony.",
    "i_want_my_order_delivered": "Chciałbym, aby moje zamówienie zostało dostarczone",
    "we_could_not_calculate_route_to_address": "Nie mogliśmy obliczyć trasy do podanego adresu. Sprawdź i spróbuj ponownie.",
    "credit_card_holder": "Posiadacz karty kredytowej",
    "credit_card_number": "Numer karty kredytowej",
    "car_registration": "Rejestracja pojazdu",
    "car_insurance": "Ubezpieczenie samochodu",
    "service_provided_in_country_by_company": "Usługa w {kraj} świadczona przez {firma}",
    "i_wish_to_have_car_registered": "Tak, chcę korzystać z usługi autoryzacji",
    "i_wish_to_get_car_insurance": "Chciałbym wykupić ubezpieczenie samochodu",
    "today": "Dzisiaj",
    "in_10_days": "Za 10 dni",
    "your_money_is_safe_with_us": "Twoje pieniądze są u nas bezpieczne",
    "car_transport": "Transport samochodowy",
    "delivery": "dostawa",
    "decide_car_delivery_or_pickup": "To Ty decydujesz, czy chcesz odebrać swój samochód osobiście, czy zlecić jego dostarczenie. Tak czy inaczej, samochód będzie w idealnym stanie, czysty i z pełnym bakiem paliwa.",
    "secured_payment_until_car_is_delivered": "Bezpieczny proces płatności do momentu dostawy pojazdu",
    "e_payment_to_reserve_car_and_secure_services": "Płatność elektroniczna w celu rezerwacji samochodu i zabezpieczenia wybranych usług",
    "e_payment_credit_card_paypal": "Płatność elektroniczna (karta kredytowa lub PayPal)",
    "multiple_secure_options_to_pay_for_the_car": "Bezpieczne opcje płatności za samochód (przelew bankowy, gotówka przy odbiorze)",
    "buy_car_by_clicking_button": "Możesz kupić ten samochód już teraz, klikając poniższy przycisk",
    "payment_with_credit_card_ending_last4_expiring_exp_month_exp_year_issued_to_card_holder": "Płatność kartą kredytową kończąca się na {last4} i wygasająca w {expMonth}/{expYear}, wydana {cardHolder}",
    "click_on_paypal_checkout_to_continue": "Kliknij przycisk Paypal Checkout, aby kontynuować.",
    "paypal_is_approved_continue_to_summary": "Płatność PayPal została autoryzowana. Przejdź do podsumowania, aby dokończyć zamówienie i autoryzować płatność.",
    "paypal_buttons_loading": "Załaduj przyciski PayPal...",
    "please_wait_saving_order": "Zapisz zamówienie. Poczekaj...",
    "error_loading_paypal_scripts": "Nie można załadować skryptów PayPal. Zamknij to wyskakujące okienko i otwórz je ponownie.",
    "link": "Link",
    "hide_on_devices": "Ukryj na urządzeniach",
    "mobile_phones": "Telefony komórkowe",
    "tablets": "Tablety",
    "desktop_pcs": "Komputery stacjonarne",
    "show_content_on_mouse_over": "Wyświetlanie zawartości po najechaniu myszą",
    "menu_content": "Zawartość menu",
    "link_to_page": "Link do strony",
    "page_has_no_translations": "Ta strona nie ma tłumaczeń",
    "search_results": "Wyniki wyszukiwania",
    "press_enter_to_search": "Naciśnij klawisz Enter, aby wyszukać",
    "no_results_found": "Nie znaleziono wyników",
    "create_new_entry_now": "Możesz teraz utworzyć nowy wpis:",
    "no_results_for_your_search": "Nie znaleźliśmy żadnych wyników dla Twojego zapytania",
    "change_image": "Zmień obraz",
    "employee_data": "Dane pracowników",
    "newsletter": "Zgoda na otrzymywanie biuletynów",
    "editorAutosave": "Automatyczne zapisywanie w edytorze",
    "is_profile_public": "Czy profil jest publiczny?",
    "parent_page": "Strona nadrzędna",
    "cms_elements": "Elementy CMS",
    "cms_element": "Element CMS",
    "new_lead_from_website": "Nowy lead ze strony internetowej",
    "website_settings": "Ustawienia strony internetowej",
    "profile": "Profil",
    "my_bills": "Moje faktury",
    "integrations": "Integracje",
    "email_templates": "Szablony wiadomości e-mail",
    "cms_templates": "Szablony CMS",
    "cms_template": "Szablon CMS",
    "select_brand": "Wybierz markę",
    "all_brands_visible": "Wszystkie marki są widoczne",
    "contact_us": "Skontaktuj się z nami",
    "thank_you": "Dziękuję bardzo",
    "thank_you_for_contacting_us": "Dziękujemy za skontaktowanie się z nami. Otrzymaliśmy Twoje zapytanie i skontaktujemy się z Tobą tak szybko, jak to możliwe",
    "cms_standard": "Standard",
    "select_element": "Wybierz element CMS",
    "divider": "Dzielnik",
    "testimonial": "Zalecenie",
    "hero_unit": "Element bohatera",
    "image_carousel": "Suwak",
    "call_to_action": "Wezwanie do działania",
    "bar_counter": "Lada barowa",
    "products": "Produkty",
    "producttemplates": "Szablony",
    "single_product": "Pojedynczy produkt",
    "single_producttemplate": "Pojedynczy szablon",
    "lead_form": "Główny formularz",
    "image_carousel_element": "Obraz suwaka",
    "cms_grid": "Pojemnik",
    "cms_row": "Linia",
    "cms_col": "Kolumna",
    "text_color": "Kolor tekstu",
    "bar_counter_now": "Teraz",
    "shadow": "Cień",
    "no_shadow": "Bez cienia",
    "small": "Mały",
    "big": "Duży",
    "rounded_corners": "Zaokrąglone rogi",
    "no_rounded_corners": "Brak zaokrąglonych narożników",
    "image_position": "Pozycja obrazu",
    "icon": "Ikona",
    "icon_class": "Klasa ikony (CSS)",
    "position_top": "Powyżej",
    "position_left": "Linki",
    "bar_color": "Kolor paska",
    "bar_background_color": "Kolor tła paska",
    "button_icon": "Symbol przycisku",
    "button_text": "Tekst przycisku",
    "full_height": "Pełna wysokość",
    "padding_top": "Odległość powyżej",
    "padding_bottom": "Odległość poniżej",
    "lead_source": "Główne źródło",
    "change_item": "Zmień artykuł",
    "show_infos_on_top": "Pokaż informacje powyżej",
    "show_infos_on_bottom": "Pokaż informacje poniżej",
    "condensed": "Skondensowany",
    "assign_item": "Przypisywanie produktu",
    "image_animation": "Animacja obrazu",
    "no_animation": "Brak animacji",
    "fade_in": "Zanikanie",
    "from_left": "Od lewej",
    "from_right": "Od prawej",
    "from_top": "Z góry",
    "from_bottom": "Od dołu",
    "image_gallery": "Galeria zdjęć",
    "elements": "Elementy",
    "accordion": "Akordeon",
    "map": "Mapa",
    "height": "Wysokość",
    "video": "Wideo",
    "link_to_video": "Link do filmu",
    "video_possible_sources": "Obsługiwane są filmy z następujących portali:: YouTube, Vimeo, Dailymotion",
    "autoplay": "Automatyczne odtwarzanie",
    "aspect_ratio": "Współczynnik kształtu",
    "image_compare": "Porównaj obraz",
    "second_image": "Drugie zdjęcie",
    "login_form": "Formularz logowania",
    "button_signin": "Zaloguj się",
    "alternative_login": "...lub zaloguj się za pomocą",
    "alternative_login_long": "Użyj naszej funkcji logowania społecznościowego",
    "login_with": "Zaloguj się za pomocą",
    "show_facebook_login": "Pokaż login do Facebooka",
    "show_google_login": "Pokaż login Google",
    "signup_form": "Formularz rejestracyjny",
    "signup_legal_explanation": "Rejestracja - oświadczenie prawne",
    "signup_agreement_text": "Rejestrując się, wyrażam zgodę na {terms}.",
    "contact_agreement_text": "Przeczytałem i zrozumiałem {data_use}.",
    "signup_agreement_text_terms": "Ogólne warunki handlowe",
    "signup_agreement_text_data_use": "Polityka prywatności",
    "signup_agreement_text_cancellation_policy_and_form": "Polityka anulowania rezerwacji i formularz anulowania rezerwacji",
    "will_become_link_with_title_title": "link z tytułem: {title}",
    "show_only_when_user_logged_id": "Wyświetlane tylko wtedy, gdy użytkownik jest zalogowany",
    "car_rental_form": "Formularz wynajmu samochodu",
    "car_search_form": "Formularz wyszukiwania samochodu",
    "car_search_funnel": "Konfigurator wyszukiwania samochodów",
    "background_image": "Obraz tła",
    "background_color": "Kolor tła",
    "same_col_height": "Ta sama wysokość kolumny",
    "pick_up_and_drop_off": "Odbiór i zwrot",
    "pick_up_time": "Czas odbioru",
    "drop_off_time": "Czas powrotu",
    "get_a_quote": "Określić ceny",
    "select_location": "Wybierz lokalizację",
    "type_address_to_start_search": "Wprowadź adres, aby rozpocząć wyszukiwanie",
    "menu_version": "Wersja menu",
    "menu_transparent": "Przezroczysty",
    "menu_dark": "Ciemny",
    "menu_light": "Światło",
    "logo_version": "Wersja z logo",
    "logo_default": "Użyj standardowego logo",
    "logo_scroll": "Użyj logo przewijania",
    "select_a_pick_up_location": "Najpierw wybierz lokalizację odbioru",
    "could_not_find_location_please_enter_more_details": "Nie mogliśmy znaleźć lokalizacji. Wprowadź lokalizację odbioru wraz z dalszymi szczegółami",
    "client_not_found": "Nie znaleziono klienta",
    "order_will_directly_be_marked_paid": "Zamówienie jest natychmiast oznaczane jako opłacone",
    "invoice_will_directly_be_marked_paid": "Faktura jest natychmiast oznaczana jako zapłacona",
    "rental_period": "Okres wynajmu",
    "change_booking_dates": "Zmiana danych rezerwacji",
    "per_day": "dziennie",
    "buy_now": "Kup teraz",
    "book_now": "Zarezerwuj teraz",
    "rent_car_now": "Wynajmij teraz",
    "commit_to_buy": "Zamówienie z obowiązkiem zapłaty",
    "back_to_cart": "Powrót do koszyka zakupów",
    "equipment": "Sprzęt",
    "technical_specifications": "Dane techniczne",
    "cancel_checkout_question": "Anulować płatność?",
    "how_it_works": "Jak to działa",
    "secure_payment": "Bezpieczna płatność",
    "top_services": "Najlepsze usługi",
    "if_you_buy_this_car_you_will_get_lots_of_services": "Kupując ten samochód, nie obawiaj się formalności. Na życzenie możemy zorganizować rejestrację, ubezpieczenie i kredyt samochodowy. Wystarczy wybrać żądaną usługę przy kasie.",
    "need_more_info_question": "Potrzebujesz więcej informacji?",
    "contact_dealer": "Kontakt ze sprzedawcą",
    "move_around_to_see_changes": "Przesuń, aby zobaczyć zmiany",
    "lead_catchers": "Główni łapacze",
    "lead_catcher": "Główny łapacz",
    "advanced_routing": "Zaawansowany routing",
    "add_route": "Dodaj trasę",
    "source": "Źródło",
    "can_be_ordered_online": "Można zamówić online",
    "count_years_warranty": "{liczba} {liczba, liczba mnoga, jeden {rok} inne {lata}} Gwarancja",
    "amount_online_advantage": "{kwota} Korzyści online",
    "your_order": "Twoje zamówienie",
    "file_name": "Nazwa pliku",
    "tags": "Tagi",
    "save_and_set_image": "Zapisywanie i ustawianie",
    "choose_or_drop_file_to_upload": "Wybierz lub przeciągnij plik na stronę przesyłania.",
    "media_formats_supported": "Obsługiwane są formaty JPG i PNG.",
    "max_file_upload_size": "Maksymalny rozmiar pliku to 15 MB",
    "no_files_found": "Nie znaleziono żadnych plików.",
    "upload_file_by_dragging_over": "Prześlij plik, po prostu przeciągając go na stronę.",
    "your_car_is_ready_to_check_out": "Twój wymarzony samochód jest gotowy",
    "choosing_one_of_our_cars_is_a_good_decision": "Jeszcze tylko kilka kroków do wymarzonego pojazdu. Skorzystaj z naszych zalet online.",
    "your_car_will_be_delivered_to_you": "Pojazd jest dostarczany do wybranej lokalizacji",
    "your_will_get_a_amount_discount_for_ordering_online": "W przypadku zamówień online otrzymasz rabat w wysokości {kwota}.",
    "you_will_receive_extended_warranty": "Otrzymujesz przedłużoną gwarancję",
    "you_will_get_exclusive_offers_when_using_our_app": "Otrzymujesz ekskluzywne oferty, gdy korzystasz z naszej aplikacji mobilnej",
    "you_dont_risk_when_ordering_online": "Zamawiając online nie ryzykujesz zbyt wiele.",
    "your_car_rent_is_nearly_done": "Twój samochód jest już prawie gotowy!",
    "please_continue_with_booking_and_payment": "Dokonaj rezerwacji i płatności. Dopilnujemy, aby samochód był gotowy w wybranym dniu odbioru. Rezerwacja online oszczędza czas!",
    "please_provide_address_delivery_method_payment": "Wprowadź swój adres, wybierz metodę wysyłki i szczegóły płatności.",
    "your_information_is_safe_with_us": "Twoje dane są u nas bezpieczne.",
    "we_offer_parcel_shipment_for_just_amount": "Dla Twojej wygody oferujemy dostawę jednej paczki dla wszystkich naszych zamówień za jedyne {kwota}.",
    "free_delivery_on_all_our_orders": "Oferujemy bezpłatną dostawę wszystkich naszych zamówień.",
    "get_your_car_delivered": "dostawa",
    "cant_pick_your_car_yourself_we_got_you_covered": "Co się stanie, jeśli znajdziesz swój wymarzony samochód i nie będziesz mógł go odebrać? Bez obaw. Mamy rozwiązanie. Po zarejestrowaniu i ubezpieczeniu samochodu kierowca umówi się z Tobą na dostawę i zaparkuje samochód na Twoim podjeździe.",
    "we_are_more_than_happy_to_show_you_around_your_new_car": "Oczywiście nie możemy się doczekać, aby powitać Cię osobiście i pokazać Ci Twój nowy samochód.",
    "we_can_complete_your_order_in_shop_for_pickup": "Jeśli jednak chcesz, możemy skompletować Twoje zamówienie w naszym sklepie i możesz je odebrać, gdy będziesz mieć na to czas.",
    "we_will_inform_you_when_we_complete_order_for_pickup": "Poinformujemy Cię, gdy zrealizujemy Twoje zamówienie. Następnie będziemy cierpliwie czekać na jego odbiór.",
    "we_will_ship_your_order_to_provided_address": "Zamówienie zostanie wysłane przez DHL na podany adres. Poinformujemy Cię, gdy zamówienie zostanie wysłane. Otrzymasz również numer śledzenia zamówienia.",
    "we_calculated_route_from_car_to_you": "Obliczyliśmy trasę od miejsca, w którym znajduje się samochód, do podanego adresu. Wygląda na to, że nasz kierowca musi przejechać {odległość} km, aby dostarczyć samochód pod Twoje drzwi.",
    "service_includes": "Usługa obejmuje:",
    "driver_will_call_you_and_schedule_delivery": "Nasz kierowca zadzwoni do Ciebie i dostarczy samochód w dogodnym dla Ciebie czasie",
    "your_car_will_be_washed": "Twój samochód jest myty",
    "your_car_will_be_fully_tanked": "Twój samochód jest zatankowany benzyną",
    "you_will_get_quick_tour_around_car": "Otrzymasz krótką wycieczkę po samochodzie",
    "you_can_pick_up_car_yourself": "Masz jakieś pytania? Nasz zespół z przyjemnością Ci pomoże.",
    "only_one_step_left": "Jeszcze tylko jeden krok!",
    "check_if_everything_is_correct_and_provide_payment_data": "Sprawdź, czy wszystko się zgadza, podaj szczegóły płatności i prześlij zamówienie.",
    "terms_of_contract_for_additional_services": "Warunki umowne dla usług dodatkowych",
    "terms_of_use_marketplace": "Warunki korzystania z platformy handlowej",
    "for_the_sales_of_used_vehicles": "Sprzedaż używanych pojazdów",
    "for_the_rental_of_vehicles": "Wynajem pojazdów",
    "your_payment_schedule": "Przebieg płatności",
    "shipping_pickup": "Kolekcja",
    "shipping_parcel": "Pakiet",
    "shipping_transport": "Transport",
    "car_interior": "Wyposażenie wewnętrzne",
    "car_exterior": "Wyposażenie zewnętrzne",
    "we_are_processing_your_order_please_wait": "Przetwarzamy zamówienie. Proszę czekać...",
    "we_are_processing_your_booking_please_wait": "Przetwarzamy Twoją rezerwację. Proszę czekać...",
    "your_order_was_completed_successfully": "Zamówienie zostało pomyślnie zrealizowane.",
    "we_created_a_user_profile": "Utworzyliśmy dla Ciebie profil użytkownika",
    "check_your_email": "Sprawdź swoją skrzynkę odbiorczą",
    "your_orders": "{liczba, liczba mnoga, jeden {numer zamówienia} inne {numery zamówień}}:",
    "first_experience_with_our_shop_saved_profile": "Wygląda na to, że po raz pierwszy korzystasz z naszego sklepu internetowego. Pomyślnie zapisaliśmy informacje o Twoim profilu w naszej bazie danych. Aby kontynuować przetwarzanie zamówienia, sprawdź swoją skrzynkę pocztową i potwierdź swój adres e-mail.",
    "order_details_sent_to_email_check_history_for_details": "Wszystkie szczegóły zamówienia zostały wysłane na Twój adres e-mail. Historię i status zamówień można w każdej chwili wyświetlić na koncie użytkownika.",
    "timeline": "Oś czasu",
    "promotions": "Działania",
    "payment_method": "Metoda płatności",
    "first_payment": "1. płatność",
    "second_payment": "2. płatność",
    "shipping_method": "Metoda wysyłki",
    "payment_1_e_payment_to_secure_your_order": "Wpłać depozyt za pomocą płatności elektronicznej, aby zarezerwować pojazd.",
    "payment_2_choose_payment_method_for_the_car": "Wybierz metodę płatności dla swojego pojazdu.",
    "payment_escrow": "Rachunek depozytowy",
    "payment_car_credit": "Finansowanie",
    "full_refund": "Pełny zwrot pieniędzy",
    "partial_refund": "Częściowy zwrot kosztów",
    "cancel_order": "Anuluj zamówienie",
    "create_shipping": "Tworzenie wysyłki",
    "schedule_car_registration": "Autoryzacja",
    "schedule_transport": "Plan transportu",
    "is_published": "Został opublikowany",
    "no_salesman_available": "Żaden sprzedawca nie jest obecnie dostępny",
    "any_questions": "Pytania?",
    "feel_free_to_ask_our_online_sales_team": "Wystarczy skontaktować się z naszym zespołem sprzedaży online",
    "please_complete_your_details": "Prosimy o uzupełnienie danych",
    "registration_is_stressfull_get_your_car_registered": "Dla niektórych osób rejestracja samochodu jest stresująca i czasochłonna. Zarejestruj samochód na swoje nazwisko bez kłopotów.",
    "review_your_order": "Sprawdź swoje zamówienie",
    "in_order_to_confirm_identity_please_complete_your_details": "Aby potwierdzić Twoją tożsamość, potrzebujemy kilku dodatkowych informacji:",
    "payment_instructions_will_be_provided_after_seller_accepts_order": "Sprzedawca przekaże szczegółowe instrukcje dotyczące płatności po zaakceptowaniu zamówienia.",
    "nationality": "Narodowość",
    "date_of_birth": "Data urodzenia",
    "place_of_birth": "Miejsce urodzenia",
    "discover_more": "Odkryj więcej",
    "more_of_product": "Więcej {produkt}",
    "more_of_product_in_city": "Więcej {produkt} w {miasto}",
    "order_placed": "Złożone zamówienie",
    "show_order_details": "Pokaż szczegóły zamówienia",
    "white_label_plugins": "Wtyczki białych etykiet",
    "white_label_plugin": "Wtyczka White Label",
    "enhance_chedri_project_with_plugins": "Ulepsz obsługę creacheck za pomocą wtyczek",
    "plugin": "Wtyczka",
    "plugins": "Wtyczki",
    "enhance_your_project_with_plugins": "Możesz wybrać, ile funkcjonalności Creacheck potrzebujesz. Sprawdź dostępne wtyczki, ich funkcje i ceny.",
    "week": "tydzień",
    "month": "miesiąc",
    "quarter": "Kwartał",
    "year": "Rok",
    "more_infos": "Więcej informacji",
    "less_infos": "Mniej informacji",
    "activate_plugin": "Aktywuj wtyczkę",
    "partially_activate_plugin": "Aktywacja wtyczki Sign",
    "terminate_plugin": "Wyjście z wtyczki",
    "detailed_description": "Szczegółowy opis",
    "installation_instructions": "Instrukcje instalacji",
    "you_are_not_an_adminitrator": "Nie jesteś administratorem",
    "creditor_id": "Identyfikator wierzyciela",
    "i_understand_the_cost_and_want_activate_plugin": "Rozumiem koszty i chciałbym aktywować wtyczkę",
    "i_understand_the_running_cost_when_terminating_plugin": "Rozumiem koszty, które zostaną naliczone na koniec okresu rozliczeniowego.",
    "are_you_sure_you_want_to_terminate_plugin": "Czy naprawdę chcesz zatrzymać wtyczkę?",
    "security_settings": "Ustawienia zabezpieczeń",
    "limit_write_rights": "Ograniczenie uprawnień do zapisu",
    "limit_read_rights": "Ograniczenie praw odczytu",
    "edit_your_company_data": "Tutaj możesz edytować dane swojej firmy. Zmiana danych kontaktowych i finansowych wykorzystywanych do celów rozliczeniowych",
    "latest_order": "Ostatnie zamówienie",
    "latest_orders": "Ostatnie zamówienia",
    "latest_invoices": "Ostatnie faktury",
    "latest_editions": "Ostatnia edycja",
    "overdue_invoices": "Należne faktury",
    "view_all": "Pokaż wszystko",
    "i_accept": "Akceptuję",
    "accept_terms_before_continuing": "Zanim przejdziesz dalej, musisz zaakceptować nasz regulamin i politykę prywatności",
    "approve_car_sale": "Autoryzacja sprzedaży samochodu",
    "enter_vin_number": "Wprowadź numer podwozia pojazdu",
    "mark_as_paid": "Oznacz jako opłacone",
    "enter_ein_number": "Wprowadź elektroniczne potwierdzenie ubezpieczenia",
    "send_payment_instructions": "Wyślij instrukcje dotyczące płatności",
    "submit_vin_number": "Wyślij numer podwozia pojazdu",
    "approve_and_inform_client": "Autoryzacja i poinformowanie klienta",
    "submit_ein_number": "Wyślij elektroniczne potwierdzenie ubezpieczenia",
    "ein": "Elektroniczne potwierdzenie ubezpieczenia",
    "payment_reference": "Odniesienie do płatności",
    "upload_sales_cotract": "Prześlij umowę sprzedaży",
    "cancellation_reason": "Powód anulowania",
    "enter_bank_data": "Wprowadź dane bankowe",
    "pickup_invite": "Zaproszenie do odbioru",
    "mark_picked_up": "Oznacz jako pobrane",
    "refunds": "Zwroty kosztów",
    "sent_on_date": "Wysłano dnia {data}",
    "not_sent_yet": "Jeszcze nie wysłane",
    "pick_up_location": "Miejsce odbioru",
    "emails": "E-maile",
    "use_following_list_to_personalize_your_emails": "Skorzystaj z poniższej listy, aby spersonalizować wiadomości e-mail:",
    "recipients_first_name": "Imię i nazwisko odbiorcy",
    "recipients_last_name": "Nazwisko odbiorcy",
    "senders_company_name": "Nazwa firmy nadawcy",
    "system_mail_registration_description": "Wysyłane, gdy nowy użytkownik zarejestruje się w witrynie",
    "system_mail_invitation_description": "Wysyłane, gdy pracownik utworzy nowego użytkownika z adresem e-mail.",
    "system_mail_car_rental_pickup_reminder_description": "Wysyłane na dzień przed odbiorem samochodu przez klienta",
    "system_mail_car_rental_dropoff_reminder_description": "Wysyłane na dzień przed oddaniem przez klienta wynajętego samochodu",
    "terms_of_use": "Warunki użytkowania",
    "contract": "Umowa",
    "order_data_processing": "Umowa o realizację zamówienia",
    "i_read_and_accept_plugins_terms_of_use": "Przeczytałem i zrozumiałem warunki użytkowania i wyraźnie je akceptuję.",
    "i_read_and_accept_plugins_contract": "Przeczytałem i zrozumiałem umowę i wyraźnie ją akceptuję.",
    "i_read_and_accept_plugins_order_data_processing": "Przeczytałem i zrozumiałem zasady przetwarzania danych zamówienia i wyraźnie je akceptuję.",
    "send_activation_request": "Wyślij żądanie aktywacji",
    "missing_companys_bank_data": "Wygląda na to, że brakuje nam danych bankowych Twojej firmy.",
    "you_can_fill_missing_data_in_settings": "Brakujące dane można wprowadzić w {settings}.",
    "not_sole_representative_click_below_to_request_plugin_activation": "Nie masz uprawnień do samodzielnej aktywacji wtyczek. Kliknij przycisk, aby aktywować wtyczkę. Autoryzowany użytkownik zostanie poproszony o dokończenie procesu aktywacji.",
    "not_authorized_click_below_to_request_plugin_activation": "Nie masz uprawnień do aktywacji wtyczek. Kliknij przycisk, aby wysłać prośbę o aktywację do autoryzowanych użytkowników.",
    "select": "Wybierz",
    "enter_app_secret": "Wprowadź sekret aplikacji",
    "enter_page_access_token": "Wprowadź token dostępu do strony",
    "legal": "Prawne",
    "activation_pending": "Oczekiwanie na aktywację",
    "termination_pending": "Anulowanie w toku",
    "form_contains_errors": "W formularzu występują pewne problemy",
    "internal_car_reference": "Wewnętrzne odniesienie do samochodu",
    "system_debtor_number": "Numer debitora systemu",
    "internal_company_reference": "Wewnętrzny numer referencyjny firmy",
    "internal_location_reference": "Wewnętrzne odniesienie do lokalizacji",
    "show_notes": "Notatki z pokazu",
    "show_my_notes": "Pokaż moje notatki",
    "show_by_me": "Pokaż ode mnie",
    "items": "Produkty",
    "send_text_message": "Wyślij SMS",
    "wrote_message": "Wiadomość napisana",
    "sent_text_message": "Jedna wysłana wiadomość SMS",
    "wrote_note": "Uwaga napisana",
    "please_check_document_check_expiration_date": "Sprawdź dokument. Upewnij się, że widziałeś dokument. Sprawdź datę ważności",
    "i_reviewed_the_document": "Sprawdziłem ten dokument",
    "receive_updates_in_messenger": "Czy chcesz otrzymywać aktualizacje w Messengerze?",
    "weve_received_your_request_and_will_contact_you": "Otrzymaliśmy Twoje zapytanie i skontaktujemy się z Tobą tak szybko, jak to możliwe",
    "check_your_email_to_confirm": "Sprawdź pocztę e-mail, aby potwierdzić wysłanie tego formularza kontaktowego.",
    "response_text": "Tekst odpowiedzi",
    "entries": "Wpisy",
    "timeline_block": "Blok osi czasu",
    "external_accounts": "Konta zewnętrzne",
    "send_copy_to_me": "Wyślij kopię do mnie",
    "user_tracking": "Śledzenie użytkownika",
    "select_model": "Wybierz model",
    "select_model_group": "Wybierz grupę modeli",
    "equipment_level_budget": "Budżet",
    "equipment_level_family": "Rodzina",
    "equipment_level_comfort": "Komfort",
    "equipment_level_business": "Biznes",
    "salutation": "Pozdrowienie",
    "getting_started": "Pierwsze kroki",
    "set_your_locations": "Zdefiniuj swoje lokalizacje",
    "set_up_locations_for_your_car_business_set_services_opening_hours_upload_photos": "Każdy pojazd wymaga lokalizacji, z której klienci mogą wybierać. Przejdź do ustawień, gdzie możesz zdefiniować wszystkie żądane lokalizacje. Tam możesz zdecydować, jakie usługi oferujesz w każdej lokalizacji, ustawić godziny otwarcia i przesłać zdjęcia, aby ułatwić klientom znalezienie Twojej firmy.",
    "now_that_locations_are_set_create_users_so_they_can_work_together": "Teraz, gdy Twoi klienci wiedzą, gdzie prowadzisz działalność, daj nam znać, kto dla Ciebie pracuje. Utwórz użytkowników, aby mogli współpracować przy użyciu technologii Creacheck",
    "cms_content": "Zawartość CMS",
    "this_is_blank_page_use_editor_to_create_great_one": "To jest pusta strona. W naszym edytorze wizualnym możesz stworzyć piękną stronę z grafiką, piękną typografią i kompatybilną ze wszystkimi urządzeniami.",
    "cars_to_sell": "Pojazdy na sprzedaż",
    "your_business_is_car_sales_but_there_are_no_cars_create_yourself_or_use_import": "Twoim zadaniem jest sprzedaż pojazdów. Naszym zadaniem jest ułatwienie Ci tego. Nie wprowadziłeś jeszcze żadnych pojazdów do naszego systemu. Skorzystaj z wygodnej funkcji importu pojazdów lub wprowadź je ręcznie.",
    "create_manually": "Utwórz ręcznie",
    "import": "Import",
    "car_import": "Pojazdy importowane",
    "most_car_dealers_use_mobilede_enter_your_interface_username_and_password_to_import": "Jak się dowiedzieliśmy, większość dealerów samochodowych korzysta z mobile.de do prezentowania swoich samochodów. Więc najprawdopodobniej masz dostęp do usługi internetowej. Wprowadź swoją nazwę użytkownika i hasło. Automatycznie zaimportujemy wszystkie Twoje samochody i zsynchronizujemy Twoją ofertę. Jeśli masz kilka danych logowania, możesz wprowadzić więcej w swoim profilu.",
    "we_will_import_count_cars_for_this_username": "Zaimportujemy {count} samochodów dla tej nazwy użytkownika",
    "mobile_de_username": "Nazwa użytkownika API sprzedawcy Mobile.de",
    "mobile_de_password": "Hasło API sprzedawcy Mobile.de",
    "missing_bank_data": "Brakujące dane bankowe",
    "we_dont_have_your_bank_data": "Najwyraźniej nie mamy Twoich danych bankowych",
    "monthly_billing_relies_on_sepa_direct_debit_fill_in_bank_data_in_settings": "Nasze miesięczne rozliczenia opierają się na poleceniu zapłaty SEPA. Ponieważ nie mamy żadnych danych bankowych, nie możesz korzystać z wielu naszych wtyczek. Przejdź do ustawień i wprowadź swoje dane bankowe.",
    "fill_in_bank_data": "Wprowadź dane bankowe",
    "sepa_mandate": "Mandat SEPA",
    "sepa_mandates": "Mandaty SEPA",
    "missing_sepa_mandate": "Brakuje nam mandatu SEPA",
    "changed_bank_data_please_generate_new_sepa_mandate": "Wygląda na to, że niedawno zmieniłeś numer konta bankowego. Aby zautomatyzować nasz comiesięczny proces rozliczeniowy i zmniejszyć stres, prosimy o utworzenie nowego upoważnienia SEPA.",
    "generate_sepa_mandate_now": "Wygenerowanie nowego upoważnienia SEPA",
    "columns": "Kolumny",
    "send_doc_as_message": "Wyślij dokument do klienta (e-mail)",
    "alternative_recepient": "Alternatywny odbiornik",
    "enter_email_address": "Wprowadź prawidłowy adres e-mail",
    "job_title": "Nazwa stanowiska",
    "service_point": "Usługa",
    "rental_point": "Baza wynajmu",
    "seller_data": "Dane sprzedawcy",
    "car_location": "Lokalizacja pojazdu",
    "save_data_before_nav_to_pathname": "Dane nie zostaną zapisane. Czy chcesz przełączyć się na {nazwa_patrycy} bez zapisywania danych?",
    "share": "Udział",
    "assignee_changed_from_assignee_to_assignee": "Cesjonariusz został zmieniony z %oldAssignee% na %newAssignee%.",
    "stage_changed_from_name_to_name": "Poziom został zmieniony z %oldName% na %newName%.",
    "handover_filled_out": "Protokół przekazania pojazdu został ukończony",
    "data_changed": "Aktualizacja danych",
    "key": "klucz",
    "old_value": "Stara wartość",
    "new_value": "Nowa wartość",
    "no_details_available": "Szczegółowe informacje nie są dostępne",
    "services_we_offer_in_city": "Usługi, które oferujemy w {miasto}",
    "damages_present": "Obecne uszkodzenia",
    "user_is_online": "Użytkownik jest online",
    "user_is_offline": "Użytkownik jest offline",
    "click_to_start_chat": "Kliknij tutaj, aby rozpocząć czat",
    "click_to_view_chat_history": "Kliknij tutaj, aby wyświetlić historię czatu",
    "theme_colors": "Kolory tematyczne",
    "logos": "Logo",
    "send_invitation_with_username_and_password": "Wyślij zaproszenie z nazwą użytkownika i hasłem (tylko dla nowych użytkowników)",
    "cookie_settings": "Szczegóły dotyczące plików cookie",
    "accept_only_necessary_cookies": "Akceptuj tylko niezbędne pliki cookie",
    "accept_all_cookies": "Akceptuj wszystkie pliki cookie",
    "privacy_preference_center": "Centrum preferencji ochrony danych",
    "your_privacy": "Twoja prywatność",
    "basic_information_on_cookies": "Podczas odwiedzania witryny internetowej informacje mogą być przechowywane lub pobierane w przeglądarce, zwykle w postaci plików cookie. Informacje te mogą dotyczyć użytkownika, jego preferencji lub urządzenia. Informacje te są wykorzystywane głównie do tego, aby witryna działała zgodnie z oczekiwaniami. Informacje te zazwyczaj nie identyfikują bezpośrednio użytkownika, ale mogą zapewnić bardziej spersonalizowane korzystanie z witryny. Ponieważ szanujemy prawo użytkownika do prywatności, może on zrezygnować z niektórych rodzajów plików cookie. Wybierz kategorię, aby dowiedzieć się więcej i zmienić ustawienia domyślne. Zablokowanie niektórych rodzajów plików cookie może jednak wpłynąć na korzystanie z witryny i oferowanych przez nas usług.",
    "necessary_cookies": "Niezbędne pliki cookie",
    "performance_cookies": "Wydajnościowe pliki cookie",
    "targeting_cookies": "Ukierunkowane pliki cookie",
    "always_active": "Zawsze aktywny",
    "cookies_used": "Używane pliki cookie",
    "necessary_cookies_information": "Te pliki cookie są niezbędne do działania witryny i nie można ich wyłączyć w naszych systemach. Zazwyczaj są one ustawiane tylko w odpowiedzi na działania użytkownika związane z żądaniem usługi, takie jak ustawienie preferencji prywatności, logowanie lub wypełnianie formularzy. Można ustawić przeglądarkę tak, aby blokowała te pliki cookie lub ostrzegała o nich, ale niektóre części witryny nie będą działać. Te pliki cookie nie przechowują żadnych informacji umożliwiających identyfikację użytkownika.",
    "save_settings": "Zapisz ustawienia",
    "save_and_continue": "Zapisz i kontynuuj",
    "priority": "Priorytet",
    "closed_leads": "Zamknięte przewody",
    "show": "Wyświetlacz",
    "hide": "Zabawa w chowanego",
    "first_registration_from": "Pierwsza rejestracja od",
    "first_registration_to": "Pierwsza rejestracja do",
    "power_from": "Moc silnika z",
    "power_to": "Moc silnika do",
    "mileage_from": "Przebieg od",
    "mileage_to": "Przebieg do",
    "included_mileage": "{przebieg} kilometrów",
    "really_cancel": "Naprawdę anulować?",
    "unsaved_information_will_be_lost": "Niezapisane dane zostaną utracone",
    "client_signature": "Podpis klienta",
    "another_photo": "Kolejne zdjęcie",
    "try_again": "Spróbuj ponownie",
    "back_to_vehicle": "Powrót do pojazdu",
    "image_upload_finished": "Przesyłanie obrazów zostało zakończone",
    "you_have_no_internet_connection": "Nie masz połączenia z Internetem",
    "waiting_to_reconnect": "Oczekiwanie na ponowne połączenie",
    "plus_count_more": "+ {count} więcej",
    "document_is_missing": "Brakuje jednego dokumentu",
    "upload_document_name_now": "Prześlij teraz dokument \"{nazwa}",
    "lead_per_mail": "Zasada prowadzenia przez e-mail",
    "create_rules_to_forward_emails": "Tworzenie reguł przekazywania wiadomości e-mail jako kopii do innych użytkowników. Jeśli aktywowana jest wtyczka \"Data e-mail plugin\", można również przekazywać wiadomości e-mail z ważnymi informacjami w formacie XML bezpośrednio do Customer One.",
    "lead_per_mail_rules": "Zasady prowadzenia przez e-mail",
    "include_xml_data_in_email": "Wstawianie danych w formacie XML do wiadomości e-mail",
    "sad_activity": "Aktywność SAD",
    "e_payments": "Płatności elektroniczne",
    "stripe_connect": "Stripe Connect",
    "we_will_handle_epayments_for_you": "Dzięki Stripe Connect możemy przetwarzać płatności kartą kredytową w Twoim imieniu. Po kliknięciu \"Połącz ze Stripe\" możesz użyć istniejącego konta Stripe, aby połączyć się z platformą Creacheck lub utworzyć nowe konto Stripe, jeśli jeszcze go nie masz. Mając własne konto Stripe, możesz zobaczyć całą historię płatności i wypłacić pieniądze w dowolnym momencie.",
    "connect_with_stripe": "Połącz się z Stripe",
    "stripe_accounts": "Konta Stripe",
    "stripe_account_connected": "Twoje konto Stripe jest teraz połączone",
    "stripe_accounts_are_connected": "Twoje konto Stripe jest teraz połączone z {projectName}. {projectName} może teraz akceptować płatności kartą kredytową w Twoim imieniu.",
    "money_won": "Wygrane pieniądze",
    "leads_won": "Pozyskane leady",
    "offered_by": "Dealer samochodowy",
    "pay_with_saved_credit_card": "Zapłać zapisaną kartą kredytową",
    "pay_with_new_credit_card": "Zapłać nową kartą kredytową",
    "settings": "Ustawienia",
    "account": "Funkcje",
    "clients": "Firmy",
    "payments": "Płatności",
    "calendar": "Kalendarz",
    "contact": "Skontaktuj się z nami",
    "login": "Zaloguj się",
    "logout": "Wylogowanie",
    "previous_odometer_reading": "Poprzedni odczyt licznika kilometrów",
    "current_odometer_reading": "Aktualny przebieg",
    "odometer_reading": "Przebieg",
    "tank_volume": "Pojemność zbiornika",
    "vehicle_fuel_costs": "Koszty tankowania pojazdów",
    "mileage_surcharge": "Dodatkowa opłata za kilometr",
    "insurance_self_participation": "Nadwyżka ubezpieczeniowa",
    "additional_costs_due": "Dodatkowe koszty z tytułu",
    "no_additional_costs": "Brak dodatkowych kosztów",
    "charge_customer_additional_costs": "Obciążenie klienta dodatkowymi kosztami",
    "warning_you_chose_not_to_charge_customer_for_additional_costs": "Uwaga! Zdecydowałeś się nie obciążać klienta żadnymi dodatkowymi kosztami",
    "cancel_booking": "Anuluj rezerwację",
    "no_invoices_found": "Nie znaleziono żadnych faktur",
    "upload_drivers_license": "Prześlij prawo jazdy",
    "order_details": "Szczegóły zamówienia",
    "shipping_address": "Adres wysyłki",
    "order_overview": "Przegląd zamówień",
    "booked_by_mistake": "Zarezerwowane przez pomyłkę",
    "booked_wrong_dates": "Zarezerwowano nieprawidłowe daty",
    "found_better_price": "Znaleziono lepszą cenę",
    "change_of_plans": "Zmiana planu",
    "client_called_to_cancel": "Klient zadzwonił i poprosił o anulowanie",
    "client_wrote_email_to_cancel": "Klient napisał wiadomość e-mail z prośbą o anulowanie.",
    "car_damaged_before_transport": "Pojazd został uszkodzony przed transportem",
    "car_damaged_in_transport": "Pojazd został uszkodzony podczas transportu",
    "item_not_available": "Artykuł nie jest już dostępny",
    "other_reason": "Inne",
    "license_plate_number": "Tablica rejestracyjna",
    "open_in_google_maps": "Otwórz w Mapach Google",
    "access_identifier": "Kod dostępu",
    "branch": "Oddział",
    "new": "Nowość",
    "in_progress": "W toku",
    "failed": "Nie powiodło się",
    "not_started": "Nie rozpoczęto",
    "finished": "Zakończony",
    "failed_refunds": "Nieudane zwroty kosztów",
    "list_all_failed_refund_payments": "Lista wszystkich nieudanych zwrotów wraz ze szczegółami",
    "rent_sync_vamoso_reservation_success": "Pomyślnie zarezerwowano w systemie Vamoso. Numer rezerwacji to %reservationId%.",
    "rent_sync_vamoso_reservation_failure": "Nie można było zarezerwować samochodu w systemie Vamoso. System zwrócił następujący komunikat o błędzie: \"%errorText%\"",
    "rent_sync_vamoso_cancellation_success": "Rezerwacja w systemie Vamoso została pomyślnie anulowana.",
    "rent_sync_vamoso_cancellation_failure": "Anulowanie rezerwacji w systemie Vamoso nie było możliwe. System zwrócił następujący komunikat o błędzie: \"%errorText%\"",
    "rent_sync_vamoso_booking_success": "Pomyślna rezerwacja w systemie Vamoso",
    "rent_sync_vamoso_booking_failure": "Dokonanie rezerwacji w systemie Vamoso nie było możliwe. System zwrócił następujący komunikat o błędzie: \"%errorText%\"",
    "rent_sync_vamoso_return_success": "Pojazd został pomyślnie oznaczony jako zwrócony w systemie Vamoso",
    "rent_sync_vamoso_return_failure": "Zwrócenie samochodu do systemu Vamoso nie było możliwe. System zwrócił następujący komunikat o błędzie: \"%errorText%\"",
    "view_lead_details": "Pokaż szczegóły potencjalnego klienta",
    "change_category": "Zmień kategorię",
    "json_url_parameters": "Parametry JSON URL",
    "layout_version": "Wersja układu",
    "layout_wide": "Szeroki",
    "layout_narrow": "Wąski",
    "layout_detailed": "Szczegółowy",
    "layout_slider": "Suwak",
    "layout_list": "Lista",
    "search_version": "Wersja modułu wyszukiwania",
    "not_sure_go_to_advisory_mode": "Nie jestem pewien: tutaj jest {link}.",
    "link_to_advisory_mode": "Tryb doradztwa",
    "desired_pickup_location": "Żądana lokalizacja odbioru",
    "incl_short": "w tym.",
    "included": "W zestawie",
    "included_services": "Usługi wliczone w cenę",
    "insurance": "Ubezpieczenie",
    "liability_and_comprehensive_insurance_with_excess_amount_of_amount_per_claim": "Odpowiedzialność cywilna i pełne ubezpieczenie z franszyzą w wysokości {kwota} na roszczenie",
    "tyres_suitable_for_winter": "Opony zimowe",
    "additional_driver": "Dodatkowy sterownik",
    "on_request": "na żądanie",
    "navigation_system": "System nawigacji",
    "child_seat": "Fotelik dziecięcy",
    "order_comments_and_special_wishes": "Dalsze życzenia",
    "ordering_as_person": "Zamawiam jako osoba prywatna",
    "ordering_as_company": "Zamawiam jako firma",
    "filter_by_date": "Filtruj według daty",
    "car_rental_max_days_count_exceeded": "Niestety, wybrany okres przekracza nasz maksymalny okres wynajmu wynoszący {liczba, liczba mnoga, jeden {# dzień} inne {# dni}}.",
    "require_document_number": "Numer dokumentu zamówienia",
    "require_document_issuing_city": "Żądanie miejsca wydania dokumentu",
    "require_document_issuing_authority": "Żądanie organu wydającego dokument",
    "require_document_issue_date": "Żądana data wydania dokumentu",
    "require_document_expiration_date": "Żądanie daty wygaśnięcia dokumentu",
    "doc_number": "Numer dokumentu",
    "doc_issuing_city": "Miejsce wydania dokumentu",
    "doc_issuing_authority": "Organ wydający dokument",
    "doc_issue_date": "Data wydania dokumentu",
    "doc_expiration_date": "Data ważności dokumentu",
    "chedri_plugins": "Wtyczki Creacheck",
    "in_order_to_use_our_software_in_its_full_potential_you_need_to_activate_plugins_in_order_to_do_that_please_confirm_that_you_may_sign_contracts_on_behalf_of_your_company": "<p>Aby korzystać z naszego oprogramowania, należy aktywować żądane funkcje. Każda funkcja jest dostarczana w formie wtyczki, której zwykle towarzyszy obowiązek zapłaty (opłata).<br/>Podczas aktywacji wtyczki zostaniesz poproszony o zaakceptowanie warunków wtyczki. Zanim jednak będziesz mógł kontynuować, musisz potwierdzić, że jesteś upoważniony do działania w imieniu swojej firmy.</p",
    "i_declare_that_i_am_entitled_to_legally_sign_contracts_on_behalf_of_my_company": "Oświadczam, że jestem upoważniony do podpisywania prawnie wiążących umów w imieniu mojej firmy {firma}.",
    "your_company_was_successfully_registered": "Pomyślnie zarejestrowaliśmy Ciebie i Twoją firmę w naszym systemie",
    "check_email_for_more_instructions_about_your_company_registration": "Na podany adres e-mail wysłaliśmy informacje dotyczące dalszego postępowania. Prosimy o sprawdzenie skrzynki odbiorczej. Jeśli masz jakiekolwiek pytania lub nie wiesz, jak postępować, skontaktuj się z nami.",
    "dependent_on": "W zależności od",
    "we_have_more_plugins_that_further_enhance_the_functionality": "Mamy inne wtyczki, które dodatkowo poprawiają funkcjonalność {plugin}.",
    "finish_plugin_configuration": "Wyjście z konfiguracji wtyczki",
    "it_seems_that_you_havent_finished_configuring_plugins": "Wygląda na to, że konfiguracja aktywowanych wtyczek nie została jeszcze zakończona. Zewnętrzne dane logowania muszą być zdefiniowane dla niektórych wtyczek (np. dla interfejsu mobile.de lub danych logowania Vamoso). Przejdź do ustawień i dodaj brakujące konta.",
    "lead_created_from_an_email": "Ten potencjalny klient został utworzony z wiadomości e-mail",
    "show_original_message": "Pokaż oryginalną wiadomość",
    "we_found_count_similar_leads": "Znaleźliśmy {liczba, liczba mnoga, jeden {# podobnych leadów} inne {# podobnych leadów}}.",
    "show_similiar_leads": "Pokaż podobne leady",
    "new_lead_from_failed_refund": "Nowy potencjalny klient z powodu nieudanego zwrotu",
    "you_can_apply_company_data_from_company_profile": "Dane firmy można przenieść z profilu firmy",
    "load_company_data": "Załaduj dane firmy",
    "company_lookup": "Wyszukiwanie firm",
    "match_lead_tags": "Synchronizacja znaczników potencjalnych klientów",
    "is_active": "Jest aktywny",
    "is_renew_password_at_next_login": "Odnów hasło przy następnym logowaniu",
    "no_overdue_tasks": "Nie ma żadnych zaległych zadań",
    "great_job": "Dobra robota!",
    "no_leads_for_today": "Brak potencjalnych klientów na dziś",
    "address_not_set": "Adres nie został ustawiony",
    "statistics": "Statystyki",
    "select_assignee": "Wybór edytora",
    "kroschke_order_in_progress": "Zamówienie Kroschke z identyfikatorem pojazdu %kroschkeVehicleId% zostało pomyślnie zainicjowane.",
    "in_original": "W oryginale",
    "a_copy": "Jeden egzemplarz",
    "mark_documents_sent": "Oznaczanie dokumentów jako wysłanych",
    "i_sent_the_documents": "Wysłałem dokumenty",
    "documents_were_sent": "Dokumenty zostały wysłane",
    "documents_sent": "Wysłane dokumenty",
    "define_custom_license_plate_number": "Zdefiniuj preferowaną tablicę rejestracyjną",
    "about_car_registration": "Usługa autoryzacji obejmuje",
    "i_would_like_individual_license_plate_number": "Tak, chcę wybrać wybraną postać",
    "we_gather_necessary_documents": "Rejestracja w organie wydającym licencje",
    "we_produce_license_plates": "Zakup i montaż tablic rejestracyjnych",
    "license_plate_reservation_number": "Numer rezerwacji tablicy rejestracyjnej",
    "with_custom_license_plate_number": "Ze spersonalizowaną tablicą rejestracyjną",
    "after_the_order_reserve_desired_license_plate_at_local_registration_office": "Po złożeniu zamówienia należy zarezerwować wybraną tablicę rejestracyjną online w lokalnym urzędzie rejestracyjnym.",
    "inform_us_about_your_license_plate_reservation_so_we_can_start_the_process": "Gdy to zrobisz, poinformuj nas o wybranym numerze rejestracyjnym i numerze rezerwacji (który otrzymałeś w urzędzie rejestracyjnym).",
    "clarify": "Wyjaśnienie",
    "notification": "Komunikacja",
    "license_plate_reservation_data_missing": "Brakuje danych rezerwacji dla tablicy rejestracyjnej",
    "license_plate_reservation_number_filled_out": "Wprowadzono dane rezerwacji tablic rejestracyjnych. Numer tablicy rejestracyjnej: %licensePlateNumber%. Numer rezerwacji: %reservationNumber%.",
    "distance_planned": "Planowana trasa",
    "distance_driven": "Przejechana trasa",
    "planned_pickup_date": "Planowana data odbioru",
    "pickup_date": "Data odbioru",
    "planned_delivery_date": "Planowana dostawa",
    "your_initial_offer": "Twoja pierwsza oferta",
    "your_next_offer": "Twoja następna oferta",
    "car_number": "Numer pojazdu",
    "send_price_proposal": "Wyślij propozycję ceny",
    "our_price": "Nasza cena",
    "purchase_price": "Cena zakupu",
    "our_prices_are_already_optimized_but_if_you_think_price_is_too_high_negotiate_it": "Staramy się zoptymalizować nasze ceny dla Ciebie, ale jeśli uważasz, że cena jest nadal zbyt wysoka, daj nam znać, co możemy dla Ciebie zrobić.",
    "select_the_car_you_love": "Wybierz żądany samochód",
    "sign_up_or_log_in_if_you_already_have_an_account": "Zarejestruj się lub zaloguj, jeśli masz już konto",
    "enter_desired_price": "Wprowadź żądaną cenę",
    "send_the_form_our_employee_will_contact_you": "Wyślij formularz. Nasz pracownik skontaktuje się z Tobą tak szybko, jak to możliwe",
    "if_we_settle_on_a_price_you_can_buy_the_car_online": "Jeśli uzgodnimy cenę, możesz kupić samochód bezpośrednio na naszej stronie internetowej.",
    "you_have_to_log_in_to_proceed": "Musisz się zalogować, aby kontynuować",
    "rent_a_car": "Wynajem pojazdu",
    "pending_price_negotiations_count": "Oczekujące {liczba, liczba mnoga, jedna {# propozycja cenowa} inne {# propozycje cenowe}}",
    "my_price_negotiation": "Moja propozycja ceny",
    "price_negotiation": "Propozycja ceny",
    "price_negotiations": "Propozycje cenowe",
    "new_price_negotiation_request": "Nowe zapytanie o negocjację ceny",
    "expired": "Wygasł",
    "first_offer": "Pierwsza oferta",
    "current_offer": "Aktualna oferta",
    "expires": "Wygasa",
    "accept_the_offer": "Zaakceptuj ofertę",
    "send_a_new_offer": "Wyślij nową ofertę",
    "your_new_offer": "Twoja nowa oferta",
    "your_project_dashboard": "Pulpit nawigacyjny {projektu}",
    "add_my_vehicle": "Dodaj mój pojazd",
    "no_reply_yet": "Brak odpowiedzi",
    "employee_replied": "Pracownik odpowiedział",
    "price_negotiation_details": "Szczegóły propozycji cenowej",
    "current_offer_is_amount_you_can_now_proceed_to_checkout": "Twoja aktualna oferta to {kwota}. Możesz przejść do kasy z wynegocjowaną ceną.",
    "show_timeline": "Pokaż historię",
    "communities": "Społeczności",
    "gain_coverage_with_community": "Zwiększ swój zasięg dzięki nowym platformom. Pozyskuj nowych klientów jednym kliknięciem",
    "community": "Wspólnota",
    "is_community": "Aktywna społeczność",
    "join_community": "Dołącz do społeczności",
    "leave_community": "Opuścić społeczność",
    "join_jahreswagen_community_and_profit_from_additional_sales_channel": "<div><p>Jedną z zupełnie nowych rzeczy w Creacheck są społeczności. Dzięki oprogramowaniu Creacheck możliwe jest tworzenie platform, do których może dołączyć wiele firm.</p><p>Jedną z takich platform jest Jahreswagen.de. Możesz zapoznać się z ofertami Jahreswagen. Jeśli chcesz zostać członkiem, przejdź do ustawień społeczności i dołącz do społeczności Jahreswagen.</p><p>Członkostwo jest bezpłatne. Obowiązują jednak opłaty transakcyjne.</p><p className='m-t-20 alert alert-info'>Jeśli masz własny pomysł na społeczność, skontaktuj się z nami. Znajdziemy dla ciebie rozwiązanie.</p></div>",
    "car_description": "Opis pojazdu",
    "marketplace_terms": "Warunki korzystania z platformy handlowej",
    "online_appointment_booking": "Rezerwacja terminów online",
    "my_online_appointments": "Moje spotkania online",
    "employee": "Pracownicy",
    "choice_of_date": "Wybór daty",
    "works": "Praca",
    "make_selection": "Wybierz",
    "remove_selection": "Usunąć",
    "tasks": "Zadania",
    "task": "Zadanie",
    "car_service": "Usługa",
    "car_services": "usługi",
    "car_service_station": "Stacja robocza",
    "car_service_stations": "Stacje robocze",
    "services_offered_at_your_locations": "Zdefiniuj usługi w swoich lokalizacjach.",
    "service_stations_where_your_employees_work": "Zdefiniuj stacje robocze, aby móc śledzić ich wykorzystanie oraz zobaczyć zajęte i wolne zasoby.",
    "team_members": "Członkowie zespołu",
    "service_details": "Szczegóły usługi",
    "service_location": "Wybrana lokalizacja usługi",
    "service_drop_off_time": "Wybrany czas usługi",
    "car_service_appointments": "Daty warsztatów",
    "car_service_booked_at": "Spotkanie zarezerwowane",
    "show_booking_details": "Pokaż szczegóły rezerwacji",
    "saving_draft": "Wersja robocza została zapisana",
    "draft_saved": "Wersja robocza zapisana",
    "define_custom_page_templates_to_meet_your_cd": "Dostarczamy system Creacheck ze zintegrowanymi szablonami. Tutaj możesz tworzyć własne szablony, aby lepiej dopasować je do swojego projektu korporacyjnego.",
    "edit_description": "Edytuj opis",
    "edit_language": "Edytuj język",
    "create_blank_page": "Utwórz pustą stronę",
    "create_from_template": "Utwórz z szablonu",
    "use_template": "Użyj szablonu",
    "time_left": "Pozostały czas",
    "animated_header": "Animowany nagłówek",
    "header_size": "Rozmiar nagłówka",
    "options": "Opcje",
    "effects": "Efekty",
    "animation": "Animacja",
    "parallax": "Paralaksa",
    "animation_repeat": "Powtarzanie animacji",
    "animation_intensity": "Intensywność animacji",
    "animation_duration": "Czas trwania animacji",
    "animation_delay": "Opóźnienie animacji",
    "animation_initial_opacity": "Początkowa nieprzezroczystość animacji",
    "animation_speed_curve": "Krzywa prędkości animacji",
    "parallax_intensity": "Intensywność paralaksy",
    "animation_direction": "Kierunek paralaksy",
    "parallax_initial_opacity": "Początkowa siła krycia paralaksy",
    "append_html": "Dołącz HTML",
    "css_classes": "Klasy CSS",
    "forward_url": "Przekierowanie na ten adres URL",
    "effect_fade": "Zanikanie",
    "effect_slide": "Slajdy",
    "effect_zoom": "Zoom",
    "effect_bounce": "Odbicie",
    "effect_flip": "Klapka",
    "effect_fold": "Fałdy",
    "effect_roll": "Rolki",
    "direction_center": "Centrum",
    "direction_left": "Linki",
    "direction_right": "Prawo",
    "direction_top": "Powyżej",
    "direction_bottom": "Poniżej",
    "repeat_once": "Raz",
    "repeat_loop": "Pętla",
    "selected_template": "Wybrany szablon",
    "premade_layouts": "Prefabrykowane układy",
    "your_existing_pages": "Istniejące strony",
    "count_templates": "{count} Szablony",
    "background_attachment": "Dodatek wprowadzający",
    "background_position": "Pozycja w tle",
    "background_repeat": "Powtarzanie tła",
    "background_size": "Rozmiar tła",
    "edit_mode": "Tryb edycji",
    "effects_active": "Efekty aktywne",
    "document_templates": "Szablony dokumentów",
    "code_document_templates_for_print_use_the_templates_for_individual_quotes_and_invoices": "Koduj własne szablony dokumentów i drukuj je dla swoich klientów. Możesz także zaprojektować własne wersje ofert i faktur.",
    "currencies": "Waluty",
    "document_template": "Szablon dokumentu",
    "document_content": "Treść dokumentu",
    "number_prefix": "Prefiks numeru",
    "sales_documents": "Dokumenty sprzedaży",
    "theme": "Temat",
    "choose_template": "Wybierz szablon dokumentu",
    "default": "Standard",
    "favorites": "Ulubione",
    "favorites_list": "Lista ulubionych",
    "require_client_address": "Żądanie adresu klienta",
    "require_client_email": "Wymagany adres e-mail klienta",
    "require_client_phone": "Żądanie podania numeru telefonu klienta",
    "require_message": "Żądanie wiadomości",
    "subscription": "Subskrypcja",
    "subscription_plan": "Plan subskrypcji",
    "main_plugin": "Główna wtyczka",
    "target_organisation": "Organizacja docelowa",
    "reference_number": "Numer referencyjny",
    "show_invoice": "Pokaż fakturę",
    "delivery_date": "Data dostawy",
    "test_drive": "Jazda próbna",
    "find_your_perfect_car": "Znajdź idealny pojazd",
    "select_car_design": "Wybierz projekt",
    "multiple_selection_possible": "Możliwość wielokrotnego wyboru",
    "fuel_type": "Paliwo",
    "select_equipment_level": "Wybór poziomu wyposażenia",
    "engine": "Motoryzacja",
    "like_budget": "Jak budżet",
    "like_family": "Jak rodzina",
    "like_comfort": "Jak komfort",
    "we_did_not_find_any_results_for_your_search": "Przepraszamy, wyszukiwanie nie powiodło się",
    "we_did_not_find_any_cars_at_this_location_for_the_selected_period": "Nie znaleźliśmy żadnych samochodów w tej lokalizacji w wybranym okresie",
    "we_did_not_find_any_cars_for_your_seaech": "Niestety, nie znaleźliśmy żadnych samochodów dla Twojego zapytania",
    "all_cars": "Pokaż wszystkie samochody",
    "search_for_cars": "Wyszukiwanie samochodów",
    "used_car_search": "Wyszukiwanie samochodów używanych",
    "dropdown": "Lista rozwijana",
    "checkboxes": "Pole wyboru",
    "multiple_choice": "Wybór wielokrotny",
    "text_input": "Wprowadzanie tekstu",
    "number_input": "Liczba wejściowa",
    "multiline_input": "Wielowierszowe wprowadzanie tekstu",
    "paragraph": "Sekcja",
    "change_questionnaire": "Kwestionariusz zmian",
    "answers_to_a_questionnaire": "Odpowiedzi na pytania zawarte w kwestionariuszu",
    "request_filling_out_questionnaire": "Wyślij swoim klientom kwestionariusz",
    "send_questionnaire_as_message": "Wyślij kwestionariusz jako wiadomość",
    "ingoing": "Nadchodzące",
    "outgoing": "Wychodzące",
    "vat_short": "VAT.",
    "vat": "Podatek od wartości dodanej",
    "price_net": "{cena} netto",
    "environment": "Środowisko",
    "inquire": "Zapytania",
    "continue_to_product_name": "Przejdź do {productName}",
    "you_can_find_this_and_other_vehicles_at_company": "Ten i inne pojazdy można znaleźć na stronie {company}.",
    "more_vehicles_based_on_your_search": "Więcej pojazdów na podstawie wyszukiwania",
    "add_to_favorites": "Zapisz jako ulubione",
    "remove_from_favorites": "Usuń z ulubionych",
    "share_the_offer": "Udostępnij ofertę",
    "any_question_the_company_team_is_at_your_disposal": "Masz jakieś pytania? Zespół {company} jest do Twojej dyspozycji.",
    "you_want_this_vehicle_but_the_price_isnt_right_submit_price_proposal": "Chcesz ten pojazd, ale cena nie jest odpowiednia? Prześlij swoją propozycję ceny.",
    "you_want_to_rent_this_vehicle_at_project_name_you_can": "Chciałbyś jeździć tym pojazdem przez dłuższy czas, w {projectName} masz możliwość wypożyczenia swojego wymarzonego pojazdu.",
    "uploaded_video": "Prześlij wideo",
    "lead_autoresponse": "Automatyczna odpowiedź leadów",
    "automatically_respond_to_incoming_leads": "Automatycznie odpowiada na przychodzące leady. Zdefiniuj dni tygodnia i godziny, w których autoresponder jest aktywny.",
    "client_gave_count_answers_to_the_name_questionnaire": "Klient udzielił {liczba} odpowiedzi na kwestionariusz \"{nazwa}\".",
    "new_lead_from_comms_center": "Nowy potencjalny klient po zamkniętej komunikacji",
    "customer_chat_ended": "Czat z klientem zakończony",
    "chatter_welcome_message": "Nie ma za co. Dziękujemy za wypróbowanie naszego czatu. Jak mogę ci dzisiaj pomóc?",
    "please_rate_us": "Prosimy o ocenę",
    "reservation_fee": "Opłata za rezerwację",
    "item_name": "Nazwa artykułu",
    "deposit": "Zaliczka",
    "balance_due": "Pozostała kwota do zapłaty",
    "authorize": "Autoryzacja",
    "authorized_by_name_on_date": "Zatwierdzony przez {imię} w dniu {data}",
    "auto_generated": "Generowane automatycznie",
    "not_authorized": "Nieautoryzowane",
    "settings_instructions": "Instrukcje dotyczące ustawień",
    "payment_config": "Konfiguracja płatności",
    "projects": "Projekty",
    "invoice_address": "Adres rozliczeniowy",
    "deliver_adress": "Adres dostawy",
    "your_contract_partner": "Twój partner umowny",
    "remaining_balance": "Pozostała kwota",
    "item_filter_is_active": "Widoczny online",
    "item_filter_is_resource": "Czy zasób",
    "item_filter_is_subscription": "Finansowanie",
    "cancelled_by_name_on_date": "Anulowane przez {imię} w dniu {data}",
    "add_credit_card_data_in_next_step": "W następnym kroku możesz dodać dane karty kredytowej",
    "upon_collection": "Przy odbiorze",
    "leads_manually_set_to_high_priority": "Leady ustawione ręcznie na wysoki priorytet",
    "leads_with_no_reaction_for_2_hours_since_creation": "Leady bez odpowiedzi przez 2 godziny od utworzenia",
    "leads_that_were_not_updated_for_2_days": "Leady, które nie były aktualizowane przez 2 dni",
    "use_this_element_as_reference": "Użyj tego elementu jako odniesienia",
    "you_can_reuse_this_element_on_multiple_pages": "Można odwoływać się do treści, która jest ponownie wykorzystywana na wielu stronach internetowych. Elementy Express Page Builder nie mogą być definiowane jako odniesienia. Takie elementy są zawsze kopiowane",
    "company_data": "Dane firmy",
    "company_singup_company_data_hint": "Potrzebujemy następujących informacji, abyśmy mogli utworzyć Twoją firmę i przypisać Ci domenę.",
    "company_singup_personal_data_hint": "<p>Aby otrzymać dostęp z własnymi danymi użytkownika, potrzebujemy następujących informacji.<br/>Następnie otrzymasz wiadomość e-mail z osobistymi danymi dostępu.</p>",
    "your_test_domaine": "Nazwa domeny testowej",
    "your_test_domaine_hint": "<p>Proszę utworzyć nazwę domeny, w której będzie hostowany Twój projekt. Domena będzie dostępna natychmiast po rejestracji.<br/>Pamiętaj, że możemy również hostować projekty pod nazwą domeny określoną przez Ciebie. Wystarczy skontaktować się z nami później.</p>",
    "features": "Cechy",
    "forms": "Formularze",
    "pricings": "Ceny",
    "testimonials": "Referencje",
    "show_your_page_structure_and_responsive_visibility": "Wyświetla strukturę strony z etykietami i responsywną widocznością.",
    "toggle_edit_mode_for_page_preview": "Przełączanie trybu edycji dla podglądu strony",
    "toggle_animation_and_parallax_effects": "Aktywacja i dezaktywacja animacji i efektów paralaksy",
    "use_quick_page_builder_to_create_content_faster": "Użyj Quick Page Builder, aby tworzyć treści znacznie szybciej",
    "cancel_invoice": "Anuluj fakturę",
    "refunded": "Zwrócone",
    "invoice_mark_refunded": "Możliwość zwrotu kosztów faktury",
    "denied_to_pay": "Odmowa zapłaty",
    "invoice_mark_denied_to_pay": "Nie zapłaci",
    "registration_success": "Pomyślnie zarejestrowany",
    "no_images_yet": "Nie ma jeszcze zdjęć.",
    "sortingPrice": "Cena sortowania",
    "main_menu_version": "Wersja menu głównego",
    "main_menu_versions": "Wersje menu głównego",
    "administration_of_main_menu_versions": "Zarządzanie wersjami menu głównego",
    "featured": "Wyróżnione",
    "list_element_class": "Element listy (po lewej) Klasa CSS",
    "link_class": "Klasa CSS łącza",
    "width": "Szerokość",
    "primary_menu_elements": "Elementy menu głównego",
    "secondary_menu_elements": "Elementy menu dodatkowego",
    "has_secondary_elements": "Posiada elementy drugorzędne",
    "active": "aktywny",
    "company_singup_wizard_step_subscription_plan": "Wybierz pakiety",
    "company_singup_wizard_step_subscription_plan_headline": "Wybierz pakiet, który chcesz przetestować.",
    "company_singup_wizard_step_additional_plugins": "Dodatkowe pakiety",
    "company_singup_wizard_step_subscription_plan_main_plugin_headline": "Chciałbym przetestować następujące funkcje:",
    "company_singup_wizard_step_company_data": "Dane kontaktowe firmy",
    "company_singup_wizard_step_company_data_headline": "Nazwa i dane kontaktowe firmy",
    "company_singup_wizard_step_personal_data": "Profil pracownika",
    "company_singup_wizard_step_personal_data_headline": "Profil pracownika",
    "company_singup_wizard_step_personal_data_hint": "Aby móc utworzyć dla Ciebie profil pracownika, potrzebujemy Twoich danych osobowych. To konto daje ci prawa administracyjne do twojego projektu %platformName%.",
    "company_singup_wizard_step_personal_data_domain_hint": "<p>Utwórz nazwę domeny, w której będzie hostowany Twój projekt. Domena będzie dostępna natychmiast po rejestracji.<br/>Pamiętaj, że możemy również hostować projekty pod wskazaną przez Ciebie nazwą domeny. Wystarczy skontaktować się z nami później.</p>",
    "company_singup_wizard_free_trial_hint": "Testuj nasze oferty przez miesiąc bezpłatnie i bez zobowiązań.",
    "company_signup_wizard_free_trial_plugin_hint": "W takim razie przetestuj jeden miesiąc za darmo:",
    "included_in_subscription_plan": "Wliczone w subskrypcję, w przeciwnym razie",
    "mostly_asked": "Najczęściej zadawane pytania",
    "test_package": "Pakiet testowy",
    "active_since": "aktywny od",
    "test_period_until": "Faza testowa do",
    "navigate_to_plugins_and_configure": "Do przeglądu wtyczek i ustawień",
    "termination_date": "Data zakończenia",
    "lead_taggers": "Lead Taggers",
    "lead_tagger": "Główny tagger",
    "share_with": "Podziel się z",
    "password_weak": "Słaby",
    "password_medium": "Średni",
    "password_strong": "Silny",
    "plugin_already_added_in_whitelabel_project": "Wtyczka jest już przypisana do projektu white label.",
    "lead_channel": "Kanał",
    "lead_channel_web": "Sieć",
    "lead_channel_mobile": "Aplikacja",
    "lead_channel_phone": "Telefon",
    "lead_channel_email": "E-mail",
    "lead_channel_personally": "Osobiste",
    "allow_others_to_share": "Umożliwienie innym dzielenia się z większą liczbą osób",
    "additional_imprint_information": "Dodatkowe informacje o nadruku",
    "share_with_the_company_i_work_for": "W firmie, dla której pracuję, dzielę się",
    "share_with_all_companies_in_my_holding_structure": "Ze wszystkimi spółkami w mojej strukturze holdingowej, akcje",
    "latest_lead": "Najnowsza przewaga",
    "latest_edit": "Ostatnia edycja",
    "reaction_time": "Czas reakcji",
    "no_reaction_time": "Brak reakcji",
    "gt_2_days": "> 2 dni",
    "lt_5_minutes": "< 5 minut",
    "lt_1_hour": "< 1 godzina",
    "lt_2_hours": "< 2 godziny",
    "lt_6_hours": "< 6 godzin",
    "lt_1_day": "< 1 dzień",
    "lt_2_days": "< 2 dni",
    "by_employee": "Przez pracownika",
    "by_company": "Przez firmę",
    "by_location": "Według lokalizacji",
    "by_source": "Według źródła",
    "client_data": "Dane klienta",
    "show_in_lead_description": "Pokaż w głównym opisie",
    "this_answer_will_be_shown_in_lead_description": "Ta odpowiedź jest wyświetlana w opisie leadu",
    "this_answer_will_not_be_shown_in_lead_description": "Ta odpowiedź nie jest wyświetlana w opisie potencjalnego klienta",
    "lead_assignment_strategy": "Strategia przydzielania leadów",
    "lead_assignment_strategies": "Strategie przydzielania leadów",
    "change_the_lead_assignment_strategies_for_companies_and_pipelines": "Zmień sposób przydzielania leadów pracownikom na podstawie ich organizacji i pipeline'ów.",
    "strategy": "Strategia",
    "deleted": "Usunięto",
    "assign_to_me": "Przypisz do mnie",
    "responsibilities": "Obowiązki",
    "escalation_rules": "Zasady eskalacji",
    "escalation_rule": "Zasada eskalacji",
    "define_rules_when_you_would_like_to_inform_users_about_overdue_leads": "Definiuje reguły, których zespoły mogą używać do identyfikowania potencjalnych klientów, którzy zostali porzuceni lub na których członkowie zespołu nie odpowiadają.",
    "category_images": "Obrazy kategorii",
    "use_document_template": "Użyj szablonu dokumentu",
    "escalation_level_one": "Etap pierwszy - poinformowanie pracowników",
    "escalation_level_two": "Krok drugi - poinformowanie lidera zespołu",
    "automatically_terminate_the_lead": "Automatycznie zakończ przewód (oznacz przewód jako utracony)",
    "no_reaction": "Brak reakcji",
    "no_reaction_for": "Brak reakcji na",
    "no_update": "Brak aktualizacji",
    "no_update_for": "Brak aktualizacji dla",
    "escalation_level": "Poziom eskalacji",
    "time_settings": "Ustawienia czasu",
    "pending": "Wyróżnia się",
    "skipped": "Pominięto",
    "holding_structure": "Struktura holdingowa",
    "show_sibling_companies": "Firmy rodzeństwa pokazują",
    "show_descendant_companies": "Pokaż podległe firmy",
    "subscription_type": "Model finansowania",
    "no_subscription_type_selected": "Należy wybrać co najmniej jeden model finansowania.",
    "financing": "Finansowanie",
    "monthly_payment": "Rata miesięczna",
    "final_payment": "Ostatnia rata",
    "interest_rate": "Stopa procentowa",
    "availability": "Dostępność",
    "delivery_time_month": "Czas dostawy (w miesiącach)",
    "delivery_time": "Czas dostawy",
    "cancelation_period_month": "Okres anulowania (w miesiącach)",
    "enter_financing_data": "Wprowadź dane dotyczące finansowania",
    "marketing_agency": "Agencja marketingowa",
    "edit_company_read_and_write_rights": "Edycja uprawnień firmy do odczytu i zapisu",
    "grant_data_access": "Udzielanie dostępu do danych",
    "deny_data_access": "Odmowa dostępu do danych",
    "allow_data_access_for_a_single_company": "Umożliwienie dostępu do danych dla pojedynczej firmy",
    "allow_data_access_for_a_whole_level": "Umożliwienie dostępu do danych dla całego poziomu",
    "deny_data_access_for_a_single_company": "Odmowa dostępu do danych dla pojedynczej firmy",
    "deny_data_access_for_a_whole_level": "Odmowa dostępu do danych dla całego poziomu",
    "closing_costs": "Opłata za zamknięcie",
    "nominal_interest_rate": "Docelowa stopa procentowa",
    "has_rate_insurance": "Ochrona płatności",
    "is_user_based": "Jest oparty na użytkownikach",
    "min_user_count": "Minimalna liczba użytkowników",
    "max_user_count": "Maksymalna liczba użytkowników",
    "price_per_user": "Cena za użytkownika",
    "monthly": "Miesięcznie",
    "instantly": "Natychmiast",
    "quarterly": "Kwartalnie",
    "annual": "Rocznie",
    "monthly_fee": "Opłata miesięczna",
    "starting_fee": "Wpisowe",
    "included_templates_count": "Liczba dołączonych szablonów",
    "simple_templates_count": "Liczba szablonów w zestawie (pojedynczy)",
    "middle_templates_count": "Liczba uwzględnionych szablonów (średnia)",
    "complex_templates_count": "Liczba dołączonych szablonów (złożonych)",
    "surcharge": "Dopłata",
    "base_surcharge": "Dopłata podstawowa",
    "max_surcharge": "Maksymalna dopłata",
    "dataproof_surcharge": "Dopłata za Dataproof",
    "aws_domain_hosting": "Hosting domen przez AWS",
    "domain_hosting": "Hosting strony internetowej wraz z domeną",
    "desired_domain": "Pożądana domena",
    "redirect_uri": "Przekazywanie",
    "check_availability": "Sprawdź dostępność",
    "cart_edition_title": "Oznaczenie:",
    "cart_recent_edition": "Ostatnio edytowane:",
    "cart_campaign_title": "Kampania:",
    "cart_last_saved": "Ostatnio zapisane:",
    "remove_item": "Usuń produkt",
    "config": "Konfiguracja",
    "submit_config": "Potwierdź konfigurację",
    "complete_order": "Finalizacja zamówienia",
    "payment_method_description": "Wybierz żądaną metodę płatności",
    "cart_hint": "Uwaga:",
    "cart_hint_description_part_1": "W tym miejscu należy wprowadzać tylko ogólne komentarze. Komentarze dotyczące produktów mogą być brane pod uwagę tylko po wybraniu opcji",
    "cart_hint_description_part_2": "Ręczne sprawdzanie danych",
    "cart_hint_description_part_3": "i wprowadzili komentarz dotyczący danego produktu.",
    "your_message": "Twoja wiadomość do nas",
    "hint_dataproof_1": "Zrezygnowałeś z ręcznego sprawdzenia danych przez naszego eksperta dla jednego lub więcej elementów.",
    "hint_dataproof_2": "Zwracamy uwagę, że w takim przypadku użytkownik ponosi pełną odpowiedzialność za własny projekt. W przypadku błędów w danych, nieprawidłowych kolorów na zdjęciach itp. reklamacja jest wykluczona. Obowiązują nasze ogólne warunki handlowe.",
    "hint_dataproof_3": "Jeśli zdecydujesz się na ręczną kontrolę danych, Twój plik zostanie sprawdzony przez eksperta pod kątem błędów (kontrola techniczna, bez pisowni). W przypadku przeoczenia błędu bierzemy na siebie odpowiedzialność. Nie ponosisz żadnego ryzyka!",
    "hint_missing_order_quantity": "Brak ilości zamówienia!",
    "missing_order_quantity": "Nie wybrano ilości zamówienia dla jednej lub więcej pozycji.",
    "missing_invoice_shipping_address": "Brakuje adresu rozliczeniowego lub adresu dostawy. Wybierz adres.",
    "billing_address_form_is_not_submitted": "Adres rozliczeniowy nie został podany. Prosimy o wypełnienie formularza.",
    "shipping_address_form_is_not_submitted": "Adres dostawy nie został podany. Prosimy o wypełnienie formularza.",
    "no_invoice_address": "Nie utworzono adresu rozliczeniowego",
    "no_delivery_address": "Nie utworzono adresu dostawy",
    "use_invoice_contact": "Użyj kontaktu rozliczeniowego",
    "order_item": "Artykuł",
    "order_price": "Cena",
    "order_copies": "Wydanie",
    "order_totals": "Suma zamówień",
    "subtotal": "Suma częściowa",
    "invoice_amount": "Kwota faktury",
    "remainder_available": "Nierozdzielona pozostała dostępna ilość",
    "reduce_edition": "Aby dodać nowy adres, należy najpierw zmniejszyć liczbę kopii istniejącego adresu.",
    "split_delivery_item_hint": "Dystrybucja całkowitej ilości nie została jeszcze sfinalizowana. Specyfikacja ilości musi być wartością pomiędzy 1 a pozostałą ilością do dystrybucji lub nakładem; obecnie maksymalna wartość wynosi",
    "net_amount": "Kwota netto",
    "gross_amount": "Kwota brutto",
    "is_debtor": "Wierzyciel",
    "is_debtor_number_provider": "Numer dłużnika Dostawca",
    "is_never_remind": "Nie wysyłaj przypomnień",
    "is_demo": "Tryb demonstracyjny",
    "is_vendor": "Dostawca",
    "should_user_pay": "Użytkownik powinien zapłacić",
    "receive_payment_from_users": "Otrzymywanie płatności od użytkowników",
    "use_payment_receiver": "Użyj opcji odbiorcy płatności",
    "payment_receiver": "Odbiorca płatności",
    "use_summary_invoice": "Użyj faktury zbiorczej",
    "credit_limit": "Limit kredytowy",
    "commission_receiver": "Odbiorca Komisji",
    "budget_value": "Wartość budżetu",
    "billing_value": "Wartość rozliczenia",
    "coupon_billing": "Wartość rozliczeniowa dla wybranego okresu rozliczeniowego",
    "coupon_billing_period": "Okres rozliczeniowy",
    "coupon_recharge_period": "Kupon doładowujący",
    "coupon_print_type": "Ograniczenie kuponu",
    "coupon_never": "Nigdy",
    "coupon_unset": "Brak ograniczeń",
    "coupon_print_pdf": "Tylko wydruk PDF",
    "recurring": "Powtarzające się",
    "period": "Okres",
    "without_end_date": "Bez daty zakończenia",
    "budget_settings": "Ustawienia budżetu",
    "no_budget_set": "Brak ustalonego budżetu",
    "current_period_budget_usage": "Wykorzystanie budżetu w bieżącym okresie",
    "user_entered_no_message": "Użytkownik nie wprowadził komunikatu",
    "coupons": "Bony",
    "coupon": "Voucher",
    "create_discounts_and_subsidy_products": "Tworzenie kodów rabatowych i subsydiowanie produktów dla partnerów",
    "authorization_status": "Status autoryzacji",
    "user_budget": "Budżet użytkownika",
    "manual_vat_check": "Kontrola obniżonej stawki VAT",
    "manual_data_check": "Ręczne sprawdzanie danych",
    "completed": "Zakończono",
    "changes_requested": "Wymagane zmiany",
    "rejected": "Odrzucono",
    "request_changes": "Żądanie zmian",
    "reply": "Odpowiedzi",
    "coupon_monetary": "Stały rabat",
    "coupon_percentage": "Zniżka procentowa",
    "calculations": "Obliczenia",
    "percent_discount_value": "Zniżka procentowa",
    "monetary_discount_value": "Rabat gotówkowy",
    "company_budget": "Budżet firmy",
    "enter_0_to_ignore_this_setting": "Wprowadź 0, aby zignorować to ustawienie",
    "min_order_value": "Minimalna wartość zamówienia",
    "max_order_discount": "Maksymalny rabat na zamówienie",
    "coupon_budget_exceeded": "Przekroczenie budżetu kuponu",
    "billing_target": "Cel rozliczeniowy",
    "billing_settings": "Ustawienia rozliczeń",
    "dataproof_textarea": "Czy musimy się nad czymś zastanawiać?",
    "edit": "Edytuj",
    "edit_client": "Edycja danych klienta",
    "edit_gallery": "Edytuj szczegóły galerii",
    "edit_job": "Edytuj szczegóły zadania",
    "edit_position": "Edytuj pozycję",
    "edit_product": "Edytuj produkt",
    "edit_producttemplate": "Edytuj szablon",
    "edit_question": "Edytuj pytanie",
    "edit_template": "Edytuj szablon",
    "edit_step": "Edytuj etap potoku",
    "edit_payment": "Edycja płatności",
    "edit_team": "Edytuj zespół",
    "edit_document_type": "Edytuj typ dokumentu",
    "edit_brand": "Edytuj markę",
    "edit_model": "Edytuj model",
    "edit_category": "Edytuj kategorię",
    "edit_attribute": "Atrybut edycji",
    "edit_data_push": "Edycja danych push",
    "edit_lead_catcher": "Edytuj głównego łapacza",
    "edit_external_account": "Edycja konta zewnętrznego",
    "edit_comment": "Edytuj komentarz",
    "edit_delivery_date": "Edytuj datę dostawy",
    "edit_lead_tagger": "Edytuj głównego taggera",
    "edit_commission_receiver": "Edytuj odbiorcę prowizji",
    "edit_payment_receiver": "Edytuj odbiorcę płatności",
    "edit_billing_target": "Edytuj cel rozliczeniowy",
    "create_client": "Utwórz nową firmę",
    "create_gallery": "Utwórz nową galerię",
    "create_job": "Utwórz nowe zadanie",
    "create_product": "Tworzenie nowego produktu",
    "create_producttemplate": "Utwórz nowy szablon",
    "create_questionnaire": "Utwórz nowy kwestionariusz",
    "create_question": "Utwórz nowe pytanie",
    "create_template": "Utwórz nowy szablon wiadomości e-mail",
    "create_cms_template": "Utwórz nowy szablon CMS",
    "create_pipeline": "Utwórz nowy potok",
    "create_step": "Utwórz nowy etap potoku",
    "create_payment_schedule": "Utwórz nowy plan płatności",
    "create_payment": "Utwórz nową płatność",
    "create_message": "Napisz wiadomość",
    "create_text_message": "Napisz wiadomość tekstową (SMS)",
    "create_note": "Napisz notatkę",
    "create_user": "Tworzenie nowego użytkownika",
    "create_users": "Utwórz użytkownika",
    "create_doc_type": "Utwórz nowy typ dokumentu",
    "create_team": "Utwórz zespół",
    "create_brand": "Tworzenie marki",
    "create_model": "Utwórz model",
    "create_category": "Utwórz kategorię",
    "create_attribute": "Utwórz atrybut",
    "create_attribute_group": "Tworzenie grupy atrybutów",
    "create_car": "Tworzenie pojazdu",
    "create_item": "Tworzenie produktu",
    "create_lead": "Tworzenie przewagi",
    "assign_lead": "Przypisywanie lidera",
    "create_page": "Utwórz stronę",
    "create_data_push": "Tworzenie nowych danych push",
    "create_white_label_project": "Utwórz nowy projekt white label",
    "create_cms_element": "Utwórz nowy element CMS",
    "create_lead_catcher": "Utwórz nowego lead catchera",
    "create_white_label_plugin": "Utwórz nową wtyczkę white label",
    "create_dynamic_data_definition": "Utwórz nową dynamiczną definicję danych",
    "add_account": "Dodaj konto",
    "create_external_account": "Dodaj nowe konto zewnętrzne",
    "create_model_group": "Utwórz nową grupę modeli",
    "create_model_asset": "Utwórz nowy obraz modelu",
    "create_lead_per_mail_rule": "Utwórz nowego potencjalnego klienta za pomocą reguły e-mail",
    "duplicate_page": "Zduplikowana strona",
    "duplicate_cms_element": "Zduplikowany element CMS",
    "duplicate_cms_template": "Zduplikowany szablon CMS",
    "create_task": "Utwórz zadanie",
    "create_car_service": "Utwórz usługę",
    "create_car_service_station": "Tworzenie stacji roboczej",
    "create_doc_template": "Utwórz nowy szablon dokumentu",
    "create_lead_autoresponse": "Utwórz nową automatyczną odpowiedź leada",
    "create_main_menu_version": "Utwórz wersję menu głównego",
    "create_lead_tagger": "Utwórz główny znacznik",
    "create_lead_assignment_strategy": "Tworzenie strategii przydzielania leadów",
    "create_escalation_rule": "Tworzenie reguły eskalacji",
    "create_coupon": "Utwórz kupon",
    "client_data_saved": "Przechowywane dane klientów",
    "user_data_saved": "Zapisane dane użytkownika",
    "job_data_saved": "Zapisane dane zadania",
    "product_data_saved": "Zapisane dane produktu",
    "template_data_saved": "Zapisane dane szablonu",
    "company_logo_saved": "Logo zostało zapisane",
    "payment_schedule_data_saved": "Plan płatności został zapisany",
    "questionnaire_filled_out": "Wypełniony kwestionariusz",
    "your_message_has_been_sent": "Wiadomość została wysłana",
    "email_with_password_reset_instructions_sent": "Wiadomość e-mail z instrukcjami resetowania hasła została wysłana",
    "password_changed_after_reset": "Twoje hasło zostało zmienione. Witamy ponownie",
    "quote_accepted_saved": "Oferta została przyjęta",
    "quote_rejected_saved": "Oferta została odrzucona",
    "quote_saved": "Zapisana oferta",
    "message_sent": "Wiadomość została wysłana",
    "note_saved": "Uwaga została zapisana",
    "holding_info_saved": "Przechowywanie informacji",
    "new_password_set": "Ustawiono nowe hasło",
    "questionnaire_data_saved": "Zapisany kwestionariusz",
    "doc_type_saved": "Zapisany typ dokumentu",
    "team_saved": "Zespół uratowany",
    "document_saved": "Zapisany dokument",
    "brand_saved": "Zachowana marka",
    "model_saved": "Model zapisany",
    "category_saved": "Kategoria zapisana",
    "attribute_saved": "Zapisany atrybut",
    "item_data_saved": "Zapisane dane produktu",
    "car_data_saved": "Przechowywane dane pojazdu",
    "variant_saved": "Wariant zapisany",
    "pipeline_saved": "Zachowany rurociąg",
    "comment_saved": "Komentarz zapisany",
    "lead_saved": "Zachowany ołów",
    "order_saved": "Zapisane zamówienie",
    "document_reviewed": "Przegląd dokumentu",
    "locations_saved": "Zapisane lokalizacje",
    "email_confirmed": "E-mail potwierdzony",
    "page_saved": "Strona zapisana",
    "template_saved": "Zapisany szablon",
    "data_push_saved": "Zapisywanie danych push",
    "white_label_saved": "Zapisany projekt białej etykiety",
    "attribute_group_saved": "Zapisana grupa atrybutów",
    "cms_element_saved": "Zapisany element CMS",
    "lead_catcher_saved": "Główny łapacz uratowany",
    "image_data_saved": "Zapisane dane obrazu",
    "white_label_plugin_saved": "Zapisana wtyczka białej etykiety",
    "dynamic_data_definition_saved": "Zapisana dynamiczna definicja danych",
    "company_data_saved": "Przechowywane dane firmy",
    "contact_data_saved": "Zapisane dane kontaktowe",
    "no_changes_made": "Nie wprowadzono żadnych zmian",
    "car_order_approved": "Autoryzowana sprzedaż samochodów",
    "vin_number_updated": "Numer podwozia zaktualizowanego pojazdu",
    "ein_number_updated": "Zaktualizowano elektroniczne potwierdzenie ubezpieczenia",
    "order_marked_as_paid": "Zamówienie oznaczone jako opłacone",
    "payment_instructions_sent": "Wysłano instrukcje dotyczące płatności",
    "order_cancelled": "Zamówienie anulowane",
    "sales_contract_uploaded": "Przesłana umowa sprzedaży",
    "bank_data_updated": "Aktualizacja danych bankowych",
    "pickup_invite_sent": "Zaproszenie do odbioru wysłane",
    "order_picked_up": "Zamówienie odebrane",
    "order_assignee_kroschke_documents_sent": "Dealer samochodowy przesłał dokumenty wymagane do rejestracji pojazdu",
    "order_client_kroschke_documents_sent": "Klient przesłał dokumenty wymagane do rejestracji pojazdu",
    "plugin_partial_activated": "Wtyczka częściowo aktywowana",
    "plugin_request_activate_sent": "Wysłano żądanie aktywacji wtyczki",
    "plugin_activated": "Wtyczka aktywowana",
    "plugin_terminated": "Wtyczka zakończona",
    "changes_saved": "Zapisane zmiany",
    "app_secret_saved": "Zapisany sekret aplikacji",
    "page_access_token_saved": "Zapisany token dostępu do strony",
    "text_message_sent": "Wysłana wiadomość SMS",
    "account_saved": "Zapisane konto",
    "model_group_saved": "Zapisana grupa modeli",
    "model_asset_saved": "Zapisany obraz modelu",
    "sepa_agreement_saved": "Uratowany mandat SEPA",
    "document_uploaded": "Dokument został przesłany",
    "customer_sent_message": "Klient wysłał wiadomość",
    "lead_created": "Lead stworzony",
    "lead_watching": "Zaczęło się od obserwacji przewodów",
    "lead_unwatching": "Kończy monitorowanie potencjalnych klientów",
    "lead_per_mail_rule_saved": "Lead zapisany przez regułę e-mail",
    "drivers_license_uploaded": "Wgrane prawo jazdy",
    "plugin_saved": "Zapisane wtyczki",
    "settings_saved": "Zapisane ustawienia",
    "page_duplicated": "Strona zduplikowana",
    "cms_template_duplicated": "Zduplikowany szablon CMS",
    "cms_element_duplicated": "Zduplikowany element CMS",
    "price_negotiation_started": "Rozpoczęły się negocjacje cenowe",
    "new_price_negotiation_message_offer_sent": "Wysłano nową ofertę negocjacji ceny",
    "you_joined_community": "Dołączyłeś do społeczności",
    "you_left_community": "Opuściłeś społeczność",
    "car_service_booked": "Spotkanie zarezerwowane online",
    "car_service_saved": "Zapisana usługa",
    "car_service_station_saved": "Zapisana stacja robocza",
    "team_member_added": "Dodano członka zespołu",
    "team_member_removed": "Usunięty członek zespołu",
    "doc_template_saved": "Zapisany szablon dokumentu",
    "invoice_issued": "Wystawiona faktura",
    "request_to_fill_out_questionnaire_saved": "Prośba o wypełnienie zapisanego kwestionariusza",
    "request_to_fill_out_questionnaire_name_saved": "Zapisana prośba o wypełnienie kwestionariusza \"{imię}\"",
    "lead_autoresponse_saved": "Zapisana automatyczna odpowiedź leada",
    "main_menu_version_saved": "Zapisana wersja menu głównego",
    "lead_tagger_saved": "Lead Tagger zapisany",
    "share_settings_updated": "Zaktualizowano ustawienia wydania",
    "lead_assignment_strategy_saved": "Zapisana strategia alokacji ołowiu",
    "escalation_rule_saved": "Zapisana reguła eskalacji",
    "value_type": "Typ",
    "value_precision": "Dokładność",
    "category_already_in_attribute": "Kategoria jest już przypisana do atrybutu.",
    "no_attributes_for_variants": "Aby utworzyć warianty, należy zdefiniować atrybuty.",
    "budget_saved": "Zaoszczędzony budżet",
    "budget_removed": "Budżet usunięty",
    "coupon_saved": "Zapisany kupon",
    "authorization_data_saved": "Przechowywane dane autoryzacyjne",
    "template_data": "Informacje ogólne",
    "template_pages": "Ustawienia strony",
    "template_editor": "Edytor i ustawienia PDF",
    "template_timeline": "Oś czasu",
    "template_blocks": "Bloki zawartości",
    "template_import": "Import",
    "template_export": "Eksport",
    "template_generate": "Generator (TEST)",
    "template_print_data": "Drukuj dane",
    "template_import_select_template": "Wybierz folder szablonu",
    "template_import_select_print_data_document": "Wybierz dokument danych drukowania",
    "template_upload_print_data_title": "Prześlij dane drukowania do katalogu szablonów",
    "template_upload_print_data_actual_file_title": "Przesłane dane drukowania",
    "template_upload_print_data_info": "Dodaje plik do katalogu szablonów S3. Plik ten jest używany dla produktów, gdy są one tworzone i nie są generowane żadne dane drukowania.",
    "template_blocks_template": "Blok HTML",
    "template_category_thumb": "Klasa ikony miniatury (np.: fad fa-circle)",
    "add_block_category": "Nowa kategoria",
    "edit_block_category": "Edytuj kategorię",
    "add_block_variant": "Nowy wariant",
    "edit_block_variant": "Wariant edycji",
    "add_block": "Nowy blok",
    "copy_block": "Kopiuj blok",
    "edit_block": "Blok edycji",
    "edit_takeit": "Przejęcie",
    "edit_import": "Import",
    "edit_import_warning": "Czy na pewno? Spowoduje to nadpisanie wszystkich niezapisanych wpisów!",
    "template_has_content_blocks": "Zmienna zawartość",
    "template_in_test": "Tryb testowy dla zleceń",
    "placeholder": "Symbol zastępczy dla korespondencji seryjnej",
    "select_customer": "Wybór klientów",
    "company_added": "Firma dodała",
    "company_removed": "Firma usunięta",
    "item_added": "Produkt dodany",
    "item_removed": "Produkt usunięty",
    "category_added": "Kategoria dodana",
    "category_removed": "Kategoria usunięta",
    "select_user_set": "Wybór autoryzacji użytkownika",
    "sent_reminders": "Wspomnienia wysłane",
    "number_of_sent_reminders": "Liczba wysłanych przypomnień",
    "set_number": "Numer zestawu",
    "email_to_the_customer_on_save": "Wyślij do klienta wiadomość e-mail po zapisaniu",
    "email_on_save": "E-mail podczas zapisywania",
    "shown_name": "Wyświetlana nazwa",
    "filename_thumbnail": "Nazwa pliku dla miniatury",
    "name_of_imagefile": "Nazwa pliku obrazu, bez katalogu. Przykład: thumb1.jpg",
    "block_position": "Pozycja bloku w liniach siatki, 0 dla góry, czyni blok absolutnym.",
    "defined_raster_space": "Predefiniowana lokalizacja siatki",
    "needed_raster_space": "Wymagane miejsce na siatce",
    "size_group_info_text": "Wszystkie bloki z tą samą SizeGroup można przełączać pod względem rozmiaru w edytorze",
    "size_group": "Grupa wielkości",
    "group_index_info_text": "Wszystkie bloki z tą samą SizeGroup i tym samym rozmiarem bloku mogą być przełączane za pomocą indeksu. Od indeksu 1.",
    "group_index": "Indeks grupy",
    "bottom_info_text": "UWAGA: dolna pozycja ustawia blok absolutnie na dole strony, z odległością ustawioną w PX",
    "bottom_label": "Blok pozycji bezwzględnie na dole",
    "fixed_block_label": "Stały blok",
    "fixed_block_info_text": " Bloku nie można przesunąć",
    "once_per_document_label": "Raz na dokument",
    "once_per_document_info_text": "dozwolone tylko raz w całym dokumencie",
    "no_copies_label": "Blok nie może zostać skopiowany",
    "no_copies_info_text": "Blok nie może zostać skopiowany",
    "no_options_label": "Nie pokazuj żadnych opcji",
    "no_options_info_text": "Po wstawieniu blok nie ma żadnych opcji w edytorze.",
    "once_per_page_label": "Raz na stronę",
    "once_per_page_info_text": "dozwolone tylko raz po jednej stronie",
    "once_per_variant_label": "Raz na wariant",
    "once_per_variant_info_text": "Dozwolone tylko raz na wariant",
    "once_per_category_label": "Raz na kategorię",
    "once_per_category_info_text": "Dozwolona tylko jedna na kategorię",
    "only_whitelabel_admin_can_modify_label": "Tylko administrator Whitelabel",
    "only_whitelabel_admin_can_modify_info_text": "To pole może zostać zmienione wyłącznie przez administratora whitelabel. Powinno być używane, gdy administrator whitelabel tworzy szablon.",
    "restricted_to_page_label": "Dozwolone na stronie",
    "restricted_to_page_info_text": "Dostępne tylko na tej stronie, -1 = dozwolone wszędzie",
    "exclude_pages_label": "Wyklucz strony (np. 1,2,3...)",
    "exclude_pages_info_text": "Niedozwolone na tych stronach, oddziel przecinkiem",
    "only_last_label": "Tylko ostatnia strona",
    "only_last_info_text": "Blok można wstawić tylko na ostatniej stronie.",
    "container_block_label": "Blok kontenera",
    "container_block_info_text": "Ten blok zawiera kontener dla kolejnych bloków",
    "inline_block_label": "Blok wbudowany",
    "inline_block_info_text": "Blok jest traktowany jako display:inline-block",
    "inner_label": "Możliwość umieszczenia w pojemniku blokowym",
    "inner_info_text": "Blok można włożyć do pojemnika na bloki",
    "container_class_label": "Ograniczenie do bloku kontenera",
    "container_class_info_text": "Nazwa klasy kontenera, do którego można wstawić blok.",
    "only_inside_block_label": "Może być umieszczany tylko w pojemnikach blokowych",
    "only_inside_block_info_text": "Blok może być wstawiony tylko do kontenera blokowego",
    "container_space_label": "Przestrzenie siatki w bloku kontenera",
    "container_space_info_text": "Dostępne linie siatki w bloku (ta sama siatka co na stronie!)",
    "container_cols_label": "Kolumny w bloku kontenera",
    "container_cols_info_text": "dostępne linie siatki są mnożone przez tę wartość",
    "website_only": "Tylko dla stron internetowych",
    "navName": "Nazwa dla nawigacji",
    "nav_link_label": "Łącze do nawigacji",
    "nav_link_info_text": "Zakotwiczony link do identyfikatora bloku",
    "nav_options": "Opcje nawigacji",
    "combi_parent_label": "Element łączący (nadrzędny)",
    "combi_parent_info_text": "Element nadrzędny, może być wstawiony w dowolnym momencie",
    "combi_child_label": "Element łączony (dziecko)",
    "combi_child_info_text": "Elementy podrzędne mogą być ustawiane tylko w połączeniu z elementem nadrzędnym",
    "disable_group_label": "Opcje grupowe",
    "disable_group_info_text": "Tutaj można zdefiniować grupy, które wzajemnie się wykluczają, np. P#Group1,Group2 i D#Group2,Group1. Parametr definicji - P = ważny dla strony, D = ważny dla dokumentu.",
    "events": {
      "TimelineMailPasswordReset": "Klient zażądał zmiany hasła",
      "TimelineMailRegistration": "Klient zarejestrował się w Creacheck.com",
      "TimelineMailOauthRegistration": "Klient zarejestrował się w Creacheck.com za pomocą loginu w mediach społecznościowych",
      "TimelineMailInvitation": "Klient został zaproszony do korzystania z Creacheck.com",
      "TimelineMailContactForm": "Klient wysłał wiadomość za pośrednictwem formularza kontaktowego",
      "TimelineMailInvitationAccepted": "Klient zaakceptował zaproszenie",
      "TimelineMailNewInvoice": "Klientowi została wystawiona faktura",
      "TimelineMailNewQuote": "Wysłałeś ofertę do klienta",
      "TimelineMailNewMessage": "Wysłałeś wiadomość do klienta",
      "TimelineQuoteAccepted": "Klient zaakceptował ofertę",
      "TimelineQuoteRejected": "Niestety, klient odrzucił Twoją ofertę",
      "TimelineOrderCreated": "Nowe zamówienie",
      "TimelineMailNewCustomerMessage": "Wiadomość e-mail od klienta",
      "TimelineMonitoringPaymentReceipt": "Monitorowanie płatności przychodzących",
      "TimelineClosingManualDataCheck": "Ręczna kontrola danych zamknięta",
      "TimelineUpdatedByForeignStatus": "Aktualizacja statusu zamówienia",
      "TimelineMailedForeignOrderStatusOrderCanceled": "anulowane przez dostawcę",
      "TimelineMailedForeignOrderStatusInDelivery": "w dostawie",
      "TimelineMailedForeignOrderStatusInProduction": "w produkcji",
      "TimelineMailedForeignOrderStatusInReview": "w konsultacji",
      "TimelineMailedForeignOrderStatusInOrder": "w porządku",
      "TimelineMailedOrderedWebAndDownloadPDF": "Plik PDF z obsługą strony internetowej i możliwością pobrania wysyłany do odbiorców wiadomości e-mail.",
      "TimelineMailedOrderedDownloadPDF": "Pobierz plik PDF wysłany do odbiorców wiadomości e-mail.",
      "TimelineMailedOrderedWebPDF": "Z obsługą sieci Web wysyłane do odbiorców wiadomości e-mail.",
      "TimelineDomainHostingHasBeenAuthorized": "Hosting domeny został autoryzowany",
      "TimelineDomainNotAvailable": "Domena nie jest już dostępna",
      "TimelineDomainHostingDomainValidated": "Domena została zatwierdzona",
      "TimelineExceptionCheckDomainAvailability": "Błąd podczas sprawdzania dostępności domeny {0}. Błąd: {2}",
      "TimelineDomainFailRegistering": "Rejestracja domeny {0} nie powiodła się",
      "TimelineExceptionRegisterDomain": "Rejestracja domeny {0} nie powiodła się z błędem: {2}.",
      "TimelineDomainSuccessfullyRegistered": "Domena {0} została pomyślnie zarejestrowana.",
      "TimelineDomainSuccessfullyRedirected": "Domena została pomyślnie przekierowana",
      "TimelineDomainCreatingCertificate": "Certyfikat dla domeny {0} został utworzony z błędem: {1}",
      "TimelineDomainCreatingDistributionFailed": "Dystrybucja domeny {0} nie powiodła się z błędem: {1}",
      "TimelineDomainCreatingSuccessfullyHosted": "Domena {0} została pomyślnie hostowana",
      "TimelineDomainCreatingFailedHosting": "Domena {0} nie może być hostowana z błędem: {1}.",
      "TimelineDomainHostingCompleted": "Zakończono",
      "TimelineMissingClientDataFirstAndLastName": "Brakujące imię i nazwisko klienta!",
      "TimelineMissingClientDataPhoneNumber": "Brakujący numer telefonu klienta!",
      "TimelineDomainCreatingSuccessfullyFinished": "Domena {0} została pomyślnie ukończona"
    },
    "attributeName": {
      "select": "Pole wyboru",
      "html": "Pole HTML",
      "checkbox": "Pole wyboru",
      "text": "Pole tekstowe",
      "colors": "Wybór kolorów",
      "number": "Pole liczbowe",
      "boolean": "Tak/Nie",
      "date": "data"
    },
    "attributeType": {
      "select": "Można zdefiniować kilka opcji do wyboru. Użytkownik może następnie wybrać jedną z nich",
      "html": "Użytkownik może napisać dłuższy tekst za pomocą Rich Content Editor",
      "checkbox": "Można zdefiniować kilka opcji do wyboru. Użytkownik może następnie wybrać kilka z nich",
      "text": "Użytkownik może wprowadzić prosty tekst",
      "colors": "Użytkownik może wybrać jeden z kolorów zdefiniowanych tutaj",
      "number": "Użytkownik może wprowadzić numer",
      "boolean": "Użytkownik może wybrać pomiędzy tak i nie",
      "date": "Użytkownik może wybrać datę"
    },
    "attributeValueType": {
      "string": "Tekst",
      "number": "Liczba",
      "date": "data"
    },
    "attributePrecision": {
      "year": "Rok",
      "month": "miesiąc",
      "day": "Dzień",
      "time": "Pora dnia"
    },
    "categoryDisplay": {
      "select": "Tak/Nie",
      "number_range": "Zakres liczbowy",
      "date_range": "Zakres dat",
      "list": "Wybór listy"
    },
    "invoice_actions": {
      "tab_title": "Działania na fakturze",
      "refunded": {
        "title": "Zwrócone ręcznie",
        "label": "Oznacz jako zwrócone",
        "info_text": "Ręczne oznaczenie faktury jako już zwróconej bez tworzenia faktury anulującej."
      },
      "denied_to_pay": {
        "title": "Odmowa płatności",
        "label": "Oznacz jako odrzucone",
        "info_text": "Zaznacz, że faktura nigdy nie zostanie opłacona"
      },
      "amount_paid": {
        "title": "Wprowadź zapłaconą kwotę",
        "placeholder": "Wprowadź zapłaconą kwotę",
        "button": "Zapisz zapłaconą kwotę"
      },
      "sent_reminders": {
        "title": "Wysłane przypomnienia",
        "placeholder": "Liczba wysłanych przypomnień",
        "button": "Zapisz numer"
      }
    },
    "mail": {
      "registration": {
        "subject": "Dziękujemy za rejestrację w %company%",
        "subject_robethood": "Portal Robethood Creatives: Rejestracja przebiegła pomyślnie.",
        "explanation": "Dziękujemy za rejestrację. Prosimy o potwierdzenie adresu e-mail w ciągu 24 godzin. Tylko z potwierdzonym adresem e-mail można otrzymać nowe hasło internetowe lub informacje o zmianach.",
        "explanation_first_name_form": "<p>Dziękujemy za rejestrację. Prosimy o potwierdzenie adresu e-mail w ciągu 24 godzin.</p><p>Tylko z potwierdzonym adresem e-mail możesz otrzymać nowe hasło lub informacje o zmianach.</p>",
        "possible_actions": "Po potwierdzeniu adresu e-mail natychmiast uzyskasz dostęp do wszystkich funkcji, które dla Ciebie przygotowaliśmy.",
        "possible_actions_first_name_form": "Po potwierdzeniu adresu e-mail natychmiast uzyskasz dostęp do wszystkich funkcji, które dla Ciebie przygotowaliśmy.",
        "call_to_action": "Potwierdzenie"
      },
      "ordered_web_pdf": {
        "subject": "Internetowy plik PDF do zamawiania %orderId% od %company%",
        "explanation": "Dziękujemy za złożenie zamówienia. Możesz otworzyć i pobrać plik PDF w wersji internetowej, klikając poniższy przycisk.",
        "explanation_first_name_form": "Dziękujemy za złożenie zamówienia. Możesz otworzyć i pobrać plik PDF w wersji internetowej, klikając poniższy przycisk.",
        "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
        "possible_actions_first_name_form": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
        "call_to_action": "Pokaż kolejność",
        "download": "Pobierz plik PDF z dostępem do Internetu"
      },
      "ordered_download_pdf": {
        "subject": "PDF dla zamówienia %orderId% od %company%",
        "explanation": "Dziękujemy za złożenie zamówienia. Plik PDF można otworzyć i pobrać, klikając poniższy przycisk.",
        "explanation_first_name_form": "Dziękujemy za złożenie zamówienia. Plik PDF można otworzyć i pobrać, klikając poniższy przycisk.",
        "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
        "possible_actions_first_name_form": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
        "call_to_action": "Pokaż kolejność",
        "download": "Pobierz PDF"
      },
      "order_vat_data_check_done": {
        "subject": "Twoje zamówienie na ręczne sprawdzenie danych dla zamówienia %orderId% od %company%",
        "explanation": "Dziękujemy za złożenie zamówienia i zlecenie ręcznego sprawdzenia danych.",
        "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
        "call_to_action": "Pokaż status"
      },
      "order_reduced_vat_authorized": {
        "subject": "Twój wniosek o obniżoną stawkę VAT dla zamówienia %orderId% został zatwierdzony.",
        "explanation": "Dziękujemy za złożenie zamówienia. Zatwierdziliśmy zamówienie dla obniżonej stawki VAT.",
        "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
        "call_to_action": "Pokaż status"
      },
      "order_reduced_vat_rejected": {
        "subject": "Twój wniosek o obniżoną stawkę VAT dla zamówienia %orderId% nie został zatwierdzony.",
        "explanation": "Dziękujemy za zamówienie. Sprawdziliśmy zamówienie i stwierdziliśmy, że nie kwalifikuje się ono do obniżonej stawki VAT. Zastosowana zostanie normalna stawka VAT.",
        "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
        "call_to_action": "Pokaż status"
      },
      "chedri_registration": {
        "subject": "Rejestracja w %company%",
        "subject_fiendly": "Rejestracja w %company%",
        "explanation": "<p>Witam %firstName% %lastName%,</p><p>Dziękujemy za rejestrację.</p><p>Pomyślnie utworzyliśmy konto testowe dla Ciebie i Twojej firmy.</p>",
        "possible_actions": "Po potwierdzeniu adresu e-mail można zalogować się przy użyciu <strong>%username%</strong> jako nazwy użytkownika i hasła wybranego podczas rejestracji.",
        "call_to_action": "Potwierdzenie"
      },
      "invitation": {
        "subject": "Zaproszenie od %company%",
        "explanation": "W imieniu %company%, mamy przyjemność zaprosić Cię do naszego internetowego portalu projektowego.",
        "login_data": "Jeśli zaakceptujesz zaproszenie, użyj następujących danych, aby się zalogować: <strong>%username%</strong> jako nazwa użytkownika i <strong>%password%</strong> jako hasło. Pamiętaj, aby zmienić hasło po zalogowaniu.",
        "call_to_action": "Przyjmij zaproszenie",
        "explanation_first_name_form": "W imieniu %company%, mamy przyjemność zaprosić Cię do naszego internetowego portalu projektowego.",
        "login_data_first_name_form": "Jeśli zaakceptujesz zaproszenie, użyj następujących danych, aby się zalogować: <strong>%username%</strong> jako nazwa użytkownika i <strong>%password%</strong> jako hasło. Pamiętaj, aby zmienić hasło po zalogowaniu."
      },
      "new_quote": {
        "subject": "Nowa oferta od %company%",
        "explanation": "%company% wysyła wycenę zamówionej usługi.",
        "possible_actions": "Masz teraz możliwość sprawdzenia i zaakceptowania oferty. Możesz również odrzucić ofertę i zostawić wiadomość z wyjaśnieniem dlaczego.",
        "call_to_action": "Sprawdź ofertę"
      },
      "new_invoice": {
        "subject": "Nowa faktura od %company%",
        "explanation": "%company% prześle fakturę za zamówioną usługę. Fakturę można znaleźć w naszym portalu online. Dołączyliśmy również fakturę do tej wiadomości.",
        "explanation_first_name_form": "%company% prześle fakturę za zamówioną usługę. Fakturę można znaleźć w naszym portalu online. Dołączyliśmy również fakturę do tej wiadomości.",
        "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje faktury.",
        "possible_actions_first_name_form": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje faktury.",
        "call_to_action": "Pokaż fakturę"
      },
      "new_sales_commission": {
        "subject": "Nowa prowizja od %company%.",
        "explanation": "%company% prześle Ci prowizję za usługę, którą zamówiłeś. Prowizję można znaleźć w naszym portalu online. Prowizję załączyliśmy również do tej wiadomości.",
        "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoją prowizję online.",
        "call_to_action": "Pokaż prowizję"
      },
      "new_questionnaire": {
        "subject": "%company% prosi o wypełnienie kwestionariusza",
        "explanation": "<p>Witam %firstName% %lastName%,</p><p>%company% wysyła nowy kwestionariusz.</p>",
        "possible_actions": "Istnieje możliwość przejrzenia i wypełnienia kwestionariusza online.",
        "call_to_action": "Wyświetl kwestionariusz"
      },
      "questionnaire_filled_out": {
        "subject": "Kwestionariusz został właśnie wypełniony",
        "explanation": "<p>Witam %firstName% %lastName%,</p><p>Kwestionariusz wymagany przez %targetFirstName% został właśnie wypełniony.</p>",
        "possible_actions": "Odpowiedzi można wyświetlić na głównej osi czasu.",
        "call_to_action": "Otwarty przewód"
      },
      "contact_form_cms": {
        "subject": "Nowy kontakt od %name%",
        "info": "Powiadomienie o przesłaniu formularza kontaktowego. Prosimy o jego natychmiastowe przetworzenie i zapewnienie, że klient otrzyma najlepsze możliwe wsparcie."
      },
      "contact_form_edition": {
        "subject": "Nowy kontakt",
        "info": "Powiadomienie o przesłaniu formularza kontaktowego. Prosimy o jego natychmiastowe przetworzenie i zapewnienie, że klient otrzyma najlepsze możliwe wsparcie.",
        "form_fields": "Wypełnione pola formularza"
      },
      "password_renew": {
        "subject": "Odnów hasło",
        "explanation": "<p>Witaj %email%,<br><br>Próbowałeś zalogować się do %platformName%. Twoje hasło wygasło. Musisz ustawić nowe hasło, aby móc zalogować się w przyszłości. Kliknij poniższy link, aby wprowadzić nowe hasło.</p>",
        "possible_actions": "Istnieje możliwość ustawienia nowego hasła. Wystarczy kliknąć przycisk.",
        "call_to_action": "Ustaw nowe hasło",
        "explanation_first_name_form": "<p>Witaj %email%,<br><br>Próbowałeś zalogować się do %platformName%. Twoje hasło wygasło. Musisz ustawić nowe hasło, aby móc zalogować się w przyszłości. Kliknij poniższy link, aby wprowadzić nowe hasło.</p>",
        "possible_actions_first_name_form": "Istnieje możliwość ustawienia nowego hasła. Wystarczy kliknąć przycisk."
      },
      "password_reset": {
        "subject": "Resetowanie hasła",
        "explanation": "<p>Dzień dobry %email%,</p><br /><p>zażądano nowego hasła od %platformName% za pośrednictwem naszej platformy. Jeśli złożyłeś tę prośbę, kliknij poniższy link.</p",
        "explanation_first_name_form": "<p>Witaj %email%,</p><br /><p>właśnie zażądano nowego hasła od %platformName% za pośrednictwem naszej platformy. Jeśli złożyłeś tę prośbę, kliknij poniższy link.</p",
        "possible_actions": "Istnieje możliwość ustawienia nowego hasła. Wystarczy kliknąć przycisk.",
        "possible_actions_first_name_form": "Istnieje możliwość ustawienia nowego hasła. Wystarczy kliknąć przycisk.",
        "call_to_action": "Ustaw nowe hasło"
      },
      "message": {
        "explanation": "%company% wysłał do Ciebie wiadomość",
        "possible_actions": "Możesz odpowiedzieć na tę wiadomość, klikając ten przycisk:",
        "call_to_action": "Odpowiedzi"
      },
      "customerMessage": {
        "explanation": "Klient wysłał wiadomość"
      },
      "lead_comment_mention": {
        "subject": "Zostały one wspomniane w %title% lead",
        "explanation": "%creator% wspomniał o tobie w komentarzu.",
        "call_to_action": "Otwarty przewód"
      },
      "lead_assignee_changed": {
        "subject_assigned": "Jesteś teraz przypisany do leada \"%title%\".",
        "subject_unassigned": "Nie są one już przypisane do leadu \"%title%",
        "explanation_assigned": "Jesteś teraz przypisany do leadu \"%title%\". Poprzednim edytorem był %oldAssignee%.",
        "explanation_unassigned": "Nie są już przypisani do leadu \"%title%\". Nowy cesjonariusz to %newAssignee%.",
        "call_to_action": "Otwarty przewód"
      },
      "bank_data_reminder": {
        "subject": "Potrzebujemy danych bankowych do zamówienia",
        "explanation": "Zarezerwowałeś usługi, dla których wymagane są dane bankowe.",
        "possible_actions": "Przejdź do zamówienia i wprowadź brakujące dane.",
        "call_to_action": "Wprowadź dane bankowe"
      },
      "order_approved": {
        "subject": "Twoje zamówienie z %company% zostało właśnie zatwierdzone",
        "explanation": "Z przyjemnością informujemy, że zamówienie %orderId% zostało zatwierdzone.",
        "possible_actions": "Możesz przejść do swojego zamówienia, aby wyświetlić szczegóły.",
        "call_to_action": "Pokaż kolejność"
      },
      "sales_contract_uploaded": {
        "subject": "Umowa zakupu zamówienia jest już dostępna"
      },
      "order_cancelled": {
        "subject": "Twoje zamówienie z %company% zostało anulowane",
        "explanation": "Przepraszamy, ale musieliśmy anulować zamówienie %orderId%.",
        "possible_actions": "Przejdź do zamówienia, aby wyświetlić szczegóły.",
        "call_to_action": "Pokaż kolejność"
      },
      "payment_instructions": {
        "subject": "Informacje dotyczące płatności za zamówienie w %company%",
        "explanation": "Poniżej znajdują się informacje dotyczące płatności za zamówienie %orderId%:",
        "possible_actions": "Przejdź do zamówienia, aby wyświetlić szczegóły.",
        "call_to_action": "Pokaż kolejność"
      },
      "pickup_invite": {
        "subject": "Zamówienie jest gotowe do odbioru",
        "explanation": "Twoje zamówienie z %orderId% jest gotowe do odbioru.",
        "possible_actions": "Przejdź do zamówienia, aby wyświetlić szczegóły.",
        "call_to_action": "Pokaż kolejność"
      },
      "order_picked_up": {
        "subject": "Dziękujemy za zamówienie"
      },
      "request_plugin_activation": {
        "subject": "Wymagana aktywacja wtyczki",
        "explanation": "<p>Witam %firstName% %lastName%,</p><p>%creatorFirstName% %creatorLastName% chciałby aktywować wtyczkę <strong>%pluginName%</strong> dla projektu %platformName%.</p>",
        "possible_actions": "Możesz zalogować się i skorzystać z formularza aktywacyjnego, naciskając poniższy przycisk.",
        "call_to_action": "Aktywuj wtyczkę"
      },
      "plugin_activated": {
        "subject": "Wtyczka aktywowana",
        "explanation": "<p>Witaj %firstName% %lastName%,</p><p>Chcielibyśmy poinformować, że %pluginName% dla projektu %platformName% został aktywowany.</p>",
        "call_to_action": "Zobacz to w akcji"
      },
      "lead_data": {
        "subject": "Nowy lead: %title%",
        "call_to_action": "Pokaż prowadzenie"
      },
      "order": {
        "item_sales": {
          "title": "Twoje zamówienie",
          "explanation": "Szczegóły zamówienia są ponownie wymienione poniżej.",
          "subject": "Twoje zamówienie w %company%",
          "possible_actions": "Wszystkie informacje na temat zamówienia można również znaleźć na koncie klienta.",
          "call_to_action": "Widok na koncie klienta"
        }
      },
      "rent_sync_vamoso_error": {
        "subject": "Zamówienie #%orderId%: Błąd w synchronizacji czynszu dla wtyczki Vamoso: %errorType%",
        "explanation": "Niestety, nasza wtyczka nie mogła wykonać zadania w systemie Vamoso. Sprawdź status rezerwacji ręcznie. <br/>Typ błędu: %errorType% <br/>Komunikat błędu: %errorText%"
      },
      "lead_deadline_reminder": {
        "subject": "Są one przypisane do leadów, które mają być wykonane jutro",
        "explanation": "Tutaj znajdziesz listę przydzielonych ci potencjalnych klientów. Zlecenia te są nadal otwarte i mają zostać zrealizowane jutro.",
        "explanation_first_name_form": "Tutaj znajdziesz listę przydzielonych ci potencjalnych klientów. Zlecenia te są nadal otwarte i mają zostać zrealizowane jutro."
      },
      "chat_history": {
        "subject": "Historia czatów",
        "explanation": "Dziękujemy za skontaktowanie się z nami. W załączniku znajduje się historia czatu z %firstName% %lastName%. Mam nadzieję, że udało nam się pomóc we wszystkich pytaniach i problemach. W razie dalszych pytań prosimy o ponowny kontakt w dowolnym momencie.",
        "greatingchat_history": "Historia czatu",
        "greating": "Dziękujemy za skorzystanie z czatu %platformName%. Twoim partnerem na czacie był %firstName% %lastName% Poniżej możesz zobaczyć historię czatu od %date%.",
        "adaption": "Mam nadzieję, że udało nam się odpowiedzieć na wszystkie pytania i problemy. W razie dalszych pytań prosimy o ponowny kontakt w dowolnym momencie.",
        "privacy_policy": "Naszą politykę prywatności można znaleźć pod adresem <a href=\"%link%\" target=\"_blank\">'%link%</a>.",
        "call_to_action": "Odwiedź nas ponownie"
      },
      "lead_pool_notification": {
        "subject": "Nowa przewaga w puli: %title%",
        "explanation": "W systemie dostępny jest nowy lead. Nie przypisaliśmy jeszcze pracownika. Kliknij poniższy przycisk, aby wyświetlić szczegóły.",
        "call_to_action": "Otwarty przewód"
      },
      "lead_escalation_level1": {
        "subject": "[LEVEL1] %name%",
        "explanation": "Otrzymujesz tę wiadomość e-mail, ponieważ jeden lub więcej potencjalnych klientów osiągnęło pierwszy poziom eskalacji zdefiniowany w regule %name%. Sprawdź potencjalnych klientów, aby dowiedzieć się, co należy zrobić."
      },
      "lead_escalation_level2": {
        "subject": "[LEVEL2] %name%",
        "explanation": "Otrzymujesz tę wiadomość e-mail, ponieważ jeden lub więcej leadów osiągnęło drugi poziom eskalacji zdefiniowany w regule %name%. Sprawdź potencjalnych klientów i spróbuj zmotywować swoje zespoły do cięższej pracy."
      },
      "campaign_budget_auth_request": {
        "subject": "Ostrzeżenie: Przekroczono budżet kampanii.",
        "explanation": "budżet kampanii został przekroczony.",
        "call_to_action": "Pokaż kolejność"
      },
      "campaign_budget_auth_request_unsuccessful": {
        "subject": "Ostrzeżenie: Problem z autoryzacją budżetu kampanii.",
        "explanation": "Wykryto problem z autoryzacją budżetu kampanii.",
        "call_to_action": "Pokaż kolejność"
      },
      "user_budget_auth_request": {
        "subject": "Ostrzeżenie: Przekroczono budżet użytkownika.",
        "explanation": "budżet użytkownika został przekroczony.",
        "call_to_action": "Pokaż kolejność"
      },
      "user_budget_auth_request_unsuccessful": {
        "subject": "Ostrzeżenie: Problem z autoryzacją budżetu użytkownika",
        "explanation": "Wykryto problem z autoryzacją budżetu użytkownika.",
        "call_to_action": "Pokaż kolejność"
      },
      "order_auth_changes_requested": {
        "subject": "Informacja: Wymagane zmiany",
        "call_to_action": "Pokaż kolejność"
      },
      "order_auth_changes_implemented": {
        "subject": "Info: Wprowadzone zmiany",
        "call_to_action": "Pokaż kolejność"
      },
      "manage_notification": "Zarządzanie powiadomieniami e-mail",
      "unsubscribe": "Zrezygnuj z subskrypcji",
      "confidentiality_notice": "Informacja o poufności: Ta wiadomość e-mail jest przeznaczona wyłącznie dla określonego odbiorcy i może zawierać informacje poufne i/lub prawnie chronione. Jeśli nie jesteś właściwym odbiorcą lub otrzymałeś tę wiadomość e-mail przez pomyłkę, niezwłocznie poinformuj o tym nadawcę i zniszcz tę wiadomość e-mail. Nieautoryzowane kopiowanie i nieautoryzowane przekazywanie tej wiadomości e-mail jest niedozwolone.",
      "no_legal_binding_notice": "Niniejsza wiadomość służy wyłącznie celom informacyjnym i nie jest prawnie wiążąca. Ze względu na łatwość manipulowania wiadomościami e-mail, nie ponosimy żadnej odpowiedzialności za ich treść.",
      "invoice_due_reminder": {
        "subject": "Przypomnienie o płatności",
        "explanation": "Niestety nie udało nam się jeszcze potwierdzić otrzymania płatności za poniższą fakturę.",
        "explanation_first_name_form": "Niestety nie udało nam się jeszcze potwierdzić otrzymania płatności za poniższą fakturę.",
        "request_payment": "Aby uniknąć dalszych kosztów, prosimy o natychmiastową płatność całej kwoty. Prosimy o skorzystanie z poniższych danych bankowych w celu opłacenia tej faktury:",
        "request_payment_first_name_form": "Aby uniknąć dalszych kosztów, prosimy o natychmiastową zapłatę całej kwoty. Do opłacenia faktury należy użyć następujących danych bankowych:",
        "contact": "W przypadku jakichkolwiek pytań dotyczących niniejszego przypomnienia prosimy o kontakt za pośrednictwem poczty elektronicznej pod adresem %email% lub telefonicznie pod numerem infolinii %phone%.",
        "contact_first_name_form": "W przypadku jakichkolwiek pytań dotyczących niniejszego przypomnienia prosimy o kontakt za pośrednictwem poczty elektronicznej pod adresem %email% lub telefonicznie pod numerem infolinii %phone%.",
        "bank_account": "Dane bankowe",
        "bank_name": "Nazwa banku",
        "account_holder": "Posiadacz konta",
        "iban": "IBAN",
        "swift_bic": "SWIFT/BIC",
        "bank_code_number": "Kod sortowania banku",
        "account_number": "Numer konta",
        "invoice_number": "Nr faktury:"
      },
      "last_seen_reminder": {
        "subject": "Ostatnio widziane przypomnienie",
        "user_not_seen": "Użytkownik '%clientUsername%' nie był widziany przez %daysNotSeen% dni.",
        "contact_user": "Skontaktuj się z nimi, aby upewnić się, że wszystko jest w porządku.",
        "contact_user_first_name_form": "Skontaktuj się z nimi, aby upewnić się, że wszystko jest w porządku.",
        "customer_profil": "Profil klienta"
      },
      "order_foreign_status": {
        "do_contact_support": {
          "subject": "Zamówienie - skontaktuj się z obsługą klienta",
          "explanation": "Dziękujemy za złożenie zamówienia. Mamy pytanie dotyczące zamówienia. Prosimy o kontakt z naszym działem obsługi klienta.",
          "explanation_first_name_form": "Dziękujemy za złożenie zamówienia. Mamy pytanie dotyczące zamówienia. Prosimy o kontakt z naszym działem obsługi klienta.",
          "possible_actions": "-",
          "contact": "Skontaktuj się z nami",
          "phone": "Telefon:",
          "email": "e-mail:",
          "call_to_action": "-"
        },
        "in_delivery": {
          "subject": "Zamówienie jest obecnie wysyłane",
          "explanation": "Twoje zamówienie zostało wyprodukowane i przekazane kurierowi w celu dostarczenia.",
          "explanation_first_name_form": "Twoje zamówienie zostało wyprodukowane i przekazane kurierowi w celu dostarczenia.",
          "possible_actions_tracking": "Przesyłkę można śledzić na stronie internetowej firmy kurierskiej.",
          "possible_actions_tracking_first_name_form": "Przesyłkę można śledzić na stronie internetowej firmy kurierskiej.",
          "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
          "possible_actions_first_name_form": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
          "call_to_action": "Szczegóły zamówienia",
          "tracking_numbers": "Numery śledzenia"
        },
        "in_production": {
          "subject": "Zamówienie jest obecnie realizowane",
          "explanation": "Dziękujemy za złożenie zamówienia. Złożyliśmy zamówienie. Jest ono obecnie produkowane.",
          "explanation_first_name_form": "Dziękujemy za złożenie zamówienia. Złożyliśmy zamówienie. Jest ono obecnie produkowane.",
          "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
          "possible_actions_first_name_form": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
          "call_to_action": "Szczegóły zamówienia"
        },
        "invalid_print_data": {
          "subject": "Zamówienie - nieprawidłowe dane drukowania",
          "explanation": "Podczas składania zamówienia znaleziono nieprawidłowe dane drukowania.",
          "call_to_action": "Szczegóły zamówienia"
        },
        "missing_print_data": {
          "subject": "Zamówienie - brakujące dane drukowania",
          "explanation": "Podczas składania zamówienia wykryto brakujące dane drukowania.",
          "call_to_action": "Szczegóły zamówienia"
        },
        "order_canceled": {
          "subject": "Zamówienie zostało anulowane",
          "explanation": "Twoje zamówienie zostało pomyślnie anulowane. Przykro nam, że musiałeś anulować zamówienie i mamy nadzieję, że będziemy mogli Ci pomóc w przyszłości.",
          "explanation_first_name_form": "Twoje zamówienie zostało pomyślnie anulowane. Przykro nam, że musiałeś anulować zamówienie i mamy nadzieję, że będziemy mogli Ci pomóc w przyszłości.",
          "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
          "possible_actions_first_name_form": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
          "call_to_action": "Szczegóły zamówienia"
        },
        "payment_pending": {
          "subject": "Zamówienie zakupu - zaległa płatność",
          "explanation": "Nie otrzymaliśmy jeszcze płatności za zamówienie.",
          "explanation_first_name_form": "Nie otrzymaliśmy jeszcze płatności za zamówienie.",
          "possible_actions": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
          "possible_actions_first_name_form": "Możesz zalogować się do portalu online pod adresem %domain% w dowolnym momencie i wyświetlić swoje zamówienie.",
          "call_to_action": "Szczegóły zamówienia"
        }
      },
      "contact_form_cms_confirm": {
        "subject": "Prosimy o potwierdzenie adresu e-mail",
        "explanation": "<p>Twój adres e-mail został wprowadzony do formularza kontaktowego na naszej stronie internetowej.</p><p>Jeśli wypełniłeś nasz formularz kontaktowy, kliknij przycisk Potwierdź.</p><p>W przeciwnym razie zignoruj tę wiadomość.</p>",
        "call_to_action": "Potwierdzenie"
      },
      "contact_form_edition_confirm": {
        "subject": "Prosimy o potwierdzenie adresu e-mail",
        "explanation": "<p>Twój adres e-mail został wprowadzony do formularza kontaktowego na naszej stronie internetowej.</p><p>Jeśli wypełniłeś nasz formularz kontaktowy, kliknij przycisk Potwierdź.</p><p>W przeciwnym razie zignoruj tę wiadomość.</p>",
        "call_to_action": "Potwierdzenie"
      },
      "order_content_check_status": {
        "subject": "sprawdzenie zawartości dla zamówienia %orderId% było %status%",
        "explanation": "Dziękujemy za zamówienie. Sprawdziliśmy zawartość zamówienia i nadaliśmy mu %status% .",
        "possible_actions": "Możesz wywołać swoje zamówienie, aby wyświetlić szczegóły.",
        "call_to_action": "Wyświetl zamówienie",
        "reason": "Powód"
      },
      "lead_follow_up_reminder": {
        "subject": "Sprawdź przydzielone zadania",
        "explanation": "Tutaj znajdziesz listę zadań przypisanych do Ciebie. Zadania te są nadal otwarte.",
        "explanation_first_name_form": "Tutaj znajdziesz listę zadań przypisanych do Ciebie. Zadania te są nadal otwarte."
      },
      "client_domain_authorization_code": {
        "subject": "Kod autoryzacji domeny",
        "explanation": "Po anulowaniu subskrypcji %itemName% dla domeny %clientDomain% otrzymasz od nas kod autoryzacyjny, którego możesz użyć do przeniesienia domeny do innego rejestratora.",
        "explanation_authorization_code": "Kod autoryzacji to %authorizationCode%."
      },
      "new_lead_notification": {
        "subject": "Nowy lead: %title%",
        "explanation": "Utworzono nowego potencjalnego klienta. Kliknij przycisk poniżej, aby wyświetlić szczegóły.",
        "call_to_action": "Otwarty przewód"
      }
    },
    "contact_form_cms_confirm": {
      "success": {
        "heading": "Twoje zapytanie zostało wysłane",
        "body": "<p>Dziękujemy za skontaktowanie się z nami!</p><p>Otrzymaliśmy Twoje zapytanie i skontaktujemy się z Tobą tak szybko, jak to możliwe.</p>"
      },
      "already_confirmed": {
        "heading": "Ta aplikacja została już potwierdzona",
        "body": "<p>Otrzymaliśmy Twoje zapytanie i skontaktujemy się z Tobą tak szybko, jak to możliwe."
      },
      "not_found": {
        "heading": "Nie znaleziono",
        "body": "<p>Upewnij się, że kliknąłeś przycisk w naszym e-mailu, aby dostać się tutaj.</p>"
      }
    },
    "contact_form_edition_confirm": {
      "success": {
        "heading": "Twoje zapytanie zostało wysłane",
        "body": "<p>Dziękujemy za skontaktowanie się z nami!</p><p>Otrzymaliśmy Twoje zapytanie i skontaktujemy się z Tobą tak szybko, jak to możliwe.</p>"
      },
      "already_sent": {
        "heading": "Ta aplikacja została już potwierdzona",
        "body": "<p>Otrzymaliśmy Twoje zapytanie i skontaktujemy się z Tobą tak szybko, jak to możliwe."
      },
      "not_found": {
        "heading": "Nie znaleziono",
        "body": "<p>Upewnij się, że kliknąłeś przycisk w naszym e-mailu, aby dostać się tutaj.</p>"
      },
      "expired": {
        "heading": "Kod wygasł",
        "body": "<p> Link potwierdzający był ważny tylko przez 2 dni. Prosimy o ponowne wypełnienie formularza kontaktowego.</p>"
      }
    },
    "formGroup": {
      "basic_information": "Podstawowe informacje",
      "contact_person": "Osoba do kontaktu",
      "address_information": "Adres",
      "address_settings": "Ustawienia adresu",
      "bank_account": "Dane bankowe",
      "company_information": "Informacje o firmie",
      "user_roles": "Role użytkowników",
      "subscription_roles": "Subskrypcja użytkownika",
      "opening_hours": "Godziny otwarcia",
      "position_on_map": "Pozycja na mapie",
      "white_label_parent": "Prekursor",
      "user_teams": "Zespoły użytkowników",
      "contact_data": "Dane kontaktowe",
      "permissions": "Zezwolenia",
      "person_information": "Informacje o użytkowniku"
    },
    "hints": {
      "small_hint_for_you": "Mała wskazówka dla Ciebie",
      "task_is_automated": "To zadanie jest zautomatyzowane. Musisz poczekać, aż zadanie automatyzacji zostanie uruchomione. Następnie oznaczymy je jako ukończone.",
      "please_complete_previous_tasks": "Nie możesz teraz edytować tego zadania. Najpierw zajmij się poprzednimi zadaniami.",
      "companyLogoNew": "<p>Nie przesłałeś jeszcze swojego logo. Aby je przesłać, po prostu przeciągnij i upuść obraz (jpeg lub png) na tę stronę.</p>",
      "companyLogoChange": "<p>To jest logo, które przesłałeś. Aby je zmienić, po prostu przeciągnij i upuść obraz (jpeg lub png) na tę stronę.</p>)",
      "companyFinancial": "<div><p>Wypełnij informacje wymagane do fakturowania</p><p>Dane bankowe wprowadzone tutaj będą wyświetlane w stopce ofert i faktur</p></div>",
      "companyPaymentInfo": "<p>Informacja ta jest wyświetlana na każdej utworzonej fakturze.</p"
    },
    "vendors": {
      "association": "Powiązanie z danymi klienta",
      "vendor_api": "Nazwa usługi",
      "pricing": "Wycena",
      "operating_mode": "Tryb pracy",
      "price_update_method": "Metoda aktualizacji ceny",
      "order_processing": "Przetwarzanie zamówień",
      "order_method": "Metoda zamawiania",
      "order_email": "Adres e-mail dla zamówień",
      "phone_number": "Numer telefonu",
      "web_shop_url": "Adres URL sklepu internetowego",
      "trial": "próba",
      "live": "na żywo",
      "prices_by_vendor": "Ceny są zarządzane przez dostawcę",
      "prices_by_employee": "Ceny są sprawdzane i utrzymywane przez pracownika",
      "prices_by_api": "Ceny są aktualizowane za pośrednictwem interfejsu API",
      "notified_by_email": "Powiadomienia o przychodzących zamówieniach będą wysyłane pocztą elektroniczną",
      "accepts_only_email": "Przyjmuje zamówienia wyłącznie przez e-mail",
      "use_webshop": "Zamówienia przez sklep internetowy",
      "order_by_api": "Zamówienia przez API",
      "order_by_api_and_webshop": "Zamówienia przez API lub sklep internetowy"
    },
    "client_account": "Konto klienta",
    "deleted_campaigns": "Usunięte kampanie",
    "deleted_editions": "Usunięte edycje",
    "deleted_folders": "Usunięte foldery",
    "deleted_media": "Usunięte media",
    "root": "Katalog główny",
    "trash_bin": "Kosz na śmieci",
    "delete_trash_bin": "Pusty kosz na śmieci",
    "pls_wait": "Proszę czekać. Żądane działanie zostanie wykonane.",
    "go_back": "Powrót",
    "restore": "Przywracanie",
    "archived_campaigns": "Zarchiwizowane kampanie",
    "load_all_campaigns": "Załaduj wszystkie kampanie",
    "load_creacheck_campaigns": "Załaduj kampanie Creacheck",
    "load_flyeralarm_campaigns": "Ładowanie kampanii Flyeralarm",
    "load_designgenie_campaigns": "Kampanie Load DesignGenie",
    "archived_editions": "Wydania zarchiwizowane",
    "sorting_name": "Nazwa",
    "sorting_date_ASC": "Najstarszy pierwszy",
    "sorting_date_DESC": "Najpierw najnowsze",
    "sorting_owner": "Firma",
    "sorting_creator": "Stworzony przez",
    "campaign_title": "Kampania",
    "dataproof": "Kontrola danych",
    "not_ordered": "nie zamówiony",
    "supplier_order": "Zamówienie dostawcy",
    "change_of_address": "Zmiana adresu",
    "not_available": "niedostępny",
    "selected_shipping_method": "Wybrana metoda wysyłki",
    "ordered_items": "Zamówione artykuły",
    "standard": "Standard",
    "express": "Express",
    "aws_iframe_hosting": "Hosting AWS Iframe",
    "aws_hosting_html_file_for_iframe_downloaded": "AWS Hosting Plik HTML dla ramki iframe z tytułem: %title%",
    "lead_stage_change_confirm_title": "Zmiana statusu lidera",
    "lead_stage_change_confirm_body": "Czy naprawdę chcesz zmienić status leada?",
    "iframe_implementation_file": "Plik implementacyjny iframe",
    "iframe_implementation_file_download_failed": "Nie można pobrać pliku implementacji Iframe: %error%",
    "lead_stage_change_error_domain_hosting_in_progress": "Linii nie można zmienić, jeśli trwa bieżąca faza lub jeśli autoryzacja nie została zatwierdzona.",
    "lead_stage_change_error_domain_hosting_command_to_start_pipeline_has_been_sent": "Linii nie można zmienić, jeśli trwa bieżąca faza lub jeśli autoryzacja nie została zatwierdzona.",
    "allowed_domain_already_exists": "Domena %domain% znajduje się już na liście autoryzowanych domen.",
    "allowed_domain_domain_name_should_not_be_empty": "Nazwa domeny nie może być pusta.",
    "allowed_domains_title": "Dozwolone domeny",
    "allowed_domains_description": "Tutaj można dodać domeny, które mogą być używane do implementacji ramek iframe.",
    "allowed_domains_add": "Dodaj domenę",
    "allowed_domains_domain_name": "Nazwa domeny",
    "allowed_domains_default_src": "Default-Src",
    "allowed_domains_script_src": "Script-Src",
    "allowed_domains_connect_src": "Connect-Src",
    "allowed_domains_style_src": "Style-Src",
    "allowed_domains_img_src": "Img-Src",
    "allowed_domains_font_src": "Font-Src",
    "allowed_domains_frame_src": "Frame-Src",
    "created_at": "Utworzono dnia",
    "created_by": "Stworzony przez",
    "overnight": "Nocleg",
    "create_printData": "Tworzenie danych drukowania",
    "created_new_printData": "Utworzono nowe dane drukowania",
    "uploaded_printData_successful": "Dane drukowania zostały pomyślnie przesłane",
    "no_printData": "Brak danych drukowania",
    "no_images": "Brak dostępnych plików graficznych",
    "not_the_original_printFile": "Uwaga: To nie jest oryginalny plik do druku",
    "printDataFiles": "Drukowanie plików danych",
    "image_files": "Pliki obrazów",
    "customer_comment": "Komentarz klienta",
    "actions_": " Działania",
    "import_success": "Import został wykonany",
    "import_rejected": "Twój import został anulowany",
    "import_exec": "Import jest wykonywany",
    "import_successRE": "Twój import został zresetowany",
    "import_rejectedRE": "Proces został anulowany",
    "import_execRE": "Import zostanie zresetowany",
    "choice_csv": "Wybierz plik CSV",
    "csv_content": "Zawartość CSV",
    "warning_and_notes": "Ostrzeżenia i uwagi",
    "start_remove": "Rozpoczęcie usuwania",
    "reverse_remove": "Cofnij usunięcie",
    "user_import_success": "Import powiódł się",
    "user_duplicate_import": "Znaleziono zduplikowany wpis",
    "start_import": "Rozpocznij import",
    "reverse_import": "Cofnij import",
    "upload_local_changes": "Prześlij zmiany lokalne",
    "dataproof_master_copies_upload": "W tym miejscu można przesłać lokalnie edytowany szablon/plik danych za pomocą przycisku [Prześlij].",
    "edit_edition": "Edycja materiałów reklamowych",
    "dataproof_master_copies_recreate": "W tym miejscu można użyć przycisku [Utwórz dane drukowania], aby zainicjować nowe tworzenie szablonu/pliku danych drukowania na podstawie oryginału lub edytować szablon/plik danych drukowania lokalnie za pomocą przycisku [Edytuj].",
    "hint": "Uwaga",
    "dataproof_hint_master_copies_upload": "Zmieniony lokalnie plik szablonu/pliku danych wydruku musi zostać przesłany za pomocą przycisku [Prześlij], aby zmiana zaczęła obowiązywać po zakończeniu przetwarzania końcowego.",
    "edit_": "Edytuj",
    "send_invoice": "Wyślij fakturę",
    "sended_invoice": "Faktura została wysłana na adres",
    "send_email_tracking": "Śledzenie przesyłki przez e-mail",
    "sended_email": "Wiadomość e-mail została wysłana!",
    "tracking": "Śledzenie przesyłek",
    "send_tracking_to_customer": "Wysyłanie śledzenia przesyłek do klientów",
    "to_the_product": "Do produktu",
    "order_sended": "Zamówienie zostało wysłane!",
    "failure_when_ordering": "Wystąpił błąd podczas składania zamówienia!",
    "please_create_orderhistory_email": "Zamówienie zostało wysłane e-mailem! Utwórz historię zamówień!",
    "new_status_set": "Nowy status został ustawiony!",
    "change_of_status": "Zmiana statusu",
    "statusQuery": "Zapytanie o status",
    "totalStatus": "Status ogólny:",
    "printData": "Drukuj dane",
    "current_printdata_sended": "Bieżące dane drukowania zostały wysłane!",
    "orderHistory": "Proces zamawiania",
    "orderHistory_created": "Historia zamówień została utworzona!",
    "order_created": "Zamówienie zostało pomyślnie utworzone!",
    "enter_trackingLink": "Wprowadź link do śledzenia",
    "query_orderStatus": "Status zapytania o zamówienie",
    "foreignId": "Nr zamówienia zewnętrznego.",
    "orderStatus": "Status zamówienia",
    "orderDate": "Data zamówienia",
    "options_": "Opcje",
    "type_": "Rodzaj",
    "comment_": "Komentarz",
    "orderOptions": "Opcje zamawiania",
    "ordering_by_webshop": "Zamówienie przez sklep internetowy",
    "ordering_by_email": "Zamówienie przez e-mail",
    "info_to_the_emailOrder": "Dodatkowe informacje dotyczące zamówień e-mail",
    "add_or_edit_foreignId": "Dodawanie lub edytowanie zewnętrznego numeru zamówienia",
    "create_trackingLink": "Utwórz łącze do śledzenia przesyłki",
    "campaign": {
      "copy_edition": "Duplikat",
      "created_by": "Utworzony przez:",
      "no_edition": "Nie edytowałeś jeszcze żadnych materiałów reklamowych!",
      "configuration": "Opcje ogólne",
      "select_all": "Wszystkie produkty",
      "budget": "Budżet kampanii",
      "access": "Prawa dostępu",
      "inactive": "Kampania blokady",
      "delete": "Usuń kampanię",
      "transfer_title": "Przeniesienie kampanii do innego użytkownika",
      "campaign_owner": "Właściciel kampanii",
      "campaign_creator": "Twórca kampanii",
      "change_owner": "Zmiana właściciela kampanii",
      "change_creator": "Zmień twórcę kampanii",
      "order_all": "Zamów całą kampanię",
      "share": "Kampania udostępniania",
      "archive": "Kampania archiwalna",
      "recent_editions": "Ostatnia edycja",
      "marked_editions": "Oznaczone materiały reklamowe",
      "marked_campaigns": "Kampanie oznaczone etykietą",
      "own_campaigns": "Kampanie własne",
      "shared_editions": "Udostępniane materiały reklamowe",
      "last_edited": "Edytowano na:",
      "generated": "Utworzono dnia:",
      "edition_edit": "Uruchom edytor",
      "edition_item": "Wybrany produkt",
      "submission_item": "Wybrane zgłoszenie",
      "edition_configuration": "2. ustawienia",
      "edition_order": "Dodaj do koszyka",
      "edition_reorder": "Zmiana kolejności",
      "ordered": "Zamówione!",
      "edition_delete": "Usuń",
      "edition_preview": "Podgląd",
      "edition_edit_alt": "Edytuj",
      "edition_error_empty": "Masz %s nieedytowanych stron",
      "submissions": "Szablony",
      "new": "Utwórz nową kampanię",
      "back_to_list": "Wszystkie kampanie",
      "new_edition": "Tworzenie nowych materiałów reklamowych",
      "settings": "Zarządzanie kampanią",
      "campaign_archived": "Twoja kampania została zarchiwizowana",
      "successfully_archived": "Archiwizacja zakończona sukcesem",
      "save_settings": "Zapisz",
      "cancel_settings": "Anuluj",
      "title": "Nazwa kampanii",
      "edition_title": "Oznaczenie",
      "publisher": "Wskazanie wydawcy",
      "creator": "Kampania stworzona przez:",
      "editions": "Materiały reklamowe",
      "edition": "Materiały reklamowe",
      "sidebar": "Kampanie",
      "mark_edition": "Oznaczanie materiałów reklamowych",
      "unmark_edition": "Usuń oznaczenie",
      "campaign_title_valid": "Wprowadź nazwę kampanii.",
      "campaign_publisher_valid": "Wprowadź dane dotyczące praw autorskich.",
      "choose_company_to_load_campaign": "Aby załadować kampanie, wybierz firmę lub kliknij przycisk \"Załaduj wszystkie kampanie\".",
      "no_product_selected": "Nie wybrano żadnego produktu",
      "new_edition_title": "Nowa edycja",
      "added_to_cart": "Twoja kampania została dodana do koszyka!",
      "edition_add_to_cart_title": "Produkt w koszyku",
      "edition_add_to_cart_button_label": "Świetnie",
      "edition_add_to_cart_label": "Do koszyka zakupów",
      "edition_added_to_cart_message": " został dodany do Twojego koszyka.",
      "edit_disabled": "Edycja edycji została tymczasowo wyłączona z powodu aktualizacji szablonu.",
      "export_image": "Eksportuj obraz",
      "unknown": "nieznany",
      "already_ordered": "Ta edycja została już zamówiona i nie można jej edytować. Można jednak powielić edycję i edytować ją. Zobacz menu",
      "modal_tabs": {
        "default_edition": "Ogólne materiały reklamowe",
        "edition_from_submission": "Materiały reklamowe z szablonu"
      }
    },
    "uom": {
      "piece": "Kawałek",
      "gram": "gram",
      "kilogram": "Kilogram",
      "running_meter": "Metr bieżący",
      "liter": "Litry",
      "package": "Pakiet",
      "kilometer": "Kilometry"
    },
    "uom_symbole": {
      "gram": "g",
      "kilogram": "kg",
      "running_meter": "metry bieżące",
      "liter": "l",
      "package": "Pkt.",
      "kilometer": "km"
    },
    "pipelineViews": {
      "self": "Przypisany do mnie",
      "unassigned": "Bez przydziału",
      "pastdeadline": "Przekroczony termin",
      "duetoday": "Do dzisiaj"
    },
    "leadPriority": {
      "critical": "Krytyczny",
      "high": "Wysoki",
      "normal": "Normalny",
      "low": "Niski"
    },
    "orderTypes": {
      "item_sales": "Sprzedaż",
      "car_rent": "Wynajem samochodów",
      "car_sales": "Sprzedaż samochodów",
      "car_service": "Spotkanie warsztatowe"
    },
    "genderOptions": {
      "male": "Mężczyzna",
      "female": "Kobieta"
    },
    "salutationOptions": {
      "mr": "Pan",
      "mrs": "Pani"
    },
    "team_service": "Zespół serwisowy",
    "team_dispo": "Zespół ds. dyspozycji",
    "team_rent": "Zespół ds. wynajmu",
    "team_sales": "Zespół sprzedaży",
    "car_documents": "Dokumenty pojazdu",
    "nav_module_chip": "Moduł nawigacji satelitarnej / chip",
    "safety_vest": "Kamizelka bezpieczeństwa",
    "parking_disk": "Tarcza parkingowa",
    "ice_scraper": "Skrobaczka do lodu",
    "key_supplement_heating_taxis": "Dodatek do kluczy (ogrzewanie postojowe/znak taxi)",
    "shelf_blind": "Półka na kapelusze / roleta",
    "onboard_folder": "Folder pokładowy",
    "first_aid_kit": "Apteczka pierwszej pomocy",
    "warning_triangle": "Trójkąt ostrzegawczy",
    "tirefit_spare_wheel": "Opona / koło zapasowe",
    "handover_damage": {
      "front": "Przód",
      "back": "Tył",
      "left": "Linki",
      "right": "Prawo",
      "top": "Powyżej",
      "interior": "Wnętrze"
    },
    "dark": "Jest ciemno",
    "garage": "W garażu",
    "snow_ice": "Śnieg/lód",
    "rain_wet": "Pada deszcz / samochód jest mokry",
    "countries": {
      "de": "Niemcy",
      "pl": "Polska",
      "fr": "Francja"
    },
    "cookie_description": {
      "PHPSESSID": "Identyfikuje bieżącą sesję przeglądarki",
      "tempTarget": "Używamy tymczasowego docelowego pliku cookie, aby zidentyfikować produkty, których szukasz i Twoje zainteresowania",
      "chedriCookiesBoxClosed": "Używamy tego pliku cookie, aby wskazać, że użytkownik widział i zaakceptował nasze informacje o plikach cookie",
      "chedriCookiesPerformance": "Służy do określania, czy użytkownik akceptuje nasze wydajnościowe pliki cookie",
      "chedriCookiesTargeting": "Służy do określania, czy użytkownik akceptuje nasze targetujące pliki cookie.",
      "ga": "Służy do rozróżniania użytkowników w usłudze Google Analytics",
      "gid": "Służy do rozróżniania użytkowników w usłudze Google Analytics",
      "act": "Jest to plik cookie Facebooka, który jest wykorzystywany do jego analizy i badań",
      "c_user": "Logowanie na Facebooku dla użytkowników, które jest wykorzystywane do wyświetlania reklam, które są bardziej odpowiednie dla użytkowników i ich zainteresowań.",
      "datr": "Używany przez Facebooka do identyfikacji przeglądarki użytkownika.",
      "fr": "Zawiera unikalny identyfikator przeglądarki i użytkownika, który jest wykorzystywany do wyświetlania ukierunkowanych reklam.",
      "sb": "Używany przez Facebooka do ulepszania sugestii znajomych",
      "xs": "Plik cookie Facebooka używany do zarządzania sesją. Działa w połączeniu z plikiem cookie c_user w celu uwierzytelnienia tożsamości użytkownika na Facebooku."
    },
    "cookie_ttl": {
      "6_months": "6 miesięcy",
      "60_days": "60 dni",
      "2_years": "2 lata",
      "1_day": "1 dzień"
    },
    "upload_and_manage_media_files": "Tutaj możesz przesyłać swoje multimedia i zarządzać nimi.",
    "profile_information_avatar_contact_address": "Tutaj możesz edytować informacje o swoim profilu. Awatar, dane kontaktowe, adres do fakturowania, informacje finansowe.",
    "define_sales_points_address_position_map_opening_hours": "Zdefiniuj swoje punkty sprzedaży: Adres, położenie na mapie, godziny otwarcia. Ważne dla zarządzania produktami i pracownikami.",
    "check_incoming_and_outgoing_invoices_check_details_and_download_as_pdf": "Możesz sprawdzić przychodzące i wychodzące faktury wygenerowane przez system lub innych użytkowników. Wyświetl szczegóły i pobierz w formacie PDF.",
    "found_api_interesting_personal_api_key_here": "Czy uważasz, że nasze API jest interesujące? Chciałbyś je wypróbować? Tutaj możesz znaleźć swój osobisty klucz API.",
    "need_our_data_in_own_system_configure_data_push": "Potrzebujesz naszych danych we własnym systemie? Tutaj możesz skonfigurować usługi wypychania danych, aby subskrybować zdarzenia danych.",
    "projects_running_on_different_domains": "Projekty realizowane w różnych domenach",
    "administration_of_your_website_logo_menu_pages": "Zarządzanie witryną. Logo, menu główne, strony systemowe",
    "create_users_manage_profile_financial_roles_teams": "Twórz użytkowników, którzy pracują w Twojej organizacji, zarządzaj ich profilami i informacjami finansowymi, przeglądaj i definiuj role bezpieczeństwa oraz przypisuj zespoły.",
    "provision_service": "Zarezerwowane usługi",
    "provision_service_description": "W tym miejscu można tworzyć, edytować lub usuwać nowe typy usług",
    "provision_service_title": "Tytuł",
    "provision_service_type": "Nazwa",
    "provision_service_description_text": "Opis",
    "provision_service_primary_rate": "Prowizja w procentach dla prim. właściciel",
    "provision_service_secondary_rate": "Prowizja w procentach dla drugiego właściciela",
    "provision_service_type_status": "Status",
    "add_provision_type": "Dodaj typ prowizji",
    "edit_provision_type": "Edytuj",
    "create_provision_type": "Utwórz typ prowizji",
    "service_type_onboarding": "Onboarding (instalacja)",
    "service_type_subscriptions": "Subskrypcje (MRR, subskrypcje i opłaty za użytkowanie)",
    "service_type_additional_services": "Usługi dodatkowe (szablony)",
    "service_type_product_sales": "Sprzedaż produktów",
    "sales": "Dystrybucja",
    "primary_owner": "Główny właściciel",
    "secondary_owner": "Drugi właściciel",
    "provision_start": "Start",
    "provision_ending": "Koniec",
    "provision_actions": "Działania",
    "add_provision_service": "Utwórz prowizję",
    "edit_provision_service": "Edytuj prowizję",
    "save_provision_type": "Zapisz",
    "prov_service_client_name": "Nazwa",
    "provision_service_documents": "Prowizje",
    "commission_service_documents": "Prowizje - Dokumenty",
    "assign_service_type": "Przypisywanie nowego typu usługi",
    "provision_service_documents_description": "Wszystkie prowizje można zobaczyć tutaj",
    "provision_service_documents_download": "Pobieranie dokumentów",
    "provision_service_documents_download_start": "pobieranie",
    "provision_service_documents_name": "Nazwa",
    "provision_service_documents_title": "Tytuł",
    "provision_service_documents_description_text": "Opis",
    "provision_service_documents_serviceType": "Typ",
    "provision_service_documents_provisionRate": "Stawka prowizji",
    "provision_service_documents_provisionValue": "Wartość prowizji",
    "provision_service_documents_provisionAssignee": "Odbiornik",
    "provision_service_documents_provisionPayedDate": "zapłacone w dniu",
    "provision_service_documents_IsActive": "Status",
    "provision_service_documents_Client": "Klient",
    "provision_service_documents_choose_month": "Wybierz żądany miesiąc",
    "commission_service_documents_description": "Wszystkie faktury i szczegóły dotyczące prowizji można wyświetlić i pobrać tutaj",
    "commission_service_documents_download": "Pobieranie dokumentów",
    "commission_service_documents_download_start": "pobieranie",
    "commission_client": "Klient",
    "commission_invoice_no": "Numer faktury",
    "commission_payed_data": "zapłacone w dniu",
    "commission_service_type": "Typ usługi",
    "commission_amount": "Kwota",
    "commission_provision": "Komisja",
    "create_update_teams_assign_system_types": "Twórz i edytuj zespoły w swojej firmie. Przypisywanie typów systemów do zespołów.",
    "create_document_types_use_for_process_automation": "Twórz własne typy dokumentów, aby móc ich używać w integracjach lub do automatyzacji procesów.",
    "write_email_templates_send_to_clients": "Twórz szablony wiadomości e-mail, które mogą być używane podczas wysyłania wiadomości do klientów lub do automatyzacji niektórych przepływów pracy.",
    "define_reusable_cms_elements": "Zdefiniuj elementy wielokrotnego użytku, które mogą być używane na dowolnej stronie.",
    "manage_brands_edit_name_description": "Zarządzaj markami produktów, edytuj ich nazwy i opisy w wielu językach.",
    "manage_car_models_and_aliases": "Zarządzanie modelami samochodów, z których mogą wybierać użytkownicy. Definiowanie aliasów dla importu.",
    "display_and_manage_category_tree": "Wyświetlanie drzewa kategorii i manipulowanie nim. Można zmieniać nazwy i opisy.",
    "display_and_manage_product_templates": "Twórz i zarządzaj szablonami oraz pozwól użytkownikom na kreatywność.",
    "define_product_attributes_search_and_variants": "Zdefiniuj dodatkowe atrybuty, które możesz określić w swoich produktach. Atrybuty te ułatwiają użytkownikom wyszukiwanie produktów. Tworzenie wariantów produktów na podstawie atrybutów.",
    "group_attributes_to_better_manage_and_inform": "Możesz grupować atrybuty, aby lepiej zarządzać doświadczeniem użytkownika w backend i prezentować atrybuty przedmiotów swoim klientom w bardziej przejrzysty sposób.",
    "entity_fields_missing_add_new": "Jeśli w Twoich formularzach brakuje niektórych pól, możesz dodać je tutaj. Przydatne w kampaniach marketingowych i integracjach.",
    "define_manage_pipelines_and_stages": "Definiowanie i dostosowywanie potoków dla przepływów pracy. Dodawaj, edytuj i usuwaj etapy.",
    "create_questionnaires_for_workflows_and_campaigns": "Twórz kwestionariusze, które mogą być wykorzystywane w przepływach pracy lub różnych kampaniach marketingowych.",
    "catch_leads_from_other_sources_with_custom_email": "Wyłapuj potencjalnych klientów z wielu źródeł za pomocą naszego Lead Catchera. Skonfiguruj indywidualne adresy e-mail dla każdego pożądanego źródła.",
    "group_car_models_into_families_for_users_to_easily_find": "Grupuj modele pojazdów w rodziny, aby jeszcze bardziej ułatwić użytkownikom wyszukiwanie samochodów.",
    "enrich_car_search_functionality_with_own_model_images": "Wzbogać funkcję wyszukiwania samochodów o własne zdjęcia modeli. Przypisywanie zdjęć do modeli lub grup modeli.",
    "tag_leads_with_custom_regular_expressions": "Rozszerz funkcjonalność naszego systemu tagów o tagi zdefiniowane przez użytkownika. Wymagana jest znajomość wyrażeń regularnych.",
    "unknown_error": "Wystąpił nieznany błąd",
    "username_required": "Wprowadź nazwę użytkownika",
    "email_required": "Wprowadź swój adres e-mail",
    "email_invalid": "Adres e-mail jest nieprawidłowy",
    "client_name_required": "Wprowadź swoje imię i nazwisko",
    "password_required": "Wprowadź hasło",
    "password_length": "Hasło musi składać się z co najmniej 8 znaków",
    "repeat_password_required": "Powtórz hasło",
    "repeat_password_length": "Powtarzane hasło musi składać się z co najmniej 8 znaków",
    "message_required": "Wprowadź wiadomość",
    "second_password_incorrect": "Drugie hasło nie jest takie samo jak pierwsze",
    "registration_not_confirmed": "Rejestracja nie została jeszcze potwierdzona. Sprawdź swoją skrzynkę pocztową i kliknij przycisk, aby potwierdzić swój adres e-mail",
    "account_deleted": "To konto zostało usunięte!",
    "account_inactive": "Subskrypcja tego konta została anulowana!",
    "company_required": "Wprowadź nazwę firmy",
    "first_name_required": "Wprowadź imię",
    "last_name_required": "Wprowadź nazwisko",
    "country_required": "Wybierz kraj",
    "phone_required": "Wprowadź numer telefonu",
    "street_required": "Wprowadź nazwę ulicy",
    "house_number_required": "Wprowadź numer domu",
    "zip_code_required": "Wprowadź kod pocztowy",
    "zip_code_invalid": "Kod pocztowy \"{{ wartość }}\" nie jest prawidłowym kodem pocztowym",
    "city_required": "Wprowadź nazwę miasta",
    "vat_id_required": "Wprowadź identyfikator podatku od sprzedaży",
    "timezone_required": "Wybierz strefę czasową",
    "select_job": "Wybierz zadanie",
    "title_required": "Wprowadź tytuł",
    "type_required": "Wybierz typ",
    "location_required": "Wprowadź lokalizację",
    "questionnaire_required": "Wybierz kwestionariusz",
    "subject_required": "Wprowadź temat",
    "amount_required": "Wprowadź kwotę",
    "time_count_required": "Wprowadź ilość czasu",
    "name_required": "Wprowadź nazwę",
    "price_required": "Wprowadź cenę",
    "description_required": "Wprowadź opis",
    "question_required": "Wprowadź pytanie",
    "content_required": "Wprowadź zawartość",
    "template_required": "Wybierz szablon",
    "payment_schedule_required": "Wybierz plan płatności",
    "answer_required": "Wprowadź odpowiedź",
    "website_required": "Wprowadź adres strony internetowej",
    "fax_required": "Wprowadź numer faksu",
    "currency_required": "Wybierz walutę",
    "vat_name_required": "Wprowadź nazwę podatku VAT",
    "vat_id_name_required": "Wprowadź nazwę identyfikatora VAT",
    "vat_rates_required": "Wprowadź stawki VAT",
    "coc_number_required": "Wprowadź numer COC",
    "registered_at_required": "Wprowadź dane, pod którymi zarejestrowana jest Twoja firma",
    "bank_name_required": "Wprowadź nazwę banku",
    "account_holder_required": "Wprowadź pełne imię i nazwisko posiadacza konta",
    "account_number_required": "Wprowadź numer konta",
    "bank_location_required": "Wprowadź lokalizację banku",
    "bank_code_number_required": "Wprowadź numer rozliczeniowy banku",
    "iban_required": "Wprowadź numer IBAN",
    "min_message": "Ta wartość jest zbyt krótka. Powinna mieć {{ limit }} znaków lub więcej",
    "max_message": "Ta wartość jest zbyt długa. Powinna mieć {{ limit }} znaków lub mniej",
    "min_max_length": "Wartość ta powinna mieścić się w przedziale od {{ min }} do {{ max }}. znaków",
    "greater_than_or_equal": "Wartość ta powinna być większa lub równa {{ compared_value }}.",
    "price_type_invalid": "Typ ceny jest nieprawidłowy",
    "This value should not be blank": "Wartość ta nie powinna być pusta",
    "required_message": "Wartość ta nie może być pusta",
    "This value is not a valid URL": "Ta wartość nie jest prawidłowym adresem URL",
    "must_upload_a_file": "Musisz przesłać plik",
    "file_format_not_supported": "Plik, który próbujesz przesłać, nie jest obsługiwany",
    "category_not_empty": "Kategoria nie jest pusta",
    "brand_has_items": "Marka przydzieliła produkty",
    "template_has_items": "Produkty są przypisane do szablonu",
    "item_has_variants": "Produkt posiada przypisane warianty",
    "no_valid_variant": "Brak ważnego wariantu",
    "no_stage_set": "Nie ustawiono żadnego poziomu",
    "no_pipeline_set": "Nie ustawiono żadnego rurociągu",
    "no_users_found": "Nie znaleziono użytkowników",
    "no_team_set": "Brak zestawu drużyn",
    "budget_required": "Prosimy o określenie budżetu",
    "client_required": "Przypisz klienta",
    "order_required": "Przydziel zamówienie",
    "item_required": "Przypisz produkt",
    "document_required": "Upewnij się, że dokumenty są dostępne i ważne",
    "brand_required": "Wybierz markę",
    "model_required": "Wybierz model",
    "order_type_invalid": "Nieprawidłowy typ zamówienia",
    "item_already_reserved": "Niektóre produkty nie są dostępne w wybrane dni",
    "wrong_credentials": "Nazwa użytkownika lub hasło zostały wprowadzone nieprawidłowo. Spróbuj ponownie lub kliknij \"Nie pamiętam hasła\".",
    "no_car_found": "Nie znaleziono pojazdu dla tego ołowiu",
    "data_push_event_invalid": "Zdarzenie wypychania danych jest nieprawidłowe",
    "data_push_auth_invalid": "Typ uwierzytelniania wypychania danych jest nieprawidłowy",
    "cart_type_invalid": "Typ koszyka jest nieprawidłowy",
    "system_type_invalid": "Typ systemu jest nieprawidłowy",
    "menu_version_invalid": "Wersja menu jest nieprawidłowa",
    "logo_version_invalid": "Wersja logo jest nieprawidłowa",
    "payment_method_invalid": "Nie mogliśmy przetworzyć podanej metody płatności",
    "nationality_required": "Wprowadź swoją narodowość",
    "place_of_birth_required": "Wprowadź swoje miejsce urodzenia",
    "date_of_birth_required": "Wprowadź swoją datę urodzenia",
    "id_required": "Wprowadź identyfikator",
    "meta_identifier_required": "Wybierz identyfikator",
    "payment_status_invalid": "Status płatności jest nieprawidłowy",
    "iban_invalid": "Wprowadzona wartość nie jest prawidłowym numerem konta",
    "swift_bic_required": "Wprowadź prawidłowy numer SWIFT/BIC",
    "reference_required": "Wprowadź numer referencyjny",
    "service_status_invalid": "Status usługi jest nieprawidłowy",
    "date_proposal_type_invalid": "Sugerowany typ daty jest nieprawidłowy",
    "mobile_phone_invalid": "Numer telefonu komórkowego jest nieprawidłowy",
    "mobile_phone_required": "Wprowadź swój numer telefonu komórkowego",
    "phone_invalid": "Numer telefonu jest nieprawidłowy",
    "fax_invalid": "Numer faksu jest nieprawidłowy",
    "salutation_required": "Wybierz pozdrowienie",
    "gender_required": "Wybierz płeć",
    "stripe_error": "Błąd podczas łączenia ze Stripe",
    "stripe_connect_error_with_type": "Nie mogliśmy połączyć CreaCheck z Twoim kontem Stripe. Kod błędu: {typ}.",
    "car_rental_pickup_time_invalid": "Żądany czas odbioru jest poza godzinami otwarcia lokalizacji",
    "car_rental_drop_off_time_invalid": "Żądany czas zwrotu samochodu jest poza godzinami otwarcia lokalizacji",
    "accept_terms_and_cancellation_before_continuing": "Prosimy o potwierdzenie, że zapoznałeś się z naszymi Ogólnymi warunkami i zasadami anulowania rezerwacji.",
    "problem_processing_your_credit_card": "Mamy problem z przetworzeniem karty kredytowej",
    "invoice_type_invalid": "Typ faktury jest nieprawidłowy",
    "doc_number_required": "Wprowadź numer dokumentu",
    "doc_issuing_city_required": "Prosimy o wskazanie miasta wydającego",
    "doc_issuing_authority_required": "Należy wskazać organ wydający",
    "doc_issue_date_required": "Wprowadź datę wydania",
    "doc_expiration_date_required": "Wprowadź datę wygaśnięcia",
    "test_domain_required": "Wprowadź nazwę domeny testowej",
    "iban_has_wrong_format": "Format IBAN jest nieprawidłowy",
    "car_cash_payment_data_missing_or_invalid": "Brak danych dotyczących płatności gotówkowych lub są one nieprawidłowe",
    "car_registration_service_only_available_in_germany": "Usługa autoryzacji jest obecnie dostępna tylko w Niemczech",
    "car_transport_only_available_in_germany": "Nasza usługa transportowa jest obecnie dostępna tylko w Niemczech",
    "target_type_invalid": "Nieprawidłowy typ celu",
    "company_email_must_be_different_from_users_email": "Adres e-mail firmy musi być inny niż adres e-mail użytkownika",
    "car_transport_distance_too_short": "Transport samochodowy jest możliwy tylko do lokalizacji oddalonych o ponad 100 km.",
    "first_offer_price_too_low": "Twoja pierwsza oferta jest zbyt niska i nie została zaakceptowana.",
    "first_offer_price_too_high": "Twoja pierwsza oferta jest wyższa niż pierwotna cena i nie została zaakceptowana.",
    "current_offer_too_low": "Obecna oferta jest zbyt niska",
    "not_your_turn_to_negotiate_the_price": "Nie możesz teraz zaktualizować swojej oferty. Poczekaj na odpowiedź naszego pracownika",
    "the_question_must_be_answered": "Należy odpowiedzieć na pytanie \"{{ pytanie }}\"",
    "validation_problems_with_item_attributes": "Sprawdź, czy atrybuty zostały wypełnione poprawnie. Problemy, które wystąpiły, są zaznaczone na czerwono",
    "the_field_name_contains_following_errors": "Pole {fieldName} zawiera następujący błąd: {helpText}",
    "plugin_required": "Wybierz plan subskrypcji.",
    "invalidRegex": "Podane wyrażenie regularne jest nieprawidłowe",
    "assignment_strategy_type_invalid": "Typ strategii przypisania jest nieprawidłowy",
    "cannot_remove_role_company": "Nie można usunąć roli użytkownika ROLE_COMPANY",
    "coupon_type_invalid": "Wybierz prawidłowy typ kuponu",
    "subscription_starting_fee": "Opłata za subskrypcję",
    "comms_center_monthly_fee": "Centrum komunikacyjne Creacheck",
    "comms_center_chat": "Centrum komunikacji Creacheck - czat z klientem",
    "comms_center_call": "Centrum komunikacyjne Creacheck - połączenie z klientem",
    "0": "<strong>Fehler beim Einloggen</strong>",
    "You are not authenticated": "Niestety nie znamy adresu e-mail i hasła",
    "access_denied": "Aby się zalogować, musisz zaakceptować dostęp do Creacheck.com",
    "oauth_email_missing": "Nie możemy zalogować użytkownika za pośrednictwem mediów społecznościowych. Prosimy o zalogowanie się za pomocą adresu e-mail.",
    "User already exists": "Podany adres e-mail istnieje już w naszej bazie danych. Zapomniałeś hasła?",
    "next": "Dalej",
    "create_comment": "Napisz komentarz",
    "new_pipeline": "Nowy rurociąg",
    "new_stage": "Nowy poziom",
    "quantity": "Ilość",
    "login_error": "Błąd podczas logowania",
    "not_found": "Nie znaleziono",
    "deal_information": "Informacje o transakcji",
    "login_domain": "Domena logowania",
    "login_domain_successfully_changed": "Domena logowania została pomyślnie zmieniona.",
    "missing_lead_information": "Wprowadź brakujące informacje o potencjalnych klientach",
    "missing_informations": "Brakujące informacje",
    "missing_client_signature": "Brakujący podpis klienta",
    "previous": "Wcześniej",
    "current": "Aktualny",
    "customCssInvalid": "Niestandardowy CSS jest nieprawidłowy ({{ błąd }})",
    "accountAndLogin": "Konto i logowanie",
    "invalidEIN": "Nieprawidłowy numer eVB (elektroniczne potwierdzenie ubezpieczenia)",
    "radio": "Radio",
    "airConditioning": "Klimatyzacja",
    "parkingAssistanceRear": "Wspomaganie parkowania tyłem",
    "airConditioningAutomatic": "Automatyczna kontrola klimatu",
    "navigation": "Nawigacja satelitarna",
    "alloyRims": "Felgi aluminiowe",
    "parkingAssistanceFrontRear": "Przedni i tylny system wspomagania parkowania",
    "metallicPaint": "Lakier metaliczny",
    "cruiseControl": "Tempomat",
    "seatHeating": "Ogrzewanie siedzenia",
    "leatherSeats": "Skórzane fotele",
    "equipmentLevel": "Poziom wyposażenia",
    "motorPower": "Wydajność",
    "currentLocation": "Aktualna lokalizacja",
    "distance": "Odległość",
    "selected": "wybrany",
    "noBrandSelected": "Nie wybrano marki",
    "showMore": "Pokaż więcej",
    "showLess": "Pokaż mniej",
    "optional": "Opcjonalnie",
    "altogether": "Łącznie",
    "monday": "Poniedziałek",
    "tuesday": "Wtorek",
    "wednesday": "Środa",
    "thursday": "Czwartek",
    "friday": "Piątek",
    "saturday": "Sobota",
    "sunday": "Niedziela",
    "payment_data_missing_or_invalid": "Brak danych płatności lub są one nieprawidłowe",
    "previousSearchRequests": "Poprzednie wyszukiwania",
    "reset": "Reset",
    "number_days": "{dni, liczba mnoga, zero {# dni} jeden {# dni} inne {# dni}}.",
    "number_months": "{miesiące, liczba mnoga, zero {# miesięcy} jeden {# miesięcy} inne {# miesięcy}}.",
    "outdated_browser": "Twoja przeglądarka jest przestarzała!",
    "update_browser_text": "Zaktualizuj przeglądarkę, aby poprawnie wyświetlała tę stronę.",
    "update_browser_btn_text": "Zaktualizuj przeglądarkę teraz",
    "save_successfully": "Pomyślnie zapisane",
    "add_quickly": "Dodaj szybko",
    "load_data": "Dane ładowania",
    "car_data_import_vin_info": "Wprowadź prawidłowy numer VIN, aby automatycznie wypełnić formularz danych pojazdu.",
    "car_data_import_missing_attr_values": "Brakuje niektórych wartości atrybutów - są one zaznaczone w formularzu.",
    "vin_invalid": "FIN jest nieprawidłowy",
    "others": "Inne",
    "activate_plugin_to_enable_feature": "Ta funkcja jest dostępna tylko z następującą wtyczką. Aktywuj wtyczkę, aby nadal korzystać z tej funkcji.",
    "car_data_import_failed": "Nie można było zaimportować danych pojazdu dla tego numeru VIN. Sprawdź, czy podany numer VIN jest prawidłowy.",
    "send_mail_via_outlook": "Wiadomość e-mail jest wysyłana za pomocą konta Microsoft Outlook ({email}) i jest później dostępna w folderze \"Wysłane\".",
    "permission_required": "Wymagane zezwolenia",
    "phone_call_permissions_required": "Ta aplikacja musi mieć dostęp do kont telefonicznych w celu wykonywania połączeń",
    "calling": "Zadzwoń do mnie",
    "callingTo": "Zadzwoń do",
    "startingCall": "Inicjowanie połączenia",
    "callEnded": "Połączenie zakończone",
    "inCall": "\"W rozmowie",
    "callCancelled": "Połączenie anulowane",
    "callRejected": "Połączenie odrzucone",
    "is_calling": "%name% calls...",
    "contacts": "Kontakty",
    "addresses": "Książka adresowa",
    "chat": "czat",
    "calls": "Połączenia",
    "endCustomerNewMessage": "Wiadomość od nowego klienta",
    "returnToTheActiveCall": "Powrót do aktywnego połączenia",
    "agentStatus": "Status agenta",
    "switchOnAgentNote": "Aktywuj akceptowanie czatów i połączeń wideo z klientami",
    "switchOffAgentNote": "Dezaktywuj, aby nie akceptować czatów i połączeń wideo klientów.",
    "activateAgent": "Aktywacja akceptacji czatów z klientami",
    "deactivateAgent": "Dezaktywacja akceptacji czatów klientów",
    "startAudioCallWith": "Rozpocznij połączenie telefoniczne od {{to}}.",
    "startVideoCallWith": "Rozpocznij połączenie wideo za pomocą {{to}}.",
    "terms_and_conditions": "Zasady i warunki",
    "form_incorrect_data": "Wprowadzone dane są nieprawidłowe. Sprawdź swoje zgłoszenia i wyślij je ponownie.",
    "accept_terms_and_condition_start_chat": "Kliknięcie przycisku \"Rozpocznij czat\" oznacza akceptację warunków i postanowień.",
    "start_chat": "Rozpocznij czat",
    "connecting_with": "Połącz z",
    "connected_with": "Połączony z",
    "do_calling": "połączenia",
    "chat_inactivity_warning": "Czat był zbyt długo nieaktywny i wkrótce zostanie zamknięty.",
    "get_in_contact_with_us": "Skontaktuj się z nami",
    "no_agent_available": "Niestety żaden z agentów nie jest online. Zostaw wiadomość, a skontaktujemy się z Tobą tak szybko, jak to możliwe.",
    "hello": "Cześć!",
    "we_are_here_for_you": "Jesteśmy tu dla Ciebie i czekamy na Twoje pytania lub opinie.",
    "start_conversation": "Rozpoczęcie rozmowy",
    "welcome_customer": "Nazywam się {firstName} {lastName}. W czym mogę pomóc?",
    "end_chat": "Wyjście",
    "send_request": "Wyślij zapytanie",
    "checkout_payment_agreement": "Wyrażam zgodę na rozpoczęcie przez {company} świadczenia dodatkowych usług przed rozpoczęciem okresu odstąpienia od umowy i rozumiem, że utracę prawo do odstąpienia od umowy po pełnym wykonaniu tych dodatkowych usług.",
    "checkout_buy_order_legal_text": "Do zakupu mają zastosowanie {general_terms_and_conditions} oraz {cancellation_policy_and_cancellation_form} {company} ({privacy_policy}).",
    "checkout_services_terms_cancelation_legal_text": "W przypadku zarezerwowanych usług dodatkowych ({booked_services}) zastosowanie mają {general_terms_and_conditions} oraz {cancellation_policy_and_cancellation_form} {company} ({privacy_policy}).",
    "no_credit_cards": "Brak kart kredytowych",
    "minimize_video": "Minimalizacja widoku wideo",
    "maximize_video": "Maksymalizacja widoku wideo",
    "enable_fullscreen": "Aktywacja trybu pełnoekranowego",
    "disable_fullscreen": "Dezaktywacja trybu pełnoekranowego",
    "enable_screensharing": "Udostępnij pulpit",
    "disable_screensharing": "Zakończenie udostępniania pulpitu",
    "mute_microphone": "Wyciszanie mikrofonu",
    "unmute_microphone": "Wyłączanie wyciszenia mikrofonu",
    "enable_camera": "Włączanie kamery",
    "disable_camera": "Wyłączanie kamery",
    "end_call": "Zakończ połączenie",
    "end_videocall": "Zakończenie strumienia wideo",
    "muted": "Wyciszony",
    "overall_feedback": "Jak oceniasz swój kontakt z nami?",
    "connection_quality_feedback": "Jak oceniasz jakość połączenia?",
    "send_feedback": "Wyślij opinię",
    "customer_chat_ended_info": "Twój czat został zakończony",
    "checking_connection": "Połączenie jest sprawdzane",
    "call_again": "Zadzwoń ponownie",
    "end_call_local_disconnected": "Zakończenie połączenia z powodu problemów z połączeniem",
    "end_call_remote_disconnected": "Zakończenie połączenia z powodu problemów z połączeniem na stronie {remoteUserName}",
    "network_disconnected_warning": "Obecnie nie ma połączenia z Internetem.",
    "network_disconnected": "Połączenie przerwane",
    "network_want_reconnect": "Połączenie zostało przerwane. Czy chcesz ponownie nawiązać połączenie?",
    "leave_as_an_email_message": "Zostaw nam wiadomość e-mail",
    "continue_call": "Kontynuuj połączenie",
    "continue_text_chat": "Kontynuuj czat tekstowy",
    "continue_conversation": "Kontynuuj rozmowę",
    "disconnected": "Oddzielnie",
    "disconnect_reason": "Możliwy powód",
    "disconnect_reason_transport_close": "Opuść tę stronę",
    "disconnect_reason_ping_timeout": "Brak połączenia z Internetem",
    "customer_may_return": "Klient może wkrótce wrócić.",
    "mobile": "Telefon komórkowy",
    "headquarter": "Centrum",
    "extension-number": "Numer wewnętrzny",
    "add_contact": "Dodaj kontakt",
    "add_address": "Dodaj adres",
    "choose_contact": "Wybierz kontakt",
    "number": "Liczba",
    "new_number": "Nowy numer",
    "address_mail": "Adres pocztowy",
    "address_delivery": "Adres dostawy",
    "address_invoice": "Adres faktury",
    "new_address": "Nowy adres",
    "find_address": "Znajdź adres",
    "searching": "Szukaj...",
    "address_change": "Zmiana adresu",
    "address_ask_change": "Zmiana tego adresu wpłynie na wszystkie kontakty z tym adresem. Czy chcesz zmienić adres?",
    "designation": "Oznaczenie",
    "net": "Netto",
    "gross": "Brutto",
    "exp_delivery_time": "oczekiwany czas dostawy",
    "workdays": "Dni robocze",
    "special_offer": "Działanie",
    "transportation_cost": "Koszty wysyłki",
    "discount": "Zniżka",
    "total": "Łącznie",
    "total_gross": "Razem (z VAT)",
    "product_details": "Szczegóły produktu",
    "type_of_order": "Rodzaj zamówienia",
    "number_of_copies": "Wydanie",
    "type_of_order_choices": "Regularne drukowanie lub pobieranie danych drukowania jako pliku PDF",
    "type_of_order_website": "Udostępnianie tej strony internetowej",
    "print_production": "Produkcja druku",
    "pdf_download": "PDF do pobrania",
    "website_upload": "Załaduj stronę internetową",
    "production_time": "Czas produkcji",
    "production_time_description": "Wybierz żądaną opcję",
    "production_standard": "Standardowa produkcja",
    "production_express": "Ekspresowa produkcja",
    "production_overnight": "Produkcja z dnia na dzień",
    "choose_edition": "Edycja Select",
    "choose_edition_description": "Edycje ze stopniowanymi cenami. Rabaty i promocje są wyróżnione, jeśli są dostępne.",
    "more_options": "Dalsze opcje",
    "more_options_description": "Dodaj kolejne opcje do zamówienia.",
    "manual_data_review": "Ręczna kontrola danych (w tym ubezpieczenie)",
    "manual_item_entry": "Ręczne wprowadzanie pozycji",
    "web_enabled_pdf": "Dane do druku są również dostępne w formacie PDF.",
    "cart_production_type_change_warning_title": "Cyrkulacja jest regulowana",
    "cart_production_type_change_warning": "Wybrany nakład nie jest dostępny dla {{shippingType}}. Zostanie on zmieniony na maksymalny dostępny nakład. Czy na pewno chcesz kontynuować?",
    "cart_no_warranty_without_manual_data_check": "Uwaga: Nie wybrano ręcznego sprawdzania danych.",
    "campaign_budget_exceed_warning": "Budżet kampanii przekroczony. Wymagane zatwierdzenie!",
    "user_budget_exceed_warning": "Przekroczono budżet użytkownika. Wymagane zwolnienie!",
    "order_quantity": "Ilość zamówienia",
    "data_check": "Kontrola danych",
    "split_shipping": "Częściowa dostawa",
    "cart_not_logged_in": "Brak zalogowanego użytkownika",
    "cart_contact_or_address_not_found_invoice": "Wybierz adres rozliczeniowy",
    "cart_contact_or_address_not_found_shipping": "Wybierz adres dostawy",
    "cart_edtion_item_or_variant_not_found": "Nie znaleziono wydania, artykułu lub wariantu",
    "cart_edtion_item_not_available": "Artykuł %item_name% nie jest już dostępny",
    "cart_no_print_type_found": "Nie znaleziono typu wydruku",
    "cart_print_type_invalid": "Typ wydruku jest nieprawidłowy:",
    "cart_no_shipping_type_found": "Nie znaleziono metody wysyłki",
    "cart_checkout_info_missing": "Brakuje informacji o polu wyboru:",
    "cart_reduced_vat_rate_check_not_supported": "Poproszono o sprawdzenie obniżonej stawki VAT dla pozycji, które jej nie obsługują:",
    "cart_no_price_found": "Nie udało się ustalić ceny",
    "cart_price_mismatch": "Cena nie odpowiada cenie, którą przedstawiliśmy użytkownikowi",
    "cart_web_pdf_price_mismatch": "Cena w pliku PDF nie odpowiada cenie przedstawionej użytkownikowi.",
    "cart_contact_or_address_not_found_split_shipping": "Wybierz adres do wysyłki podzielonej",
    "cart_quantity_not_found_split_shipping": "Nie znaleziono parametrów ilości dla wysyłki podzielonej",
    "cart_split_shipping_quantity_mismatch": "Podzielona ilość wysyłkowa nie odpowiada ilości produkcyjnej",
    "cart_coupons_mismatch": "Użyte kupony nie są zgodne",
    "cart_coupon_cant_be_used": "Żądany kupon wygasł lub nie może zostać użyty.",
    "cart_coupon_value_mismatch": "Wartość kuponu jest nieprawidłowa",
    "cart_camaign_budget_processing_error": "Wystąpił nieoczekiwany problem podczas przetwarzania budżetu kampanii",
    "cart_campaign_budget_has_changed": "Budżet kampanii uległ zmianie",
    "cart_user_budget_has_changed": "Budżet użytkownika uległ zmianie",
    "cart_user_budget_processing_error": "Wystąpił nieoczekiwany problem podczas przetwarzania budżetu użytkownika",
    "cart_domain_not_active": "Domena nie jest aktywna",
    "cart_missing_desired_domain": "Brakuje specyfikacji żądanej domeny",
    "lead_monitor_order_payment_receipt": "Monitorowanie płatności przychodzących",
    "lead_format_message_description_route53_register_domain": "Zarejestruj domenę {0} i przekieruj na {1}.",
    "lead_title_route53_register_domain": "Zarejestruj domenę AWS",
    "domain_not_available": "Domena niedostępna",
    "invoice_payment_option": {
      "enabled": "Aktywowany",
      "disabled": "Dezaktywowany",
      "inherited": "Odziedziczone",
      "title": "Płatność faktury",
      "loading": "Wczytaj odziedziczone ustawienie",
      "effective_setting": "Skuteczne ustawienie"
    },
    "invoice_payment_threshold": {
      "title": "Wartość progowa",
      "placeholder": "w przykładzie 100",
      "no_effect": "Brak efektu",
      "loading": "Ładowanie odziedziczonych ustawień",
      "info_text": "Minimalna wartość zamówienia dla płatności fakturą",
      "effective_threshold": "Efektywny próg",
      "not_defined": "nieokreślony"
    },
    "remind_period": {
      "days": "Dni",
      "inherited_setting": "Odziedziczona postawa",
      "title": "Termin płatności faktury Okres przypomnienia"
    },
    "sellDownloadPdf": "Pobierz sprzedaż PDF",
    "success_management": {
      "title": "Zarządzanie sukcesem",
      "manager": "Menedżer sukcesu",
      "switch_title": "Aktywacja ostatnio oglądanych wspomnień",
      "interval": "Ostatnio oglądany okres, dni",
      "last_seen": "Ostatnio widziany",
      "error_no_self": "Nie można przypisać siebie jako menedżera sukcesu",
      "error_no_same_parent": "Success Manager musi podlegać innej firmie"
    },
    "copy": "Kopia",
    "is_client_registration_allowed": "Aktywacja rejestracji",
    "is_email_validation_enabled": "Zatwierdź wiadomość e-mail",
    "email_validation_pattern": "Regex do walidacji wiadomości e-mail",
    "confirmation_email_cc": "CC dla potwierdzenia rejestracji",
    "client_registration_title": "Ustawienia rejestracji",
    "registration_fields_show": "Pola wyświetlania",
    "registration_fields_require": "Pola obowiązkowe",
    "accept_terms_of_use_before_continuing": "Zanim przejdziesz dalej, musisz zaakceptować nasze warunki użytkowania",
    "subscription_settings": "Subskrypcje",
    "client_subscription_settings": "Subskrypcje klientów i produktów",
    "subscription_settings_description": "Tutaj można utworzyć subskrypcję podstawową i przypisać jej typ",
    "client_subscription_settings_description": "W tym miejscu można przypisać subskrypcję podstawową do klienta lub przedmiotu w zależności od typu i dostosować ceny",
    "subscriptions_": "Subskrypcje",
    "subscription_name": "Nazwa",
    "subscription_title": "Tytuł",
    "subscription_description": "Opis",
    "subscription_fee": "Opłata",
    "subscription_startingFee": "Opłata za wstęp (opcjonalnie)",
    "subscription_start": "Start",
    "subscription_end": "Koniec",
    "subscription_status": "Status",
    "subscription_action": "Działania",
    "subscription_items": "Produkty",
    "subscription_clients": "Klienci",
    "subscription_companies": "Firmy",
    "subscription_companies_detailed_role": "Użytkownik/rola w firmie",
    "subscription_basic": "Subskrypcja podstawowa",
    "add_subscription": "Dodaj subskrypcję",
    "create_subscription": "Utwórz subskrypcję",
    "edit_subscription": "Zapisz subskrypcję",
    "subscription_language": "Wybór języka",
    "period_status": "Dezaktywacja na koniec okresu",
    "subscription_edit": "Edytuj",
    "omit_subscription_fee": "Pomiń opłatę startową",
    "invoice_email": "Adres e-mail dla faktur",
    "order_processing_contract": "Umowa o realizację zamówienia",
    "accept_order_processing_contract_before_continuing": "Zanim przejdziesz dalej, musisz zaakceptować naszą umowę dotyczącą przetwarzania zamówień",
    "no_image_title": "Nie znaleziono tytułu obrazu. Kliknij tutaj, aby edytować.",
    "no_image_description": "Nie znaleziono opisu obrazu. Kliknij tutaj, aby edytować",
    "no_image_copyright": "Nie znaleziono informacji o prawach autorskich. Kliknij tutaj, aby edytować",
    "no_text": "Nie znaleziono tekstu. Kliknij tutaj, aby edytować",
    "subscription_clients_and_companies": "Klienci i firmy",
    "save_subscription": "Zapisz",
    "go_to": "Przejdź do",
    "no_invoices_to_display": "Brak faktur do wyświetlenia",
    "url_settings": "Ustawienia adresu URL",
    "update_notification": "Zarządzanie aktualizacjami",
    "update_notification_description": "Opis",
    "update_notification_settings_description": "Powiadomieniami o aktualizacjach można zarządzać tutaj",
    "update_name": "Nazwa",
    "update_notification_meta": {
      "title": "Zarządzanie aktualizacjami",
      "description": "Tutaj możesz zarządzać powiadomieniami o aktualizacjach",
      "name": "Zarządzanie aktualizacjami"
    },
    "new_update_notification": "Utwórz nowe powiadomienie o aktualizacji",
    "update_from": "z",
    "update_to": "do",
    "edit_update_notification": "Powiadomienie o aktualizacji edycji",
    "individual_prices": "Ceny dostosowane do indywidualnych potrzeb",
    "template_pdf_profile_x3": "Tworzenie plików PDF w formacie X-3",
    "countries_for_prices": {
      "de": "Niemcy",
      "ch": "Szwajcaria",
      "at": "Austria",
      "es": "Hiszpania"
    },
    "choose_country": "Wybierz kraj",
    "product_groups": "Grupy produktów - Dostawcy",
    "product_groups_description": "Tutaj można zarządzać grupami produktów dostawców",
    "new_product_group": "Dodaj nową grupę produktów",
    "edit_product_group": "Edytuj",
    "create_product_group": "Utwórz",
    "product_group_number": "Numer grupy produktów",
    "save_product_group": "Zapisz",
    "product_group_actions": "Działania",
    "product_group_status": "Status",
    "hide_preview": "Dezaktywacja podglądu w edytorze",
    "editor_settings": "Edytor ustawień",
    "share_edition": "Edycja akcji",
    "canceled_quote": "Oferta odrzucona",
    "active_quote": "Oferta w toku",
    "manual_quote_status": "Status ofert",
    "invalid_manual_quote_status": "Nieprawidłowy status oferty",
    "internal_status": "Status wewnętrzny",
    "edit_quote": "Edytuj ofertę",
    "copy_quote": "Kopiowanie oferty",
    "open_lead": "Open Lead",
    "upload_pdf": "Prześlij i połącz plik PDF",
    "upload_pdf_title": "Przesyłanie plików PDF",
    "upload_pdf_status_done": "Plik PDF został pomyślnie przesłany",
    "upload_pdf_status_error": "Przesyłanie pliku PDF nie powiodło się.",
    "placeholder_upload_error": "Wystąpił problem podczas przesyłania!",
    "linkedin_insight_tag": "LinkedIn Insight Tag",
    "linkedin_partner_id": "Identyfikator partnera",
    "tracking_pixel": "Piksel śledzący, np. Meta Piksel",
    "tracking_pixel_data": "Kod implementacji",
    "jsWebsite": "Javascript",
    "upload_website_favicon": "Favicon",
    "upload_website_favicon_label": "Przesyłanie i podgląd obrazów",
    "upload_website_favicon_info": "Uwaga! Wymagany format pliku to PNG. W zależności od używanego urządzenia optymalna rozdzielczość wynosi od 32 x 32 pikseli do maksymalnie 64 x 64 pikseli. Można również użyć innych rozmiarów, o ile są kwadratowe i nie przekraczają maksymalnej rozdzielczości.",
    "upload_favicon_error": "Obraz nie ma wymaganej rozdzielczości",
    "go_back_to_campaign": "Powrót do kampanii",
    "no_orders_to_display": "Keine Bestellungen zum Anzeigen",
    "no_editions_to_display": "Keine Editionen zum Anzeigen",
    "google_tag_manager": "Menedżer tagów Google",
    "google_tag_manager_id": "GTM ID",
    "website_analyse": "Analiza strony internetowej",
    "legal_information": "Informacje prawne",
    "contact_info": "Informacje kontaktowe",
    "import_users_via_csv": "Tworzenie użytkowników poprzez import pliku CSV",
    "remove_users_via_csv": "Usuwanie użytkowników poprzez import pliku CSV",
    "import_users": "Importuj użytkowników",
    "remove_users": "Usuń użytkownika",
    "fill_data_policy_before_autosave": "Automatyczne zapisywanie nie powiodło się. Prosimy o uzupełnienie informacji dotyczących ochrony danych i informacji prawnych. Można je znaleźć w ustawieniach",
    "invoice_customer_info": "ID - Firma / Nazwa",
    "invoice_search_debtor": "Numer dłużnika",
    "invoice_search_vat": "VAT",
    "invoice_search_amount": "Kwota",
    "search_field_name": "Wybierz pole wyszukiwania",
    "search_field_info_text": "Jeśli chcesz wyszukać wartość liczbową w polu wyszukiwania, musisz wybrać pole",
    "your_export": "Twój eksport",
    "export_download_info": "Plik został utworzony i można go teraz używać.",
    "export_download": "pobieranie",
    "export_file": "Plik",
    "export_share": "Udział",
    "image_permission_title": "Uwaga!",
    "image_permission_warning": "Czy prawa do wizerunku są dostępne/uzyskano zgodę wszystkich osób?",
    "other_settings": "Inne ustawienia",
    "image_rights_query": "Zapytanie o prawa do obrazu",
    "direct_ordering_label": "Zamówienie bezpośrednie",
    "direct_ordering_info_text": "W przypadku zamówienia bezpośredniego utworzonej edycji nie można już edytować. Jest ona umieszczana bezpośrednio w koszyku.",
    "subscription_automation": {
      "title": "Cel rozliczeniowy dla subskrypcji nowych użytkowników",
      "payer": "Płatnik",
      "fee_for_role_user": "Opłata za ROLE_USER",
      "fee_for_role_admin": "Opłata za ROLE_ADMIN",
      "fee_for_role_wl_admin": "Opłata za ROLE_WL_ADMIN",
      "hint_title": "Uwaga",
      "hint_body": "Utwórz i aktywuj subskrypcję firmową dla tej firmy, aby objąć nią wszystkich użytkowników utworzonych później.",
      "debtor_ancestor": "Każda subskrypcja użytkownika jest rozliczana w %billingTargetName%.",
      "debtor_ancestor_missing": "Żadna poprzednia firma nie jest oznaczona jako dłużnik. Prosimy o poprawienie tego."
    },
    "need_content_authorization": "Wymagana autoryzacja zawartości",
    "publisher_info_text": "Wydawca jest automatycznie umieszczany w każdym niezbędnym medium reklamowym zgodnie z wytycznymi prawnymi.",
    "mark_campaign": "Kampania Marka",
    "template_exports": {
      "download_complete": "Pobieranie zakończone",
      "export": "Eksport",
      "download_files": "Pobieranie plików",
      "synchronize": "Synchronizacja"
    },
    "info_import_and_export": {
      "note": "Ogólne informacje na temat importu i eksportu",
      "import": "Importuj: Importuje wszystkie informacje o ustawieniach szablonu (CSS, bloki treści, inne ustawienia) i plikach. Tworzy początkowy plik zip dla eksportu. Istniejące pliki zip są nadpisywane.",
      "upload": "Prześlij: Dodaje kolejne pliki do katalogu szablonu S3. Pliki te są dodawane do pliku zip eksportu.",
      "sync": "Synchronizuj: Synchronizuje ustawienia szablonu (CSS, bloki treści, inne ustawienia) z plikami (index.html, page.css, pdf.css, printpdf.css, website.css, website.js) w katalogu szablonów S3 i plikiem zip (eksport).",
      "export": "Export: Tworzy strukturę index.html do kopiowania i wklejania.",
      "download": "Pobierz pliki: Pobiera istniejący plik zip. (Przed pobraniem należy przeprowadzić synchronizację, aby uzyskać najnowsze dane).",
      "copy": "Kopiuj szablon: Funkcja \"Kopiuj szablon\" kopiuje tylko ustawienia szablonu, ale nie katalogi i pliki."
    },
    "template_upload_title": "Prześlij pliki do katalogu szablonu",
    "pls_choose_text": "Wybierz tekst",
    "discounts": {
      "discount_type_invalid": "Typ rabatu jest nieprawidłowy",
      "discounts_tab_title": "Rabaty",
      "intro_text": "Wprowadź numer grupy produktów FlyerAlarm oraz odpowiedni typ i wartość rabatu. Zostaną one zastosowane do wszystkich użytkowników w bieżącej organizacji podczas płatności.",
      "none_yet_add_some": "Nie zdefiniowano jeszcze żadnych zniżek. Prosimy o ich dodanie.",
      "number_must_be_unique": "Numer musi być unikalny",
      "must_be_0_to_100": "Musi wynosić od 0 do 100"
    },
    "link_to_uploaded_pdf": "Adres URL przesłanego pliku PDF",
    "upload_qrcode_logo": "Prześlij logo dla kodu QR",
    "choose_file": "Wybierz plik",
    "upload_successful": "Przesyłanie powiodło się",
    "upload_failed": "Przesyłanie nie powiodło się",
    "qr_logo": "Logo",
    "qr_example": "Przykład",
    "qr_settings": "Ustawienia kodu QR",
    "qr_margin": "Odległość",
    "qr_upload": "Prześlij logo QRCode",
    "google_search_console": "Google Search Console",
    "gsc_html_tag": "Weryfikacja HTML-TAG",
    "content_id": "Content-Id",
    "gsc_html_tag_example": "Użyj weryfikacji znaczników HTML. Skopiuj identyfikator, np. Xu9c238409d8JDSF8... z atrybutu zawartości metatagu otrzymanego od Google w celu weryfikacji i wklej identyfikator tutaj. Przykład: <meta name='google-site-verification' content='Xu9c238409d8JDSF8...' />",
    "order_complete": {
      "good_luck": "Powodzenia w kampanii!",
      "order_complete": "Zamówienie zostało pomyślnie zrealizowane",
      "pls_check_inbox": "Prosimy o sprawdzenie skrzynki odbiorczej.",
      "order_info": "Wszystkie szczegóły zamówienia zostały wysłane na adres e-mail, który mamy zapisany. Historię zamówień można wyświetlić w dowolnym momencie na koncie użytkownika pod adresem",
      "orders": "Zamówienia",
      "view": "widok.",
      "start_next_campaign": "Rozpocznij kolejną kampanię już teraz"
    },
    "no_product_selected": "Nie wybrano żadnego produktu",
    "mail_messages": {
      "header": {
        "header_title": "Inteligentny portal projektowy online",
        "support": "Pomoc i FAQ",
        "login": "LOGIN"
      },
      "footer": {
        "email_info": "Otrzymujesz tę wiadomość e-mail, ponieważ zarejestrowałeś się w %company%.",
        "email_info_first_name_form": "Otrzymujesz tę wiadomość e-mail, ponieważ zarejestrowałeś się w %company%.",
        "dont_reply": "Ta wiadomość została wysłana z adresu, który nie może odbierać przychodzących wiadomości e-mail. </Prosimy o nie odpowiadanie na tę wiadomość. W razie jakichkolwiek pytań lub wątpliwości prosimy o kontakt pod adresem",
        "agb": "GTC",
        "privacy_policy": "Ochrona danych",
        "imprint": "Nadruk"
      }
    },
    "content_check": "Przegląd zawartości",
    "terms_of_payment": "Warunki płatności",
    "terms_of_payment_edit": "Edycja warunków płatności",
    "cancel_impersonation": "Koniec odwracania ról",
    "impersonate": "Zaakceptuj rolę",
    "template_deleted_warning": "Uwaga! Szablon edycji nie jest już dostępny.",
    "click_to_enable_editing": "Kliknij tutaj, aby aktywować edycję na bieżącej karcie.",
    "click_to_send_a_request_to_enable_editing": "Kliknij tutaj, aby wysłać prośbę o aktywację przetwarzania",
    "waiting_for_form_release": "Oczekiwanie na wydanie",
    "already_edited_by_another_user": "Ta edycja może być już edytowana przez innego użytkownika.",
    "please_wait": "Proszę czekać.",
    "user_already_requested_release": "złożyła już wniosek o zatwierdzenie",
    "user_requested_that_you_give_up_the_editing": "prosi o pozwolenie na edycję.",
    "emergency_release": "Wymuś zwolnienie. Używaj na własne ryzyko. Edycja może zostać nadpisana przez innego użytkownika, jeśli aktualnie nad nią pracuje.",
    "alt_debtor_number": "Alternatywny identyfikator dłużnika",
    "use_bootstrap_label": "Bootstrap",
    "use_bootstrap_infotext": "Po włączeniu tej opcji biblioteki bootstrap zostaną zintegrowane",
    "last_queried_attributes": "Ostatnie żądane ceny",
    "transfer_campaign": "Transfer",
    "duplicate_campaign_description": "Powiel kampanię i przypisz ją do poszczególnych użytkowników lub wszystkich użytkowników firmy.",
    "duplicate_campaign": "Duplikat",
    "clone_to_single_user": "Utwórz duplikat dla jednego lub więcej użytkowników",
    "clone_to_all_users_of_company": "Tworzenie duplikatów dla wszystkich użytkowników firmy",
    "enum_option_invalid": "Nieprawidłowa opcja",
    "pdf_download_option": {
      "enabled": "Aktywowany",
      "disabled": "Dezaktywowany",
      "inherited": "Odziedziczone",
      "title": "Pobieranie plików PDF dozwolone",
      "loading": "Wczytaj odziedziczone ustawienie",
      "effective_setting": "Skuteczne ustawienie"
    },
    "choose_image_for_filter": "Wybierz zdjęcie",
    "invoice_email_bcc": {
      "send_new_invoice_email_bcc": "Wyślij nową fakturę przez e-mail BCC",
      "email_address_bcc": "Adres e-mail BCC",
      "info_text": "Na ten adres zostanie również wysłana ukryta kopia wiadomości e-mail z nową fakturą."
    },
    "reduced_vat_rate": {
      "cart_item_hint": "Ten przedmiot kwalifikuje się do obniżonej stawki VAT po ręcznym sprawdzeniu przez pracownika Creacheck"
    },
    "forgot_password_infotext": "Jeśli zapomniałeś hasła, możesz je zresetować tutaj. Wprowadź swój zarejestrowany adres e-mail w poniższym formularzu i kliknij \"Kontynuuj\". Następnie wyślemy wiadomość e-mail z dalszymi informacjami.",
    "forgot_password_infotext_robethood": "Jeśli zapomniałeś hasła, możesz je zresetować tutaj. Wprowadź zarejestrowany adres e-mail w poniższym formularzu i kliknij \"Kontynuuj\". Następnie wyślemy wiadomość e-mail z dalszymi informacjami.",
    "forgot_password_info_email_not_used": "Jeśli nie korzystasz już z adresu e-mail powiązanego z Twoim kontem, skontaktuj się z nami za pomocą naszego formularza kontaktowego:",
    "forgot_password_info_email_not_used_robethood": "Jeśli nie korzystasz już z adresu e-mail powiązanego z Twoim kontem, skontaktuj się z nami za pośrednictwem naszego formularza kontaktowego:",
    "invoice_greeting_male": "Szanowny Panie",
    "invoice_greeting_female": "Szanowna Pani",
    "invoice_greeting_text": "Bardzo dziękujemy za złożenie zamówienia za pośrednictwem %company%. Naliczymy opłatę za następujące usługi:",
    "bank_account": "Dane bankowe",
    "mandatory_field": "Zadeklaruj to jako pole obowiązkowe",
    "custom_checkbox": "Niestandardowe pole wyboru",
    "set_label_checkbox": "Przypisz etykietę do pola wyboru",
    "set_checkbox_link": "Link do etykiety znajduje się tutaj",
    "change_checkbox_link": "Zmień łącze",
    "checkbox_infotext": "Możesz połączyć etykietę, umieszczając odpowiedni obszar w znakach $. np. Zgadzam się na $Privacy Policy$.",
    "perforation": {
      "cart_radiobox_title": "Opcje wypełniania perforacji",
      "attribute_is_custom_fa_setting": "Możliwość dostosowania wstępnie wybranych opcji perforacji FA",
      "set_perforation_options": "Ustawianie opcji perforacji",
      "preset": "Ustawienie domyślne",
      "how_to": "Wyszukaj identyfikator grupy produktów, wybierz kilka wariantów, wypróbuj różne ilości i metody wysyłki. Następnie wybierz opcję perforacji, która ma być wstępnie wybrana z tym atrybutem. Można dodać kilka opcji.",
      "select_variant": "Wybierz wariant, aby wyświetlić jego ilości i metody wysyłki.",
      "select_shipping_type": "Kliknij metodę wysyłki, aby zobaczyć dostępne opcje.",
      "select_option_value": "Kliknij opcję, aby dodać ją do listy wyboru.",
      "please_try_other": "Wypróbuj inny wariant, ilość lub metodę wysyłki",
      "options": "Opcje",
      "other_options": "Inne opcje",
      "selected_options": "Wybrane opcje",
      "perforation_options": "Opcje perforacji",
      "product_group": "Grupa produktów",
      "variants": "Warianty",
      "quantities_in_variant": "Ilości w wariancie"
    },
    "textile_select_exactly_n_items": "Wybierz dokładnie {łącznie}",
    "textile_exact_number_selected": "Wszystko dobrze: dokładnie {całkowicie} wybrane artykuły",
    "textile_order_quantity_mismatch": "Liczba rozmiarów materiału musi być zgodna z wybraną edycją.",
    "tracking_pixel_help_text": "Należy pamiętać, że implementacja może się różnić w zależności od dostawcy. W naszym przykładzie używamy piksela śledzącego od Meta. Integracja wymaga przede wszystkim weryfikacji domeny. Aby to zrobić, musisz zintegrować metatag, który w naszym przykładzie został dostarczony przez Meta. Zobacz przykładowy kod poniżej. Należy pamiętać, że weryfikacja może potrwać do 72 godzin, w zależności od dostawcy. W drugim kroku należy dodać kod bazowy dla integracji piksela śledzącego. Aby uzyskać więcej informacji, zapoznaj się z dokumentacją dostarczoną przez odpowiedniego dostawcę.",
    "reg_greeting_male": "Szanowny Panie",
    "reg_greeting_female": "Szanowna Pani",
    "reg_gender_male": "Pan",
    "reg_gender_female": "Pani",
    "reg_name": "Powitanie i imię",
    "reg_phone_number": "Numer telefonu",
    "reg_domain": "Domena",
    "reg_ancestors": "Identyfikatory struktur",
    "reg_explanation": "<p>Dziękujemy za rejestrację. Prosimy o potwierdzenie adresu e-mail w ciągu 24 godzin.</p><p>Tylko z potwierdzonym adresem e-mail możesz otrzymać nowe hasło lub informacje o zmianach.</p>",
    "widget_order_stats": {
      "title": "Całkowita liczba zamówień",
      "orders_count": "Liczenie zamówień",
      "orders_gross_total": "Zamówienia brutto ogółem",
      "no_orders_in_date_range": "Brak zamówień w wybranym okresie",
      "loading": "Ładowanie",
      "this_month": "W tym miesiącu",
      "previous_month": "Ostatni miesiąc",
      "last_3_months": "Ostatnie 3 miesiące",
      "last_6_months": "Ostatnie 6 miesięcy",
      "last_12_months": "Ostatnie 12 miesięcy",
      "for_companies": "dla spółek",
      "for_clients": "dla klientów",
      "title1": "Całkowita liczba zamówień",
      "title2": "10 najczęściej zamawianych produktów",
      "items_count": "Liczba produktów",
      "item_types_count": "Produkty (rodzaje)"
    },
    "cart_domain": "Określona domena",
    "cart_domain_available": "jest dostępny!",
    "cart_domain_not_available": "nie jest już dostępny!",
    "cart_domain_not_available_title": "Domena niedostępna",
    "pages_to_delete": "Następujące strony zostaną usunięte:",
    "page_to_delete": "Strona",
    "sales_doc_reverse_charge": "Faktura jest wystawiana bez podatku VAT, ponieważ w tym przypadku ma zastosowanie procedura odwrotnego obciążenia. VAT musi zostać zadeklarowany i zapłacony przez odbiorcę usługi.",
    "invoice_for_service": "Faktura za usługę",
    "phone_invalid_country": "Kod kraju numeru telefonu jest nieprawidłowy",
    "mobile_phone_invalid_country": "Kod kraju numeru telefonu komórkowego jest nieprawidłowy",
    "fax_invalid_country": "Kod kraju numeru faksu jest nieprawidłowy",
    "search_campaign": "Wyszukiwanie kampanii",
    "coupon_usage": "Wykorzystanie kuponu",
    "coupon_balance_title": "Bilans kuponowy",
    "get_coupon_balance": "Zapytanie o pozostałą kwotę",
    "total_coupon_budget": "Całkowity budżet:",
    "remaining_coupon_budget": "Pozostały budżet:",
    "spent_coupon_budget": "Wydany budżet:",
    "coupon_balance_success": "Zapytanie powiodło się",
    "upload_print_data_previews": "Przesyłanie obrazów podglądu danych drukowania",
    "uploaded_print_data_previews": "Przesłane dane drukowania Podgląd obrazów",
    "confirm_upload": "Potwierdzenie",
    "image_minimum_size": "Zdjęcie nie odpowiada minimalnemu rozmiarowi!",
    "data_policy_info": "Ochrona danych i nadruk",
    "fill_data_policy_before_save": "Przed zapisaniem należy wypełnić informacje o ochronie danych i informacje prawne. Można je znaleźć w ustawieniach",
    "subscription_liu_subscribe": "Subskrybuj",
    "subscription_liu_cancel": "Anulowanie",
    "subscription_liu_extend": "Rozszerzenie",
    "subscription_status_changed_to_active": "Status został zmieniony na aktywny!",
    "subscription_status_changed_to_inactive": "Status został zmieniony na nieaktywny!",
    "sub_start_end": "Data początkowa nie może być większa niż data końcowa!",
    "subscription_liu_send": "Wysyłanie",
    "subscription_to_client": {
      "subscription_not_available": "Wybierz subskrypcję!",
      "type_parameter_not_present": "Brakuje parametru %type%",
      "date_parameter_not_present": "Brakuje parametru %type%",
      "end_date_greater_then_start_date": "Data końcowa nie może być większa niż data początkowa!",
      "end_date_less_then_first_day_of_next_month": "Data końcowa nie może być wcześniejsza niż pierwszy dzień następnego miesiąca!"
    },
    "subscription_billing_period": "Okres rozliczeniowy",
    "mail_greeting_female": "Witam panią",
    "mail_greeting_male": "Witam panie",
    "mail_greeting_first_name_form": "Witam",
    "mail_preview": "Podgląd wiadomości e-mail",
    "mail_preview_update": "Podgląd uruchomienia/aktualizacji",
    "mail_preview_close": "Zamknij",
    "mail_preview_hint": "Należy pamiętać, że podgląd służy wyłącznie do sprawdzenia układu i wiadomości. Wszystkie szablony zawierają fikcyjne dane.",
    "tracking_details": "Kliknij numer śledzenia, aby uzyskać więcej informacji",
    "ups_tracking_title": "Monitorowanie przesyłki przez API (UPS)",
    "ups_tracking_button": "Status zapytania",
    "tracking_informations": "Informacje o przesyłce",
    "ups_tracking_status": "Status przesyłki (UPS)",
    "shipment_package": "Pakiet",
    "shipment": "Program",
    "shipment_package_current_status": "Aktualny status",
    "shipment_package_current_status_description": "Opis",
    "shipment_package_delivery_date": "Dostarczone w dniu",
    "shipment_package_delivery_location": "Otrzymać gdzie",
    "shipment_package_delivery_receivedBy": "Odbiór od",
    "tracking_error": "Wystąpił błąd podczas zapytania!",
    "1": "<strong>Fehler beim Einloggen</strong>",
    "website_title": "Tytuł dla strony internetowej (wyszukiwarki) i PDF (dostępność)",
    "basic_billing_settings": "Podstawowe ustawienia rozliczeń",
    "currency_and_vat": "Waluta i podatek VAT",
    "basic_client_settings": "Ustawienia podstawowe",
    "client_settings_info_text": {
      "info_text_phone": "Aby poprawnie wprowadzić numer telefonu stacjonarnego, wprowadź pełny numer wraz z numerem kierunkowym kraju. Upewnij się, że nie używasz spacji ani znaków specjalnych. Przykład dla Niemiec: +49XXXXXXXXXXX.",
      "info_text_mobile": "Aby poprawnie wprowadzić numer telefonu komórkowego, wprowadź pełny numer wraz z numerem kierunkowym kraju. Upewnij się, że nie używasz spacji ani znaków specjalnych. Przykład dla Niemiec: +49XXXXXXXXXXX.",
      "info_text_demo": "Aktywacja trybu demonstracyjnego, np. w celu wykonania zleceń testowych.",
      "info_text_image_rights_query": "Jeśli aktywujesz tę opcję, dodatkowy monit będzie wyświetlany za każdym razem, gdy prześlesz obraz. Zostaniesz wówczas poproszony o potwierdzenie, że posiadasz prawa do korzystania z przesłanego obrazu. Pomaga nam to upewnić się, że wszystkie przesłane obrazy są zgodne z prawem i nie naruszają żadnych praw autorskich.",
      "info_text_pdf_download_option": "Aktywuje opcję pobierania plików PDF w edytorze.",
      "info_text_needs_content_authorization": "Jeśli aktywujesz sprawdzanie zawartości, wszystkie zamówienia złożone przez członków Twojej firmy muszą zostać sprawdzone i potwierdzone ręcznie.",
      "info_text_main_language": "Wybierz język, w którym głównie chcesz korzystać z aplikacji. Wybierając język, dostosowujemy interfejs użytkownika do swoich potrzeb.",
      "info_text_password_renew": "Jeśli aktywujesz tę opcję, wyślemy użytkownikowi wiadomość e-mail z prośbą o odnowienie hasła przy następnym logowaniu.",
      "info_text_editor_auto_save": "Po włączeniu tej opcji edytor automatycznie zapisuje zmiany co 5 minut."
    },
    "content_check_status_rejected": "Odrzucono",
    "content_check_status_approved": "autoryzowany",
    "cart_remaining_user_budget": "Pozostały budżet użytkownika",
    "cart_remaining_user_budget_after_order": "Pozostały budżet użytkownika po zamówieniu",
    "cart_budget_user": "Budżet użytkownika",
    "archived_product_templates": "Zarchiwizowane szablony",
    "archived_product_templates_description": "Tutaj można znaleźć wszystkie zarchiwizowane szablony",
    "archive_template": "Archiwum",
    "restore_template": "Przywracanie",
    "budget_value_used": "Wykorzystane",
    "budget_value_reserved": "Zarezerwowane",
    "budget_value_residual": "Wartość rezydualna",
    "double_opt_in": "Procedura podwójnej zgody",
    "export_video": "Eksport wideo",
    "available_actions": "Dostępne działania",
    "mail_text_rhenus": {
      "support": "FAQ i pomoc",
      "login": "Logowanie",
      "thank_you": "Dziękujemy za zamówienie!",
      "order_foreign_status": {
        "in_delivery": {
          "explanation_first_name_form": "Twoje zamówienie zostało wyprodukowane i przekazane kurierowi w celu dostarczenia.",
          "possible_actions_tracking_first_name_form": "Śledź dostawę na stronie",
          "possible_actions": "Pod <a href='%domain%'><span class='r_url bold'>%domain%</span></a> możesz zobaczyć swoje zamówienie w dowolnym momencie."
        }
      },
      "order_item_sales": {
        "explanation": "Szczegóły zamówienia znajdują się poniżej:",
        "possible_actions": "Pod <a href='%domain%'><span class='r_url bold'>%domain%</span></a> możesz zobaczyć swoje zamówienie w dowolnym momencie.",
        "call_to_action": "Szczegóły zamówienia"
      },
      "order_content_check_status": {
        "explanation": "Dziękujemy za zamówienie. Zamówienie zostało wysłane po sprawdzeniu zawartości %status%.",
        "possible_actions": "Pod <a href='%domain%'><span class='r_url bold'>%domain%</span></a> możesz zobaczyć swoje zamówienie w dowolnym momencie.",
        "call_to_action": "Szczegóły zamówienia"
      },
      "possible_actions_default": "Pod <a href='%domain%'><span class='r_url bold'>%domain%</span></a> możesz zobaczyć swoje zamówienie w dowolnym momencie.",
      "call_to_action_order_details_default": "Szczegóły zamówienia",
      "contact_form_edition": {
        "subject": "Nowy kontakt",
        "info": "Powiadomienie o przesłaniu formularza kontaktowego. Prosimy o jego natychmiastowe przetworzenie i zapewnienie, że klient otrzyma najlepsze możliwe wsparcie.",
        "form_fields": "Wypełnione pola formularza"
      },
      "contact_form_cms": {
        "subject": "Nowy kontakt od %name%",
        "info": "Powiadomienie o przesłaniu formularza kontaktowego. Prosimy o jego natychmiastowe przetworzenie i zapewnienie, że klient otrzyma najlepsze możliwe wsparcie."
      },
      "password_renew": {
        "greeting": "Witaj %email%,",
        "explanation_first_name_form": "Próbowano zalogować się do %platformName%. Twoje hasło wygasło. Musisz ustawić nowe hasło, aby móc zalogować się w przyszłości."
      },
      "password_reset": {
        "greeting": "Witaj %email%,",
        "explanation_first_name_form": "Zażądano nowego hasła od %platformName% za pośrednictwem naszej platformy. Jeśli złożyłeś taki wniosek, kliknij poniższy link."
      }
    },
    "authorized": "Autoryzowany",
    "open_in_new_tab": "Otwarty",
    "subscription_set_availability_period": "Ustaw okres dostępności subskrypcji",
    "failed_video_generation": "Nie można wygenerować wideo! Sprawdź, czy przesłane pliki są prawidłowe!",
    "video_uploads_missing": "Nie znaleziono żadnych filmów w blokach edycji!",
    "subscription_video_missing": "Nie znaleziono subskrypcji wideo!",
    "download_invoices": "Pobieranie faktur",
    "download_user_list": "Pobierz listę użytkowników",
    "cookie_management": "Menedżer zgody na pliki cookie",
    "cookie_manager_id": "Adres URL menedżera plików cookie (opcjonalnie) - ccm19.de",
    "supported_cookie_manger_provider": "Obecnie obsługiwane są tylko menedżery Cookie Constent z ccm19.de.",
    "cookie_management_custom": "To ustawienie jest opcjonalne. Menedżer zgody na pliki cookie jest domyślnie zintegrowany z witryną.",
    "print_accessibility_pdf": "Drukuj PDF (PDF bez barier)",
    "accessibility_settings": {
      "accessibility": "Dostępność",
      "language_of_text": "Język tekstu (kody krajów - ISO 639-1)",
      "language_example": "Wprowadź tutaj kod kraju języka używanego w dokumencie lub na stronie internetowej. (Kody krajów - ISO 639-1), np. de dla języka niemieckiego (standard) lub en dla języka angielskiego.",
      "activate_accessibility_pdf": "Dostępny plik PDF - UA-1",
      "bookmarks": "Zakładka",
      "use_bookmark_classes": "Klasy zakładek",
      "template_settings_bookmarks_info": "Aktywuje klasy (.bookmarks-l1 do .bookmarks-l6), które są ustawione jako zakładki. Opcje zakładek H1-H6 są dezaktywowane.",
      "edition_settings_bookmarks_info_classes": "Użyj dostępnych elementów tytułu lub zakładki, aby ustawić zakładki w pliku wyjściowym PDF.",
      "edition_settings_bookmarks_info_h": "Po włączeniu tej opcji używane nagłówki są ustawiane jako zakładki w pliku wyjściowym PDF. Na przykład \"Zakładka H1\" ustawia zakładki dla wszystkich elementów zawierających nagłówek 1 lub znacznik H1.",
      "accessible_pdf_infos": "Wymagania dotyczące dostępnych dokumentów PDF",
      "accessible_pdf_infos_1": "1. metadane: Ustaw tytuł i krótkie podsumowanie.",
      "accessible_pdf_infos_2": "2. Ustawienia języka: Ustaw odpowiedni język używany w danej edycji.",
      "accessible_pdf_infos_3": "3. oznaczony (etykietowany) plik PDF: Znaczniki i kolejność odczytu są ustawiane automatycznie.",
      "accessible_pdf_infos_4": "4. alternatywny tekst dla obrazów: Użytkownik ma możliwość zdefiniowania \"Opisów\" w centrum multimediów lub podczas przesyłania. Są one używane jako tekst alternatywny dla obrazów.",
      "accessible_pdf_infos_5": "5 Dostępne tabele: Jeśli używasz tabel, ustaw odpowiednie wiersze lub kolumny nagłówka.",
      "accessible_pdf_infos_6": "6. tekst jako obraz: Jeśli to możliwe, nie używaj tekstu jako obrazu.",
      "accessible_pdf_infos_7": "7. Czytelne czcionki: Używaj czcionek, które mogą być łatwo dekodowane przez programy czytników ekranu. Unikaj czcionek, które są trudne do zinterpretowania przez czytniki ekranu.",
      "accessible_pdf_infos_8": "8. pomoce nawigacyjne: Wstaw odpowiednie elementy (elementy tytułu i zakładki), aby utworzyć zakładki, łącza i spisy treści ułatwiające nawigację po dokumencie. Dalsze opcje mogą być dostępne w ustawieniach. Zobacz Ustawienia -> Zakładki.",
      "accessible_pdf_infos_9": "9. Kontrast kolorów: Upewnij się, że teksty i tła oferują wystarczający kontrast dla osób z wadami wzroku. Należy unikać trudnych do rozróżnienia kombinacji kolorów."
    },
    "save_options": "Zapisz",
    "print_options": "Eksportuj PDF",
    "css_accessibility_pdf": "CSS dla dostępnych plików PDF",
    "css_accessibility_pdf_info_text": "Należy pamiętać, że podczas tworzenia dostępnych plików PDF tylko \"ogólny CSS\" i \"CSS dla dostępnych plików PDF\" są używane przez CSS produktu. \"CSS dla wyjścia PDF\" jest również używany przez klienta, białą etykietę lub szablon.",
    "payment_options": "Paypal / karta kredytowa / polecenie zapłaty",
    "default_logo_size": "Logo e-mail. Należy pamiętać, że dla standardowego układu wiadomości e-mail wymagany jest rozmiar logo ...px x 175px (wys. x szer.). Opcja \"Mail logo\" musi być również aktywna.",
    "lead_authorization": {
      "authorization": "Autoryzacja",
      "status": "Status",
      "approved": "autoryzowany",
      "rejected": "Odrzucono",
      "pending": "Znakomity",
      "type": "Typ"
    },
    "loading": "Ładowanie",
    "no_data": "Brak danych",
    "whiteLabel": "WhiteLabel",
    "widget_login_stats": {
      "widget_title": "Statystyki logowania",
      "number_of_logins_label_title": "Liczba rejestracji"
    },
    "widget_edition_stats": {
      "widget_title": "Statystyki wyjściowe",
      "social_media_downloads_label_title": "Pobieranie z mediów społecznościowych",
      "total_editions_label_title": "Wydatki ogółem",
      "amount_of_new_edition_per_date": "Liczba nowych emisji na dzień",
      "amount_of_new_edition_per_template_per_date": "Liczba nowych emisji na szablon"
    },
    "widget_item_stats": {
      "widget_title": "Statystyki artykułów",
      "used_coupons_title": "Wykorzystane kupony",
      "turnovers_title": "Obrót",
      "for_items": "dla artykułów",
      "total_used_coupons_count_label_title": "Wykorzystane bony ogółem",
      "total_turnovers_count_label_title": "Całkowita sprzedaż",
      "total_used_coupons_value_label_title": "Całkowita wartość wykorzystanych bonów",
      "total_turnovers_value_label_title": "Całkowita wartość sprzedaży"
    },
    "pipeline_owner": "Właściciel",
    "set_to_null": "Ustaw właściciela na zero",
    "change_page_number_notify": {
      "same_page": "Ta strona jest już w żądanej pozycji.",
      "fixed_page": "Ta pozycja ma stałą stronę, której nie można przesuwać.",
      "out_of_range": "Numer strony jest nieprawidłowy. Wprowadź numer w prawidłowym zakresie."
    },
    "change_page_options": "Otwiera ustawienia przenoszenia stron",
    "filtered_subscriptions": "Filtrowane subskrypcje",
    "filtered_subscriptions_description": "Każdy wybrany element jest dostępny dla podległych użytkowników tej firmy; jeśli nie dokonasz wyboru, wszystkie są dostępne.",
    "video_export_warning": "Po wyeksportowaniu wideo dalsze zmiany nie są możliwe. Kolejne zmiany wymagają utworzenia nowego materiału reklamowego.",
    "cssPdfX3Compatible": "Zgodność z CSS dla PDF/X-3",
    "filtered_subscriptions": "Filtrowane subskrypcje",
    "filtered_subscriptions_description": "Każdy wybrany element jest dostępny dla podległych użytkowników tej firmy; jeśli nie dokonasz wyboru, wszystkie są dostępne.",
    "template_css_convert": {
      "to_pdf_x3_compatible": "Konwersja CSS do formatu zgodnego z PDF/X-3",
      "template_has_no_css": "Szablon nie ma CSS",
      "css_converted": "Konwersja CSS",
      "css_convert_failed": "Konwersja CSS nie powiodła się",
      "no_svg_files_to_convert": "Brak plików SVG do konwersji",
      "svg_files_content_read_problem": "Problem z odczytaniem zawartości pliku SVG:"
    }
  }
}